import {Row} from "../../../lib/markup/markup";
import {border} from "../../../const";
import {useContext} from "react";
import {CouriersContext} from "../../contexts";
import Courier from "./Courier";
import {ScrollView} from "react-native";


export default function () {
    const {couriers} = useContext(CouriersContext)

    return <ScrollView>
        <Row style={[border]}>
            {couriers.map((courier, key) => <Courier key={key} {...courier}/>)}
        </Row>
    </ScrollView>
}
