import {useContext, useEffect, useState} from "react";
import Loading from "../../lib/static/Loading";
import {AppContext} from "../contexts";
import Chat from "../../lib/modules/chat/Chat";
import {StringSession} from "telegram/sessions";
import {TelegramClient} from "telegram";
import TextField from "../../lib/fields/TextField";


export default function () {
    const {telegram, route, holidays} = useContext(AppContext)
    const [status, setStatus] = useState('loading')
    const [client, setClient] = useState()
    const [phoneCode, setPhoneCode] = useState('31450')

    useEffect(async () => {
        if (status === 'loading') {
            const apiId = 19209830;
            const apiHash = "b2fc15b525436f37f4fd45f565ecf9f9";
            const stringSession = new StringSession("");
            const phoneNumber = '79015283223'
            const client = new TelegramClient(stringSession, apiId, apiHash, {
                connectionRetries: 5,
            });
            await client.start({phoneNumber, phoneCode})
            setStatus('codeRequested')
        } else if (status === 'codeRequested' && phoneCode.length === 5) {
            // const apiId = 19209830;
            // const apiHash = "b2fc15b525436f37f4fd45f565ecf9f9";
            // const stringSession = new StringSession("");
            // const phoneNumber = '79015283223'
            // const client = new TelegramClient(stringSession, apiId, apiHash, {
            //     connectionRetries: 5,
            // });
            // await client.start({phoneNumber, phoneCode})
        }
    }, [status, phoneCode])

    async function createMessage(args) {

    }

    if (status === 'loading') return <Loading/>
    if (state.status === 'codeRequested') {
        return <TextField placeholder={'код телеграм'} onChange={setPhoneCode}/>
    }

    return <Chat
        // show={state.show}
        // currentChat={state.currentChat}
        // chats={state.chats}
        // createMessage={createMessage}
        // deleteMessage={async messageId => {
        //     const response = await axios.post(
        //         `${whatsapp.url}deleteMessage?token=${whatsapp.token}`,
        //         {messageId}
        //     )
        // }}
        // showContacts={true}
        // fastAnswers={[
        //     {
        //         title: 'цвет',
        //         text: 'Здравствуйте! Не указан цвет.'
        //     },
        //     {
        //         title: 'фото',
        //         text: 'Здравствуйте! Требуется фото.'
        //     },
        //     {
        //         title: 'приедем сегодня',
        //         text: 'Здравствуйте! Сегодня приедем'
        //     },
        //     {
        //         title: `приедем ${whenComing}`,
        //         text: `Здравствуйте! Приедем ${whenComing}`
        //     }
        // ]}
    />
}

