import {Row} from "../../lib/markup/markup";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {isDesktop, isSmall} from "../../lib/sugar";
import {mt1} from "../../const";

export default function ({save, deliveryNumber}) {
    return <Row style={isDesktop() || isSmall() ? null : mt1}>
        <BtnToggle
            title={'1 отправка'}
            isActive={deliveryNumber === 1}
            onPress={() =>
                save({deliveryNumber: 1}, `отправка: ${deliveryNumber} > 1`)}
        />
        <BtnToggle
            title={'2 отправка'}
            isActive={deliveryNumber === 2}
            onPress={() =>
                save({deliveryNumber: 2}, `отправка: ${deliveryNumber} > 2`)}
        />
        <BtnToggle
            title={'3 каркасы'}
            isActive={deliveryNumber === 3}
            onPress={() => {
                save({
                    deliveryNumber: 3,
                    clinic_date: null,
                    clinic_time: null
                }, `отправка: ${deliveryNumber} > 3`)
            }}
        />
    </Row>
}
