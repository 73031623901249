import {jBetween, mt1} from "../../const";
import {isSmall, zfill} from "../../lib/sugar";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {Row} from "../../lib/markup/markup";

export default function TimeRow({str, save, empty, time, pickup}) {
    const old = old ? time.slice(0, 5) : empty
    return <Row style={[isSmall() ? null: mt1, jBetween]}>
        {str.map((val, key) => {
            const thisTime = zfill(String(val)) + ':00'
            return <BtnToggle
                style={{width: 55}}
                title={val === empty ? val : thisTime}
                key={key}
                onPress={
                    () => save(val === empty ? null : thisTime, old)
                }
                isActive={
                    (time && thisTime === time.slice(0, 5))
                    ||
                    (!time && !pickup && val === empty)
                }
            />
        })}
    </Row>
}
