import React, {useState} from "react";
import {
    Dimensions,
    Image,
    Modal, Pressable,
    TouchableOpacity,
    View
} from "react-native";
import BtnPrimary from "../buttons/BtnPrimary";
import {downloadFileAsync} from "../sugar";
import {center, flex, jCenter, pv1} from "../../const";

export default function ({
                             url,
                             style = {maxHeight: 50, maxWidth: 50}
                         }) {
    const [visible, setVisible] = useState(false)

    if (!url || typeof url !== 'string') return null

    const {height, width} = Dimensions.get('window')
    return <View>
        <TouchableOpacity onPress={() => setVisible(!visible)} style={style}>
            <Image source={{uri: url}} style={[{resizeMode: 'contain'}, style]}/>
        </TouchableOpacity>
        <Modal animationType={'fade'} visible={visible} transparent={true}>
            <Pressable style={[flex, center, jCenter, {backgroundColor: '#000'}]} onPress={() => {
                setVisible(false)
            }}>
                <Image
                    source={{uri: url}}
                    style={[{height, width, resizeMode: 'contain'}]}
                />
            </Pressable>
            <View style={[{backgroundColor: '#000'}, pv1, center, jCenter]}>
                <BtnPrimary
                    title={'скачать'}
                    style={{width: 150}}
                    onPress={async () => {
                        await downloadFileAsync(url, 'jpg')
                    }}
                />
            </View>
        </Modal>
    </View>
}
