import {Dimensions, Modal, Pressable, Text, View} from "react-native";
import {
    bgDangerLight,
    bgPrimaryLight, bgSecondary, bgSuccessLight, bgWarningLight, border,
    center,
    flex,
    fRow, jCenter,
    ml1,
    mt1,
    mv1,
    shadow, white
} from "../const";
import {useContext} from "react";
import {AppContext} from "../screens/contexts";
import {Row} from "../lib/markup/markup";
import {H3} from "../lib/text/H";
import BtnPrimary from "../lib/buttons/BtnPrimary";
import BtnDanger from "../lib/buttons/BtnDanger";
import BtnWarning from "../lib/buttons/BtnWarning";
import {BtnSuccess} from "../lib/buttons/BtnSuccess";
import BtnSecondary from "../lib/buttons/BtnSecondary";


export const pureAlert = {isVisible: false, title: null, body: null, buttons: [], remove: null}

export function Alert() {
    const {alert, setAlert} = useContext(AppContext)

    function onClose() {
        setAlert(pureAlert)
    }

    function background() {
        switch (alert.background) {
            case 'primary':
                return bgPrimaryLight
            case 'danger':
                return bgDangerLight
            case 'warning':
                return bgWarningLight
            case 'success':
                return bgSuccessLight
            default:
                return bgSecondary
        }
    }

    return <Modal
        animationType={'fade'}
        visible={alert.isVisible}
        transparent={true}
        style={{
            zIndex: 10,
            elevation: 10
        }}
    >
        <Pressable style={[flex, center]} onPress={onClose}>
            <View style={{
                top: 100,
                position: 'absolute',
                paddingVertical: 10,
                paddingHorizontal: 10,
                maxHeight: Dimensions.get('window').height - 120,
                maxWidth: Dimensions.get('window').width - 20,
                minWidth: 300,
                minHeight: 100,
                ...border,
                ...shadow,
                ...background(),
            }}
            >
                <Row visible={alert.title}>
                    <H3 style={[flex, center, alert.background ? white : null]}>{alert.title}</H3>
                </Row>
                <View visible={alert.body} style={[mv1]}>
                    {
                        typeof alert.body === 'string' ?
                            <Text style={[mt1, alert.background ? white : null, center, flex]}>{alert.body}</Text>
                            :
                            alert.body
                    }
                </View>
                <View
                    style={[mt1, alert.vertical ? null : fRow, jCenter]}
                    visible={alert.buttons.length}
                >
                    {
                        alert.buttons.map((button, key) => {
                            if (!button) return null
                            const btnStyle = alert.vertical ? mt1 : flex
                            let props = {
                                title: button.title || button.text || 'ОК',
                                key: key,
                                style: alert.vertical ?
                                    null
                                    :
                                    key ? ml1 : {},
                                onPress: () => {
                                    if (button.onPress) button.onPress()
                                    onClose()
                                }
                            }
                            switch (button.style) {
                                case 'primary':
                                    return <View style={btnStyle} key={key}><BtnPrimary {...props}/></View>
                                case 'danger':
                                    return <View style={btnStyle} key={key}><BtnDanger {...props}/></View>
                                case 'warning':
                                    return <View style={btnStyle} key={key}><BtnWarning {...props}/></View>
                                case 'success':
                                    return <View style={btnStyle} key={key}><BtnSuccess {...props}/></View>
                                default:
                                    return <View style={btnStyle} key={key}><BtnSecondary {...props}/></View>
                            }
                        })
                    }
                    <BtnDanger
                        visible={alert.remove}
                        title={'удалить'}
                        style={flex}
                        onPress={() => {
                            onClose()
                            alert.remove()
                        }}/>
                    <BtnSecondary
                        visible={alert.remove}
                        style={[flex, ml1]}
                        title={'отмена'}
                        onPress={onClose}
                    />
                    <BtnSecondary
                        visible={!alert.buttons.length && !alert.remove}
                        title={'OK'} onPress={onClose}
                        style={{width: 60}}
                    />
                </View>
            </View>
        </Pressable>
    </Modal>
}

