import {View, Text} from "react-native";
import {useContext} from "react";
import {AppContext} from "../contexts";
import {bold, center, danger, jCenter, mt3, mt5} from "../../const";
import BtnPrimary from "../../lib/buttons/BtnPrimary";
import defaultUrl from "./user_profile/DefaultUrl";


export default function () {
    const {PATCH, navigation, user} = useContext(AppContext)
    return <View style={[center, jCenter, mt5]}>
        <Text style={[danger, bold]}>сервер выключен</Text>
        <BtnPrimary
            style={[mt3, {width: 200}]}
            title={'включить'}
            onPress={async () => {
                await PATCH('main/ServiceStatuses/1', {online: 1})
                navigation.navigate(defaultUrl(user))
            }}
        />
    </View>
}
