import {useContext, useEffect, useState} from "react";
import {AppContext, CurrentUserContext} from "../../contexts";
import {View} from "react-native";
import TextField from "../../../lib/fields/TextField";
import Select from "../../../lib/fields/Select";
import {asObject, screenSize} from "../../../lib/sugar";
import Loading from "../../../lib/static/Loading";
import {field, flex, fRow, mr1} from "../../../const";
import {isAdministrations, isCadCam} from "../../../objects/user_methods";
import {TextInput} from "react-native-web";

export default function () {
    const {user, GET, PATCH} = useContext(AppContext)
    if (!isCadCam(user) && !isAdministrations(user)) return null
    const {currentUser} = useContext(CurrentUserContext)
    if (!currentUser) return null
    const [hosts, setHosts] = useState([])
    const [currentHost, setCurrentHost] = useState()
    useEffect(async () => {
        let isMounted = true
        if (!hosts.length) {
            setHosts(
                await GET('main/hosts')
            )
        }
        if (isMounted) setHost(currentUser.host)
        return () => isMounted = false
    }, [hosts])

    function setHost(target) {
        if (!target) {
            currentUser.host = null
            setCurrentHost(null)
            return
        }
        for (let host of hosts) {
            if (host.id === target) {
                currentUser.host = host
                setCurrentHost(host)
                break
            }
        }
    }

    async function save(field, value) {
        PATCH(`main/hosts/${currentUser.host.id}`, asObject(field, value))
    }

    if (!hosts.length) return <Loading/>
    // const style = screenSize() === 'desktop' ? flex : {width: Dimensions.get('window').width - 20}
    const wrpStyle = [flex, mr1] //screenSize() === 'desktop' ? [flex,] : {}
    console.log(currentHost, '-------------')
    return <View style={screenSize() === 'desktop' ? fRow : {height: 120}}>
        <View style={wrpStyle}>
            <Select
                canBeNull={true}
                placeholder={'хост'}
                items={hosts}
                label={'name'}
                value={'id'}
                selected={currentUser.host}
                onChange={async host => {
                    if (!host) host = null
                    PATCH(`main/users/${currentUser.id}`, {host})
                    currentUser.host = host
                    setHost(host)
                }}
            />
        </View>
        <View style={wrpStyle}>
            <TextInput
                style={field}
                visible={currentHost}
                placeholder={'Cad-Data'}
                defaultValue={currentHost ? currentHost.cad_data : ''}
                onChangeText={value => save('cad_data', value)}
            />
        </View>
        <View style={wrpStyle}>
            <TextInput
                style={field}
                visible={currentHost}
                placeholder={'Sum-Projects'}
                defaultValue={currentHost ? currentHost.sum_projects : ''}
                onChangeText={value => save('sum_projects', value)}
            />
        </View>
        <View style={wrpStyle}>
            <TextInput
                style={field}
                visible={currentHost}
                placeholder={'Sum-Projects-2'}
                defaultValue={currentHost ? currentHost.sum_projects2 : ''}
                onChangeText={value => save('sum_projects2', value)}
            />
        </View>
    </View>
}
