import {colors, fRow} from "../const";
import {Text, View} from "react-native";
import {useContext} from "react";
import {AppContext} from "../screens/contexts";
import {isClinic} from "../objects/user_methods";

export default function OrderStatus({status}) {
    if (!status) return null
    const {user, orderStatuses} = useContext(AppContext)
    const mode = isClinic(user) ? 'clinic': 'lab'
    let color = null
    try {
        color = colors[orderStatuses[status][mode + '_color']]
    } catch (e) {
    }
    let text= null
    try {
        text = orderStatuses[status][mode]
    } catch (e){}
    return <View style={[fRow, {width: 70}]}>
        <Text numberOfLines={1} style={[{ flex: 1, textAlign: "left" }, color]}>
            {text}
        </Text>
    </View>
}
