import {AppContext} from "../../screens/contexts";
import {Row} from "../../lib/markup/markup";
import {bgPrimary, ml1, mr2, mt1} from "../../const";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {useContext} from "react";

export default function TechRow({row, statuses, setStatuses, stageID}) {
    const {DELETE, POST} = useContext(AppContext)
    function isActive(tech) {
        for (let status of statuses) {
            if (status.tech === tech.id) return true
        }
        return false
    }
    return <Row style={[mt1, mr2]}>
        {row.map((tech, key) => {
            return <BtnToggle
                style={[{width: 60}, ml1]}
                key={key}
                title={tech.code}
                isActive={isActive(tech)}
                onPress={async () => {
                    for (let n = 0; n < statuses.length; n++) {
                        if (statuses[n].tech === tech.id) {
                            let newStatuses = statuses.slice()
                            const removed = newStatuses.splice(n, 1)[0]
                            DELETE('main/TechStageStatuses/' + removed.id)
                            setStatuses(newStatuses)
                            return
                        }
                    }
                    const data = await POST('main/TechStageStatuses/', {tech: tech.id, stage: stageID, status: 1}, false)
                    setStatuses(statuses.concat([data]))
                }}
            />
        })}
    </Row>
}
