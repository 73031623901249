import {Dimensions, Modal, Pressable, View, TouchableOpacity} from "react-native";
import {bgSecondary, border,
    center, danger,
    flex,
    mt2, mt3,
    mv2,
    shadow
} from "../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../screens/contexts";
import {Row} from "../lib/markup/markup";
import {H1, H3} from "../lib/text/H";
import {HR} from "../lib/static/HR";
import {faMicrophone} from "@fortawesome/free-solid-svg-icons";
import FA from "../lib/static/FA";
import BtnToggle from "../lib/buttons/BtnToggle";
import { Audio } from 'expo-av';


export default function () {
    const {GET, POST, shoutIsVisible, setShoutIsVisible, user} = useContext(AppContext)
    if (!user || !user.has_voice) return null
    const [state, setState] = useState({recording: null})

    const url = 'lab/shouts/'
    function updateState(args) {
        setState({...state, ...args})
    }
    useEffect(async () => {
        const shouts = await GET(url)
        updateState({shouts})
    }, [])

    function isFloorActive(floor) {
        for (let shout of state.shouts) {
            if (shout.second_floor === floor && !shout.selected) return
        }
        return true
    }

    async function startRecording() {
        let access = (await Audio.getPermissionsAsync()).granted
        if (!access) access = (await Audio.requestPermissionsAsync()).granted
        if (!access) return
        try {
            await Audio.setAudioModeAsync({
                allowsRecordingIOS: true,
                playsInSilentModeIOS: true,
            });
            const { recording } = await Audio.Recording.createAsync(
                Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY
            );
            updateState({recording});
        } catch (err) {
            console.error('Failed to start recording', err);
        }
    }

    async function stopRecording() {
        await state.recording.stopAndUnloadAsync();

        const audioURI = state.recording.getURI();

        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", audioURI, true);
            xhr.send(null);
        });

        const blobToBase64 = (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        };
        const file = await blobToBase64(blob)
        let channels = ''
        let shouts = state.shouts.slice()
        for (let i=0; i<shouts.length; i++) {
            if (shouts[i].selected) {
                if (channels) channels += ','
                channels += shouts[i].channels
                shouts[i].selected = false
            }
        }
        channels = new Set(channels.split(','))
        channels = Array.from(channels)
        channels = channels.join(',')
        if (!channels) channels = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16'
        await POST(url + 'new_voice', {file, channels})
        updateState({recording: undefined, shouts});
        setShoutIsVisible(false)
    }

    if (!state.shouts) return null
    return <Modal
        animationType={'fade'}
        visible={shoutIsVisible}
        transparent={true}
        style={{
            zIndex: 10,
            elevation: 10
        }}
    >
        <Pressable
            style={[flex, center]}
            onPress={() => setShoutIsVisible(false)}
        >
            <View style={{
                top: 100,
                position: 'absolute',
                paddingVertical: 10,
                paddingHorizontal: 10,
                maxHeight: Dimensions.get('window').height - 120,
                maxWidth: Dimensions.get('window').width - 20,
                minWidth: 300,
                minHeight: 100,
                ...border,
                ...shadow,
                ...bgSecondary,
                ...center
            }}
            >

                <H1 style={danger}>ничего не выбрано - отправиться всем</H1>
                <Row style={mv2}>
                    <BtnToggle
                        isActive={isFloorActive(false)}
                        title={'первый этаж'}
                        onPress={() => {
                            let shouts = state.shouts.slice()
                            const isActive = !isFloorActive(false)
                            for (let i=0; i<state.shouts.length; i++) {
                                if (!shouts[i].second_floor) shouts[i].selected = isActive
                            }
                            updateState({shouts})
                        }}
                    />
                </Row>
                <Row style={mv2}>
                    {state.shouts.map((shout, key) => {
                        if (shout.second_floor) return null
                            return <BtnToggle
                                isActive={shout.selected}
                                key={key}
                                title={shout.name}
                                style={{marginHorizontal: 2}}
                                onPress={() => {
                                    let shouts = state.shouts.slice()
                                    shouts[key].selected = !shouts[key].selected
                                    updateState({shouts})
                                }}
                            />})}
                </Row>
                <HR/>
                <Row style={mv2}>
                    <BtnToggle
                        isActive={isFloorActive(true)}
                        title={'второй этаж'}
                        onPress={() => {
                            let shouts = state.shouts.slice()
                            const isActive = !isFloorActive(true)
                            for (let i=0; i<state.shouts.length; i++) {
                                if (shouts[i].second_floor) shouts[i].selected = isActive
                            }
                            updateState({shouts})
                        }}
                    />
                </Row>
                <Row style={mt2}>
                    {state.shouts.map((shout, key) => {
                        if (!shout.second_floor) return null
                        return <BtnToggle
                            isActive={shout.selected}
                            key={key}
                            style={{marginHorizontal: 2}}
                            title={shout.name}
                            onPress={() => {
                                let shouts = state.shouts.slice()
                                shouts[key].selected = !shouts[key].selected
                                updateState({shouts})
                            }}
                        />})}
                </Row>
                <TouchableOpacity
                    style={[{width: 120}, center, mt3]}
                    onPressIn={async () => {
                        await startRecording()
                    }}
                    onPressOut={async () => {
                        await stopRecording()
                    }}
                >
                    <FA icon={faMicrophone} style={{fontSize: 50}}/>
                    <H3>удерживайте</H3>
                </TouchableOpacity>
            </View>
        </Pressable>
    </Modal>
}

