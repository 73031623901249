import {View} from "react-native";
import React from "react";
import {bgPrimary} from "../../const";

export function HR({size=1, style, vertical=false, visible=true}) {
    if (!visible) return null

    return <View style={[
        vertical ? {height: '100%', width: size}: {width: '100%', height: 1},
        bgPrimary,
        style
    ]}/>
}
