import {faMailBulk} from "@fortawesome/free-solid-svg-icons";
import BtnSecondary from "../../lib/buttons/BtnSecondary";
import {AppContext} from "../../screens/contexts";
import {useContext} from "react";

export default function (props) {
    const {navigation} = useContext(AppContext)
    if (props.visible !== true) return null
    return <BtnSecondary
        title={faMailBulk}
        style={props.style}
        onPress={() => navigation.navigate('Chats')}
    />
}
