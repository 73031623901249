import {useContext} from "react";
import {AppContext, CurrentUserContext} from "../../contexts";
import {Text, View} from "react-native";
import {isPhoneCorrect, screenSize} from "../../../lib/sugar";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import {esc, field, fRow, mb1, mr1, primary} from "../../../const";
import {api} from "../../../lib/api";
import BtnDanger from "../../../lib/buttons/BtnDanger";
import {isAdministrations} from "../../../objects/user_methods";
import Phone from "../../../lib/text/Phone";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import {TextInput} from "react-native";

export default function () {
    const {currentUser, save} = useContext(CurrentUserContext)
    const {setAlert, setUser, user} = useContext(AppContext)
    return <View style={screenSize() === 'desktop' ? fRow : null}>
        <BtnSecondary
            visible={user.id === currentUser.id}
            style={[{width: field.width}, screenSize() === 'desktop' ? mr1 : mb1]}
            title={'выйти'}
            onPress={() => setAlert({
                isVisible: true,
                title: 'выйти из программы?',
                buttons: [
                    {
                        title: 'выйти',
                        style: 'danger',
                        onPress: async () => {
                            await api.logout()
                            setUser(null)
                        }
                    },
                    esc
                ]
            })}
        />
        <BtnDanger
            style={[{width: field.width}, screenSize() === 'desktop' ? mr1 : mb1]}
            visible={user.id !== currentUser.id && isAdministrations(user) && currentUser.is_active}
            title={'заблокировать'}
            onPress={() => setAlert({
                title: 'заблокировать пользователя?',
                body: 'пользователь не сможет входить в программу',
                buttons: [
                    {
                        title: 'заблокировать',
                        style: 'danger',
                        onPress: () => save('is_active', false)
                    },
                    esc
                ]
            })}
        />
        <BtnPrimary
            style={[{width: field.width}, screenSize() === 'desktop' ? mr1 : mb1]}
            visible={user.id !== currentUser.id && isAdministrations(user) && !currentUser.is_active}
            title={'разблокировать'}
            onPress={() => setAlert({
                title: 'разблокировать пользователя?',
                body: 'пользователь сможет входить в программу',
                buttons: [
                    {
                        title: 'разблокировать',
                        style: 'primary',
                        onPress: () => save('is_active', true)
                    },
                    esc
                ]
            })}
        />
        {isAdministrations(user) ? <TextInput
            style={[mr1, field]}
            defaultValue={currentUser.phone}
            onBlur={event => {
                const phone = isPhoneCorrect(event.nativeEvent.text)
                if (!phone) {
                    setAlert({
                        title: 'номер некорректен',
                        body: <View>
                            <Text>11 символов</Text>
                            <Text>начало с 7</Text>
                            <Text>только цифры</Text>
                        </View>
                    })
                } else save('phone', phone)
            }}
        />:null}
        {isAdministrations(user) ? <TextInput
            style={[mr1, field]}
            placeholder={'код'}
            defaultValue={currentUser.code}
            onChangeText={code => save('code', code)}
        />:null}
        <Phone visible={!isAdministrations(user)} style={[primary]}>{currentUser.phone}</Phone>
    </View>
}
