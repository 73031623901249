import {ScrollView} from "react-native";
import {useContext, useEffect, useState} from "react";
import ClinicInfo from "./ClinicInfo";
import LeaveBtn from "./LeaveBtn";
import InviteUser from "./InviteUser";
import RelationWindow from "./RelationWindow";
import {AppContext, ClinicContext} from "../../contexts";
import {H1, H3} from "../../../lib/text/H";
import Loading from "../../../lib/static/Loading";
import {Row} from "../../../lib/markup/markup";
import {center, flexStart, mt1, mv1} from "../../../const";
import Relation from "../../../components/Relations";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import {isAdministrations, isManager} from "../../../objects/user_methods";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


function Bth(props) {
    return null;
}

export default function () {
    const {user, GET, POST, route, setAlert, DELETE, navigation} = useContext(AppContext)
    const [state, setState] = useState(
        {clinic: null, relations: [], self: -1, editable: false, isOwner: false})

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        const clinic = await GET(`main/clinics/${route.params.clinic_id}`)
        const relations = await GET(`clinic/UserClinicRelations/filter`, {clinic: route.params.clinic_id})
        let self = -1
        for (let n = 0; n < relations.length; n++) {
            if (relations[n].user === user.id) {
                self = n
                break
            }
        }
        const editable = (self !== -1 && ['ad', 'ow'].includes(relations[self].status)) || isAdministrations(user)
        const isOwner = (self !== -1 && 'ow' === relations[self].status) || isAdministrations(user)
        setState({clinic, relations, self, editable, isOwner})
    }, [])

    if (!state.clinic) return <Loading/>
    return <ScrollView>
        <ClinicContext.Provider value={state}>
            <Row>
                <H1 style={flexStart}>профиль клиники</H1>
                <Bth visible={isManager(user)} title={faTrash} onPress={() => {
                    setAlert({
                        title: 'удалить клинику?',
                        buttons: [
                            {
                                title: 'удалить',
                                style: 'danger',
                                onPress: async () => {
                                    await DELETE('main/clinics/' + state.clinic.id)
                                    navigation.goBack()
                                }
                            }
                        ]
                    })
                }}/>
            </Row>
            <ClinicInfo editable={state.editable}/>
            <Row style={mv1}>
                <AutocorrectField
                    onSubmitClear={true}
                    visible={isAdministrations(user)}
                    placeholder={'добавить отношение'}
                    onSubmitButton={true}
                    find={async last_name => await GET('main/users/filter', {last_name__istartswith: last_name})}
                    findField={'last_name'}
                    onSubmit={async doctor => {
                        if (!doctor) return
                        const rels = await GET(
                            'clinic/UserClinicRelations/filter', {clinic: state.clinic.id, user: doctor.id})
                        if (rels.length) {
                            setAlert({title: 'пользователь уже состоит в клинике'})
                            return
                        }
                        const response = await POST(
                            'clinic/UserClinicRelations',
                            {clinic: state.clinic.id, user: doctor.id, status: 'do'},
                            false
                        )
                        let newRelations = state.relations.slice()
                        newRelations.push(response)
                        updateState({relations: newRelations})
                    }}
                />
            </Row>
            {state.self !== -1 && <Row style={[mv1, center]}>
                <H3>Ваш статус: </H3>
                <Relation>{state.relations[state.self].status}</Relation>
            </Row>}
            <Row style={mt1} visible={state.self !== -1}>
                <LeaveBtn/>
            </Row>
            <Row style={mt1} visible={state.isOwner && !isAdministrations(user)}>
                <InviteUser/>
            </Row>
            <Row visible={state.editable} style={mt1}>
                {
                    state.relations.map((relation, key) => {
                        if (key === state.self) return null
                        return <RelationWindow key={key} relation={relation}/>
                    })
                }
            </Row>
        </ClinicContext.Provider>
    </ScrollView>

}








