import {ScrollView, View} from "react-native";
import {bgSecondary, flex, jCenter, mt5, p1} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {asObject, EDate, toObject} from "../../../lib/sugar";
import {AppContext, OrderContext, PatientContext} from "../../contexts";
import OrderStatus from "./OrderStatus";
import OrderInfo from "./OrderInfo";
import {isLab, isManager} from "../../../objects/user_methods";
import Loading from "../../../lib/static/Loading";
import {Col, Flex, Row} from "../../../lib/markup/markup";
import Stage from "./Stage";
import ExtraOrder from "./ExtraOrder";
import {HR} from "../../../lib/static/HR";
import {H3} from "../../../lib/text/H";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Btn from "../../../lib/buttons/Btn";


export default function (props) {
    const [state, setState] = useState({
        order: {},
        stages: [],
        ready: false,
        cadcam: [],
        foundry: [],
    })

    const [users, setUsers] = useState({'cadcam': [], 'литейщики': [], 'техники': []})
    const [prices, setPrices] = useState()
    const {user, PATCH, GET, setAlert, POST, DELETE, holidays} = useContext(AppContext)
    const [refresh, setRefresh] = useState(false)
    const {patient} = useContext(PatientContext)

    function reload(){
        setRefresh(!refresh)
    }

    async function saveOrder(field, value) {
        const args = asObject(field, value)
        updateState({order: {...state.order, ...args}})
        PATCH(`main/orders/${state.order.id}`, args)
        if (field === 'shade') POST(
            'main/notifications/for_order',
            {order_id: state.order.id, body: `пациент: ${patient.last_name} цвет: ${value}`}
        )
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    function getPrice(price_id, priceListId) {
        if (!price_id) return
        for (let price of prices[priceListId]) {
            if (price.id === price_id) return price
        }
    }

    function getPrices(text, field, priceListId) {
        let variants = []
        for (let price of prices[priceListId]) {
            if (price[field].toLowerCase().startsWith(text.toLowerCase())) {
                variants.push(price)
            }
            if (variants.length === 10) break
        }
        return variants
    }

    useEffect(async () => {
        updateState({ready: false})

        if (!users.cadcam.length && isLab(user)) {
            const techs = await GET('main/users/filter', {groups__name: 'техники', is_active: 1,})
            const cadcam = await GET('main/users/filter', {groups__name: 'cadcam', is_active: 1,})
            const foundry = await GET('main/users/filter', {groups__name: 'литейщики', is_active: 1})
            setUsers({cadcam, 'литейщики': foundry, 'техники': techs})
            const positions = await GET('main/prices')
            const sections = await GET('main/pricesections')

            function getPriceList(sectionId) {
                for (let section of sections) {
                    if (section.id === sectionId) return section.price_list
                }
            }

            let prices = {}
            for (let position of positions) {
                const priceListId = getPriceList(position.section)
                if (typeof prices[priceListId] === 'undefined') prices[priceListId] = []
                prices[priceListId].push(position)
            }
            setPrices(prices)
        }


        const stages = await GET('main/stages/filter', {order: props.order.id})
        const cadcam = await GET('main/cadcams/filter', {order: props.order.id})
        const foundry = await GET('main/foundries/filter', {order: props.order.id})
        updateState({order: props.order, stages, cadcam, foundry, ready: true, users})
    }, [props.order, refresh])

    if (!state.ready) return <Loading/>

    return <ScrollView style={[flex, p1]}>
        <OrderContext.Provider
            value={{
                state, user, saveOrder, setAlert, updateState, POST, DELETE, GET, PATCH, getPrice, getPrices, holidays
        }}>
            <OrderStatus remove={props.remove}/>

            <OrderInfo/>
            <Formula/>

            <Header
                title={'этапы'}
                creator={isManager(user) ?
                    async () => {
                        const now = new EDate().isoDate()
                        const data = await POST(
                            'main/stages', {order: state.order.id, lab_date:now, clinic_date: now, deliveryNumber: 1})
                        PATCH('main/orders/' + state.order.id, {status: 5})
                        updateState({stages: state.stages.concat(data)})
                    }
                    :
                    null}
            />
            {state.stages.map((stage, key) => {
                return <Stage {...stage} key={key}/>
            })}

            <Col visible={isLab(user)}>
                <ExtraOrders
                    users={users.cadcam}
                    url={'main/cadcams'}
                    type={'cadcam'}
                    title={'CAD/CAM'}
                    reload={reload}
                />
                <ExtraOrders
                    users={users['литейщики']}
                    url={'main/foundries'}
                    type={'foundry'}
                    title={'литьё'}
                    reload={reload}
                />
            </Col>
            <Row style={{marginTop: 50}}/>
        </OrderContext.Provider>
    </ScrollView>
}

function ExtraOrders({users, url, type, title, reload}) {
    const {user, state, updateState, POST, holidays} = useContext(OrderContext)
    if (!isLab(user)) return null

    return <Col visible={isLab(user)}>
        <Header
            title={title}
            creator={isManager(user) ?
                async () => {
                    let date = new EDate()
                    date.change({days: 1})
                    while (holidays.includes(date.isoDate()) || date.getDay() === 6){
                        date.change({days: 1})
                    }
                    const data = await POST(url, {
                        order: state.order.id,
                        must_complete_in: date.isoDate(),
                        created_at: new EDate().isoDatetime()
                    })
                    updateState(toObject(type, state[type].concat(data)))
                }
                :
                null}
        />
        {state[type].map((extraOrder, key) =>
            <ExtraOrder
                key={key}
                {...extraOrder}
                users={users}
                url={url}
                type={type}
                reload={reload}
            />
        )}
    </Col>
}

function Formula({order}) {
    return <View/>
}

function Header({creator, title}) {
    return <View>
        <HR style={[mt5]}/>
        <Row style={[jCenter, bgSecondary]}>
            <H3>{title}</H3>
            <Btn
                title={faPlus}
                visible={creator}
                onPress={creator}
            />
            <Flex/>
        </Row>
    </View>
}
