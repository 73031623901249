import {Flex, PressRow, Row} from "../../markup/markup";
import {borderTable, center, mh1, ml1, mr1, mt1, mv1, p1} from "../../../const";
import {ScrollView} from "react-native";
import {useContext, useEffect, useRef, useState} from "react";
import Message from "./Message";
import Input from "./Input";
import storage from "../../storage";
import Loading from "../../static/Loading";
import BtnPrimary from "../../buttons/BtnPrimary";
import BtnDanger from "../../buttons/BtnDanger";
import {isDesktop, replaceAll} from "../../sugar";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {H1} from "../../text/H";
import FA from "../../static/FA";
import {AppContext} from "../../../screens/contexts";


export default function ({messages, createMessage, deleteMessage, chatId, goToContacts, chatName, fastAnswers}) {
    if (!messages) return null
    const scrollViewRef = useRef()
    const {isFocused} = useContext(AppContext)
    const [state, setState] = useState({messagesBuffer: []})

    function updateState(args) {
        setState({...state, ...args})
    }

    async function setBuffer(buffer) {
        await storage.set('messagesBuffer', buffer)
        updateState({messagesBuffer: buffer})
    }

    function inBuffer(message) {
        for (let bufferMessage of state.messagesBuffer) {
            if (bufferMessage.id === message.id) return true
        }
        return false
    }

    useEffect(async () => {
        let args = {}
        const cameraBuffer = await storage.get('cameraBuffer')
        if (cameraBuffer) {
            createMessage({chatId, ...cameraBuffer, file: cameraBuffer.uri, fileName: 'image.png'})
            await storage.remove('cameraBuffer')
            return
        }
        const messagesBuffer = await storage.get('messagesBuffer')
        if (!messagesBuffer) args.messagesBuffer = []
        else args.messagesBuffer = messagesBuffer
        updateState(args)
    }, [isFocused])

    if (!state.messagesBuffer) return <Loading/>
    return <Flex style={[borderTable, mt1]}>
        <PressRow visible={!isDesktop()} onPress={goToContacts} style={[center, ml1]}>
            <FA icon={faArrowLeft}/>
            <H1>{chatName}</H1>
        </PressRow>
        <ScrollView
            style={[p1]}
            ref={scrollViewRef}
            onContentSizeChange={() => scrollViewRef.current.scrollToEnd({animated: false})}
        >
            {messages.map((message, key) =>
                <Message
                    key={key}
                    message={message}
                    deleteMessage={deleteMessage ? () => deleteMessage(message.id) : null}
                    inBuffer={inBuffer(message)}
                    chatId={chatId}
                    setInBuffer={value => {
                        let newBuffer = state.messagesBuffer.slice()
                        for (let n = 0; n < newBuffer.length; n++) {
                            if (newBuffer[n].id === message.id) {
                                newBuffer.splice(n, 1)
                                setBuffer(newBuffer)
                                return
                            }
                        }
                        setBuffer(newBuffer.concat(message))
                    }}
                />)}
        </ScrollView>

        {state.messagesBuffer.length ?
            <Row style={mv1}>
                <BtnPrimary
                    title={`вставить: ${state.messagesBuffer.length}`}
                    style={mr1}
                    onPress={() => {
                        for (let message of state.messagesBuffer) {
                            let file = message.file || ''
                            if (file) {
                                file = file.replace('https://api.creative-lab.online/media/', '')
                                file = file.replace('http://127.0.0.1:8000/media/', '')
                                file = file.replace('messages/messages/', 'messages/')
                                file = replaceAll(file, '%3A', ':')
                                file = replaceAll(file, '%40', '@')
                            }
                            createMessage({
                                chatId,
                                body: message.body,
                                file
                            })
                        }
                        setBuffer([])
                    }}
                />
                <BtnDanger
                    title={'очистить буфер'}
                    onPress={() => setBuffer([])}
                />
            </Row>
            :
            null
        }
        <Input createMessage={args => createMessage({...args, chatId})} fastAnswers={fastAnswers}/>
    </Flex>
}
