import {EDate, fixWidth, isDesktop, isSmall} from "../../lib/sugar";
import {useContext, useState} from "react";
import {AppContext} from "../../screens/contexts";
import {
    absolute,
    bgSecondary,
    border,
    center,
    flex,
    fRow,
    jCenter, ml1, mr1,
    mt1,
    p2,
    ph1, primary, secondary,
    shadow
} from "../../const";
import {Col, Flex, FlexRow, PressRow, Row} from "../../lib/markup/markup";
import {isAdministrations, isClinic, isLab, isManager} from "../../objects/user_methods";
import Statuses from "./Statuses";
import LabTime from "./LabTime";
import ClinicTime from "./ClinicTime";
import {Modal, Pressable, View} from "react-native";
import BtnToggle from "../../lib/buttons/BtnToggle";
import TechTable from "./TechTable";
import DeliveryNumber from "./DeliveryNumber";
import LabTimeChose from "./LabTimeChose";
import ClinicTimeChose from "./ClinicTimeChose";
import {faCheck, faSquare} from "@fortawesome/free-solid-svg-icons";
import {TouchableOpacity} from "react-native";
import FA from "../../lib/static/FA";


export const deliveryTimeWidth = 450
export default function (props) {
    const {getTech} = useContext(AppContext)
    let otherTechs = ''
    if (props.otherTechs) {
        otherTechs = props.otherTechs.split(',')
        for (let status of props.statuses) {
            const code = getTech(status.tech)?.code
            const index = otherTechs.indexOf(code)
            if (index !== -1) otherTechs.splice(index, 1)
        }
        otherTechs = otherTechs.join(',')
    }
    const [state, setState] = useState({
        lab_time: props.lab_time,
        lab_date: new EDate(props.lab_date),
        clinic_time: props.clinic_time,
        clinic_date: new EDate(props.clinic_date ? props.clinic_date : props.lab_date),
        open: false,
        pickup: props.pickup,
        error: '',
        deliveryNumber: props.deliveryNumber,
        statuses: props.statuses,
        otherTechs,
        techTable: null,
        orderID: props.orderID,
        confirmed: props.confirmed
    })

    const {PATCH, user, LOG, setAlert} = useContext(AppContext)

    let times = [[]]
    let i = 0
    const empty = '--:--'
    for (let n = 8; n < 22; n++) {
        times[i].push(n)
        if (n === 12 || n === 17) {
            times.push([])
            i++
        }
    }
    times[2].push(empty)

    function updateState(args) {
        setState({...state, ...args})
    }

    function save(args, log) {
        let data = {}
        for (let key in args) {
            if (args[key] instanceof EDate) data[key] = args[key].isoDate()
            else data[key] = args[key]
            if (key === 'lab_date' || key === 'deliveryNumber') data.courier_task = null
        }
        if (typeof args.lab_date !== 'undefined' || typeof args.deliveryNumber !== 'undefined') {
            for (let status of state.statuses) {
                if ([11, 8, 9].includes(+status.status)) {
                    PATCH('main/TechStageStatuses/' + status.id, {status: 1})
                    data.confirmed = 0
                }
            }
            PATCH('main/orders/' + state.orderID, {status: 5})
        }
        PATCH('main/stages/' + props.stage_id, data)
        LOG(log, props.patientID)
        updateState(args)
    }

    const size = isDesktop() ? {maxWidth: 950, maxHeight: 400} : {maxWidth: 400, maxHeight: 800}

    return <View
        style={[
            props.style,
            flex,
            jCenter,
            state.confirmed ? {backgroundColor: '#bcd1ff'}: null
        ]}
    >
        <FlexRow style={center}>
            {isManager(user) ? <TouchableOpacity
                onPress={() => {
                    PATCH('main/stages/' + props.stage_id, {confirmed: !state.confirmed ? 1: 0})
                    PATCH('main/orders/' + props.orderID, {status: !state.confirmed ? 7: 5})
                    updateState({confirmed: !state.confirmed})
                }}
            >
                <FA icon={state.confirmed ? faCheck: faSquare} style={state.confirmed ? primary: secondary}/>
            </TouchableOpacity>: null}
            <Flex visible={isLab(user)}>
                <Statuses
                    statuses={state.statuses}
                    otherTechs={state.otherTechs}
                    orderID={props.orderID}
                    patientID={props.patientID}
                />
            </Flex>
            <PressRow
                onPress={() => {
                    if (isClinic(user) || isAdministrations(user)) updateState({open: true})
                }}
            >
                <LabTime
                    lab_time={state.lab_time}
                    lab_date={state.lab_date}
                    pickup={state.pickup}
                    full={props.full}
                />
                <ClinicTime
                    clinic_time={state.clinic_time}
                    clinic_date={state.clinic_date}
                    times={times}
                />
            </PressRow>
        </FlexRow>

        <Modal visible={state.open} transparent={true}>
            <Pressable style={[flex, center]} onPress={() => {
                if (!state.statuses.length) {
                    setAlert({
                        title: 'техники не указаны!',
                        background: 'warning'
                    })
                    return
                }
                if (props.reload) props.reload()
                else updateState({open: false})
            }}>
                <Pressable style={[
                    bgSecondary,
                    border,
                    p2,
                    {...size, top: isDesktop() ? 150: 0},
                    absolute,
                    isDesktop() ? fRow: null,
                    shadow
                ]}>
                    <Col>
                        <TechTable
                            statuses={state.statuses}
                            stage={props}
                            setStatuses={statuses => updateState({statuses})}
                        />
                    </Col>

                    <Col style={ph1} visible={isAdministrations(user) && !isSmall()}/>

                    <Col>
                        <DeliveryNumber deliveryNumber={state.deliveryNumber} save={save}/>

                        <Row>
                            <LabTimeChose
                                clinic_date={state.clinic_date}
                                lab_date={state.lab_date}
                                lab_time={state.lab_time}
                                save={save}
                                times={times}
                                pickup={state.pickup}
                                empty={empty}
                            />
                        </Row>
                    </Col>

                    <Col style={fixWidth(285)}>
                        <Row>
                            <BtnToggle
                                style={[flex, isDesktop() ? null : isSmall() ? mr1: {...mt1, ...mr1}]}
                                onPress={() =>
                                    save({pickup: true, lab_time: null, clinic_time: null}, 'самовывоз')}
                                isActive={state.pickup} title={'самовывоз'}
                            />
                        </Row>
                        <Row visible={state.deliveryNumber !== 3}>
                            <ClinicTimeChose
                                clinic_date={state.clinic_date}
                                lab_date={state.lab_date}
                                clinic_time={state.clinic_time}
                                save={save}
                                times={times}
                                pickup={state.pickup}
                                empty={empty}
                            />
                        </Row>
                    </Col>
                </Pressable>
            </Pressable>
        </Modal>
    </View>
}


