import {View, Text, TouchableOpacity} from "react-native";
import {useContext, useEffect, useState} from "react";
import Loading from "../../lib/static/Loading";
import {AppContext} from "../contexts";
import {Flex} from "../../lib/markup/markup";
import {EDate} from "../../lib/sugar";
import {bold, border, fRow, m1, ml1, p1, secondary} from "../../const";
import BtnSecondary from "../../lib/buttons/BtnSecondary";



export default function (props) {
    const [state, setState] = useState({ready: false, notifications: []})
    const {GET, user, isFocused} = useContext(AppContext)
    const url = 'main/notifications/'
    function updateState(args) {
        setState({...state, ...args})
    }

    function setRead() {
        const newNotifications = state.notifications.slice()
        for (let i=0; i<newNotifications.length; i++) {
            if (newNotifications[i].status === 'saw') continue
            newNotifications[i].status = 'saw'
            PATCH(`${url}${newNotifications[i].id}/`, {status: 'saw'})
        }
        updateState({notifications: newNotifications})
    }

    useEffect(async () => {
        let date = new EDate()
        date.change({days: -7})
        const notifications = await GET(url + 'filter', {user: user.id, datetime__gt: date.isoDate()})
        updateState({ready: true, notifications})
    }, [isFocused])

    if (!state.ready) return <Loading/>
    return <Flex>
        <BtnSecondary
            title={'все прочитано'}
            onPress={setRead}
        />
        {state.notifications.map((notification, key) => <Notification {...notification} key={key}/>)}
    </Flex>
}

function Notification(props) {
    const {navigation} = useContext(AppContext)

    return <TouchableOpacity
        style={[border, p1, m1, fRow]}
        onPress={() => navigation.navigate('Patient', {patient_id: props.patient})}
    >
        <Text style={secondary}>{new EDate(props.datetime).ruDateTime(false)}</Text>
        <Text style={[ml1, props.status !== 'saw' ? bold: null]}>{props.body}</Text>
    </TouchableOpacity>

}
