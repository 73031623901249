import {View, ScrollView} from "react-native";
import {AppContext, CurrentUserContext} from "../../contexts";
import {useContext, useEffect, useState} from "react";
import {asObject, hasGroup, randInt, screenSize} from "../../../lib/sugar";
import Loading from "../../../lib/static/Loading";
import {Row} from "../../../lib/markup/markup";
import MainOptions from "./MainOptions";
import PersonalSettings from "./PersonalSettings";
import {isAdministrations, isCourier, isDirector, isLab, isSuperuser, isTech} from "../../../objects/user_methods";
import ManageGroup from "./ManageGroup";
import {center, flexStart, fRow, ml1, mt1, mv2, primary} from "../../../const";
import DefaultUrl from "./DefaultUrl";
import {B} from "../../../lib/text/Style";
import Toggle from "../../../lib/fields/Toggle";
import DoctorOptions from "./DoctorOptions";
import HostSettings from "./HostSettings";
import {H1, H3} from "../../../lib/text/H";
import Btn from "../../../lib/buttons/Btn";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {HR} from "../../../lib/static/HR";
import Select from "../../../lib/fields/Select";


export default function (props) {
    const [currentUser, setCurrentUser] = useState(null)
    const {route, user, GET, PATCH, isFocused, navigation, DELETE} = useContext(AppContext)
    const [monitors, setMonitors] = useState([])
    const [refresh, setRefresh] = useState(false)

    async function save(arg, value) {
        // if (value === true) value = 1
        // else if (value === false) value = 0
        let args = asObject(arg, value)
        if (arg === 'has_cameras') {
            let abc = 'abcdifghijklmnopqrstuvwxyz01234567899'
            args.video_key = ''
            for (let n=0; n<64; n++) {
                let sym = abc[randInt(0, abc.length-1)]
                if (randInt(0, 1)) {
                    args.video_key += sym.toUpperCase()
                } else args.video_key += sym
            }
        }
        PATCH(`main/users/${currentUser.id}`, args)
    }

    function reload() {
        setRefresh(!refresh)
    }

    useEffect(async () => {
        let isMounted = true
        const user_id = route.params && route.params.user_id ? route.params.user_id : user.id
        const data = await GET(`main/users/${user_id}`)
        if (data.has_cameras) {
            let monitors = await GET('lab/monitors')
            const monitorAccesses = await GET('lab/monitors/access', {user: data.id})
            for (let i=0; i<monitors.length; i++) {
                monitors[i].access = monitorAccesses.includes(monitors[i].id)
            }
            monitors.sort((a, b) => {
                if (a.name > b.name) return 1
                return -1
            })
            setMonitors(monitors)
        }
        if (isMounted) setCurrentUser(data)
        return () => isMounted = false
    }, [props, isFocused, refresh])

    if (!currentUser) return <Loading/>
    const offset = mv2
    return <CurrentUserContext.Provider value={{
        currentUser,
        save,
        reload
    }}>
        <ScrollView>
            <Row>
                <H1 style={flexStart}>профиль пользователя</H1>
                <Btn
                    title={faTrash}
                    visible={isSuperuser(user)}
                    onPress={async () => {
                    await DELETE('main/users/' + currentUser.id)
                }}/>
            </Row>
            <Row visible={currentUser}>
                <MainOptions/>
            </Row>
            <Row style={offset} visible={currentUser}>
                <PersonalSettings
                    fields={[
                        ['фамилия', 'last_name'],
                        ['имя', 'first_name'],
                        ['отчество', 'middle_name']
                    ]}
                />
            </Row>
            <Row style={mv2}>
                <Toggle
                    visible={isSuperuser(user)}
                    text={'суперпользователь'}
                    selected={currentUser.is_superuser}
                    onPress={val => save('is_superuser', val)}
                />
                <Toggle
                    text={'WhatsApp уведомления'}
                    onPress={val => save('whatsapp_alert', val)}
                    selected={currentUser.whatsapp_alert}
                />
            </Row>

            <ManageGroup style={offset} reload={reload}/>

            <Row style={offset} visible={isAdministrations(user)}>
                <View style={screenSize() === 'desktop' ? [fRow, center] : null}>
                    <B style={[primary]}>начальный экран: </B>
                    <DefaultUrl/>
                </View>
                {isSuperuser(user) && isTech(currentUser) ?
                <View style={screenSize() === 'desktop' ? [fRow, center, ml1] : null}>
                    <B style={[primary]}>тип техника:</B>
                    <Select
                        canBeNull={false}
                        items={[
                            {id: 'other', name: 'прочее'},
                            {id: 'waxup', name: 'ваксап'},
                            {id: 'syomshik', name: 'съемщик'},
                            {id: 'carcasist', name: 'каркасист'},
                            {id: 'ceramist', name: 'керамист'},
                        ]}
                        selected={currentUser.tech_type}
                        onChange={value => save('tech_type', value)}
                    />
                </View>:null}

            </Row>

            <Row style={offset} visible={isSuperuser(user) && isLab(currentUser)}>
                <Toggle
                    text={'громкая связь'}
                    onPress={val => save('has_voice', val)}
                    selected={currentUser.has_voice}
                />
                <Toggle
                    text={'камеры'}
                    onPress={val => save('has_cameras', val)}
                    selected={currentUser.has_cameras}
                />
                <Toggle
                    visible={isDirector(user) || isSuperuser(user)}
                    text={'красная кнопка'}
                    onPress={val => save('has_red_button', val)}
                    selected={currentUser.has_red_button}
                />
                <Toggle
                    visible={isDirector(user) || isSuperuser(user)}
                    text={'редактирование прайс-листов'}
                    onPress={val => save('can_price_edit', val)}
                    selected={currentUser.can_price_edit}
                />
            </Row>

            <Row visible={currentUser.has_cameras && monitors.length && isSuperuser(user)}>
                <HR/>
                <H3>доступ к камерам</H3>
                {monitors.map((monitor, key) => {
                    return <Toggle
                        key={key}
                        selected={monitor.access}
                        text={monitor.name}
                        onPress={() => {
                            monitors[key].access = !monitors[key].access
                            PATCH('lab/monitors/set_user_access',
                                {monitor: monitor.id, user: currentUser.id, access: monitors[key].access})
                            setMonitors(monitors)
                        }}
                    />
                })}
                <HR/>
            </Row>

            <Row style={offset} visible={hasGroup(currentUser.groups, 'cadcam')}>
                <HostSettings/>
            </Row>
            <Row style={mt1} visible={isCourier(currentUser) && isAdministrations(user)}>
                <Toggle
                    text={'курьер CAD/CAM'}
                    onPress={val => save('is_cadcam_courier', val)}
                    selected={currentUser.is_cadcam_courier}
                />
            </Row>
            <Row style={[offset]} visible={hasGroup(currentUser.groups, 'курьеры')}>
                <View>
                    <B style={[primary]} visible={isAdministrations(user) || isCourier(user)}>паспорт: </B>
                    <PersonalSettings
                        fields={[
                            ['серия и номер', 'passport_number'],
                            ['кем выдан', 'passport_issue'],
                            ['когда выдан', 'passport_date', Date],
                            ['ФИО в родительном падеже', 'passport_fio']
                        ]}
                    />
                </View>
            </Row>

            <Row style={offset} visible={hasGroup(currentUser.groups, 'клиника')}>
                <DoctorOptions/>
            </Row>

        </ScrollView>
    </CurrentUserContext.Provider>
}

























