import {View, Text, TouchableOpacity, Modal} from "react-native";
import {Col, Row} from "../../../lib/markup/markup";
import {H3, H4} from "../../../lib/text/H";
import {Date} from "../../../lib/fields/Date";
import {
    bgSecondary,
    bold,
    border,
    center,
    danger,
    flex,
    jCenter,
    mh1,
    mr1,
    mt1,
    p1,
    primary,
    shadow
} from "../../../const";
import Select from "../../../lib/fields/Select";
import {useContext, useEffect, useState} from "react";
import {AppContext, WarehouseBoxContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import NumberField from "../../../lib/fields/NumberField";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import {copyObject, EDate, fixHeight} from "../../../lib/sugar";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";

export default function () {
    const {GET} = useContext(AppContext)
    const {setError, updateState, modal, createTransaction, tables} = useContext(WarehouseBoxContext)
    if (!modal.visible) return null
    return <View
            style={[flex, center, {marginTop: '30%'}]}
        >
            <View style={[p1, border, shadow, bgSecondary, fixHeight(200)]}>
                <Text style={[bold, primary]}>количество</Text>
                <Row>
                    <NumberField
                        value={modal.count}
                        onChange={count => updateState({modal: {...modal, count}})}
                    />
                </Row>
                <Row style={mt1}>
                    <AutocorrectField
                        placeholder={'поставщик'}
                        find={async name => await GET('lab/companies/filter', {is_our: 0, name__istartswith: name})}
                        findField={'name'}
                        onSubmit={vendor => updateState({modal: {...modal, vendor}})}
                        object={modal.vendor}
                    />
                </Row>
                <Row style={mt1}>
                    <AutocorrectField
                        placeholder={'техник'}
                        find={async last_name => {
                            let response = await GET(
                                'main/users/filter',
                                {
                                    last_name__istartswith: last_name,
                                    groups__name__in: ['cadcam', 'техники', 'литейщики']
                                }
                            )
                            response = new Set(response)
                            return Array.from(response)
                        }}
                        findField={'last_name'}
                        object={modal.tech}
                        onSubmit={tech => updateState({modal: {...modal, tech}})}
                    />
                </Row>
                <Row style={[mh1, p1]}>
                    <Text style={danger}>{modal.error}</Text>
                </Row>
                <Row>
                    <BtnPrimary
                        style={[flex, mr1]}
                        title={'провести'}
                        onPress={() => {
                            console.log(tables, '-----------', modal)
                            if (!+modal.count) {
                                setError('укажите количество')
                                return
                            }
                            if (!modal.vendor && !modal.tech) {
                                setError('укажите техника или поставщика')
                                return
                            }
                            let count = +modal.count
                            let number = +modal.count
                            let struct = copyObject(tables[modal.index].struct)
                            if (modal.tech) {
                                if (count > +modal.total) {
                                    setError('нельзя списать больше чем есть')
                                    return
                                }
                                count = +struct.cells[modal.row][modal.col] - count
                            } else count = +struct.cells[modal.row][modal.col]  + count

                            struct.cells[modal.row][modal.col] = count
                            let args = {
                                vendor: modal.vendor?.id,
                                tech: modal.tech?.id,
                                count: number,
                                row: modal.row,
                                col: modal.col,
                                row_name: struct.row_names[modal.row],
                                col_name: struct.col_names[modal.col],
                                table: tables[modal.index].id,
                                datetime: new EDate().isoDatetime()
                            }
                            createTransaction(struct, args)
                            updateState({modal: {visible: false, count: 0, error: ''}})
                        }}
                    />
                    <BtnSecondary
                        style={[flex]}
                        title={'отмена'}
                        onPress={() => updateState({modal: {visible: false, count: 0, error: ''}})}
                    />
                </Row>
            </View>
        </View>
}
