import {Dimensions, Platform, SafeAreaView, StatusBar} from "react-native";
import {background, flex, padding} from "../const";
import React, {useContext, useEffect, useState} from "react";
import Header from "./Header";
import {arrayToDict, EDate} from "../lib/sugar";
import {AppContext, ScreenContext, DeliveryContext} from "./contexts";
import {api} from "../lib/api";
import {Alert, pureAlert} from "../components/Alert";
import {useIsFocused} from "@react-navigation/native";
import {Flex, Row} from "../lib/markup/markup";
import {isLab} from "../objects/user_methods";
import Loading from "../lib/static/Loading";
import storage from "../lib/storage";
import Shout from "../components/Shout";

export default function (props) {
    const [currentDate, setCurrentDate] = useState(new EDate())
    const screenPadding = Platform.OS === "android" ? StatusBar.currentHeight : 0
    const [state, updateState, screens] = useContext(ScreenContext)
    const [alert, setAlert] = useState(pureAlert)
    const [deliveryNumber, setDeliveryNumber] = useState(1)
    const isFocused = useIsFocused()
    const [shoutIsVisible, setShoutIsVisible] = useState(false)

    let ScreenComponent = null
    const targetName = state.user ? props.route.name : 'Login'
    for (let screen of screens) {
        if (screen.name === targetName) {
            ScreenComponent = screen.component
            break
        }
    }
    function getClinic(value) {
        if (!value) return null
        const field = +value ? 'id' : 'name'
        for (let clinic of state.clinics) {
            if (value === clinic[field]) return clinic
        }
    }
    function getTech(techID) {
        return state.techs[techID]
    }

    function getDoctor(doctorID) {
        return state.doctors[doctorID]
    }

    function showAlert(args) {
        setAlert({...pureAlert, ...args, isVisible: !alert.isVisible})
    }

    if (!state.ready) return <Loading/>
    return <AppContext.Provider
        value={{
            ...state,
            updateAppState: updateState,
            screens,
            getTech,
            getTechs: () => state.techs,
            getUser: async id => {
                let response = await api.get('main/users/' + id)
                return response.data
            },
            shoutIsVisible,
            setShoutIsVisible,
            isFocused,
            deliveryNumber,
            setDeliveryNumber,
            currentDate,
            setCurrentDate,
            setHolidays: holidays => updateState({holidays}),
            getLabStatuses: () => state.labStatuses,
            route: props.route,
            navigation: {
                ...props.navigation,
                navigate: (url, params = {}) => {
                    props.navigation.navigate(
                        url, {...params, previewsScreen: props.route.name})
                }
            },
            alert,
            setAlert: showAlert,
            setUser: user => updateState({user}),
            getClinic,
            whatsapp: state.whatsapp,
            GET: async (url, args = null) => {
                const empty = args ? {} : []
                const response = await api.get(url, args)
                if (response.status === 200) return response.data
                return empty
            },
            POST: async (url, args) => {
                const response = await api.post(url, args)
                if (response.status === 201) return response.data
                else console.log(response, '.........error...........')
                return null
            },
            PATCH: async (url, args) => {
                const response = await api.patch(url, args)
                return (response.status === 200)
            },
            DELETE: async (url, args) => {
                const response = await api.delete(url, args)
                return response.status === 204
            },
            LOG: async (text, patientID) => {
                await api.post(
                    'main/PatientMessages', {text, system: 1, patient: patientID, user: state.user.id})
            }
        }}>
        <DeliveryContext.Provider value={{deliveryNumber, setDeliveryNumber}}>
            <SafeAreaView style={[flex]}>
                <Row
                    style={[
                        background.header,
                        {
                            height: 40 + screenPadding,
                            width: Dimensions.get('window').width,
                            paddingTop: screenPadding
                        },
                    ]}
                >
                    <Header/>
                </Row>
                <Flex style={[padding]}>
                    <ScreenComponent/>
                </Flex>
                <Alert alert={alert}/>
                <Shout/>
            </SafeAreaView>
        </DeliveryContext.Provider>
    </AppContext.Provider>
}
