import {useContext, useEffect, useState} from "react";
import Loading from "../../lib/static/Loading";
import {AppContext} from "../contexts";
import Chat from "../../lib/modules/chat/Chat";
import {EDate, randInt, replaceAll} from "../../lib/sugar";
import {center, jCenter} from "../../const";
import {Flex} from "../../lib/markup/markup";
import QRCode from 'react-native-qrcode-svg';
import {apiUrl} from "../../lib/api";
import axios from "axios";


export default function () {
    const {POST, holidays, GET} = useContext(AppContext)
    const [state, setState] = useState({
        status: 'loading',
        chats: [],
        currentChat: -1,
        qrRequestUpdater: true
    })

    function updateState(args) {
        setState({...state, ...args})
    }

    function getChatIndex(chats, chatId) {
        for (let i = 0; i < chats.length; i++) {
            if (chats[i].chatId === chatId) return i
        }
        return -1
    }

    function messageSerializer(message) {
        return {
            datetime: new EDate(message.datetime),
            body: message.text,
            file: message.file,
            author: message.author,
            id: message.id,
            type: message.fromMe ? 'mine': 'opponent',
            hidden: false
        }
    }

    useEffect(async () => {
        let isMounted = true
        if (state.status === 'loading') {
            let response = await GET(`main/WhatsAppAccounts/1`)
            if (!response.ready) updateState({status: 'qr', qr: response.qr})
            else updateState({status: 'loadingChats'})
        } else if (state.status === 'qr') {
            setTimeout(async () => {
                let response = await GET(`main/WhatsAppAccounts/1`)
                if (!response.ready) updateState({qr: response.qr})
                else updateState({status: 'loadingChats', qrRequestUpdater: !state.qrRequestUpdater})
            }, 1000)
        } else if (state.status === 'loadingChats') {
            const chats = []
            let response = await GET('main/WhatsAppChats')
            for (let chat of response) {
                chats.push({
                    chatId: chat.id,
                    chatName: chat.title ? chat.title : chat.phone,
                    messages: [],
                    avatarLoader: () => chat.avatar,
                    datetime: new EDate(chat.datetime),
                    waChatId: chat.waId
                })
            }
            response = await GET('main/WhatsAppMessages')
            response.sort((a, b) => a.datetime > b.datetime ? 1 : -1)
            for (let message of response) {
                const chatIndex = getChatIndex(chats, message.chat)
                const serializerMessage = messageSerializer(message)
                chats[chatIndex].messages.push(serializerMessage)
            }
            chats.sort((a, b) => {

                try {
                    return a?.messages[a.messages.length-1].datetime > b?.messages[b.messages.length-1].datetime ? -1 : 1;
                } catch (e) {
                    return 0
                }

            })
            if (isMounted) updateState({chats, status: 'ready'})
        }
        return () => isMounted = false
    }, [state])

    async function createMessage(args) {
        let waChatId = ''
        for (let chat of state.chats) {
            if (chat.chatId === args.chatId) {
                waChatId = chat.waChatId
                break
            }
        }
        if (args.uri) POST('main/WhatsAppTasks',
            {waChatId, text: '', type: 'send', file: args.uri.split(';base64,')[1]})
        else POST('main/WhatsAppTasks', {waChatId, text: args.body, type: 'send'})

        const kwargs = {
            datetime: new EDate().isoDatetime(),
            text: args.body,
            file: args.file,
            chat: args.chatId,
            author: 'я',
            fromMe: '1',
            waId: new EDate().isoDatetime() + randInt(0, 99999)
        }

        const message = await POST('main/WhatsAppMessages', kwargs)
        const i = getChatIndex(state.chats, args.chatId)
        let chats = state.chats.slice()
        const serializedMessage = messageSerializer(message)
        if (serializedMessage.file) {
            serializedMessage.file = apiUrl.replace('/api/v1/', '') + serializedMessage.file
        }
        chats[i].messages.push(serializedMessage)
        updateState({chats})
    }


    if (['loadingChats', 'loading'].includes(state.status))  return <Loading/>
    if (state.status === 'qr') return <Flex style={[center, jCenter]}>
        <QRCode
            value={state.qr}
            size={250}
            bgColor='#000'
            fgColor='#fff'/>
    </Flex>
    let whenComing = 'завтра'
    let date = new EDate()
    date.change({days: 1})
    while (holidays.includes(date.isoDate())) {
        date.change({days: 1})
        whenComing = date.ruDate(false)
    }
    return <Chat
        show={state.show}
        currentChat={state.currentChat}
        chats={state.chats}
        createMessage={createMessage}
        showContacts={true}
        fastAnswers={[
            {
                title: 'цвет',
                text: 'Здравствуйте! Не указан цвет.'
            },
            {
                title: 'фото',
                text: 'Здравствуйте! Требуется фото.'
            },
            {
                title: 'приедем сегодня',
                text: 'Здравствуйте! Сегодня приедем'
            },
            {
                title: `приедем ${whenComing}`,
                text: `Здравствуйте! Приедем ${whenComing}`
            }
        ]}
    />
}




