import {View, Text, TouchableOpacity} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../screens/contexts";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import FA from "../../lib/static/FA";
import {EDate, fixHeight, fixWidth, lessTablet} from "../../lib/sugar";
import {
    absolute,
    bgDangerLight,
    bold,
    center,
    dangerLight,
    field,
    fRow,
    jCenter, ml1, mr1, mt1,
    primary, primaryLight,
    secondary
} from "../../const";
import storage from "../../lib/storage";


export default function (props) {
    const {GET, navigation, route, user} = useContext(AppContext)
    if (!user) return null
    const [state, setState] = useState({unread: 0, counter: 0})
    if (route.name === 'Notifications') return null

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        if (user.id === 1) return
        let isMounted = true
        let time = await storage.get('notificationTimer', false)

        if (!time || new EDate(time) < new EDate()) {
            const unread = await GET('main/notifications/unread')
            if (isMounted) {
                updateState({unread})
                let now = new EDate()
                now.change({seconds: 5})
                await storage.set('notificationTimer', now.isoDatetime(), false)
            }
        }
        setTimeout(async () => {
            updateState({counter: state.counter + 1})
        }, 10000)
        return () => isMounted = false
    }, [state.counter])
    const fontSize = lessTablet() ? 16: 20
    return <TouchableOpacity
        onPress={() => navigation.navigate('Notifications')}
        style={[fixWidth(field.height), fixHeight(field.height), center, jCenter, fRow, mt1, ml1]}
    >
        {state.unread ?
            <Text style={[dangerLight, bold, {fontSize}, lessTablet()? null: mr1]}>{state.unread}</Text>
            :
            null}
        <FA icon={faEnvelope} style={[{fontSize}, state.unread ? dangerLight: null]}/>

    </TouchableOpacity>
}
