import {AppContext} from "../../screens/contexts";
import {
    faMicrophone, faPowerOff,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {useContext} from "react";
import BtnBox from "../../lib/buttons/BtnBox";
import {Linking} from "react-native";
import {dangerLight, esc} from "../../const";


export default function ({visible, style}) {
    if (visible !== true) return null
    const {user, setAlert, setShoutIsVisible, GET, PATCH, navigation} = useContext(AppContext)
    let items = []
    if (user.has_voice) items.push({
        title: faMicrophone,
        onPress: () => setShoutIsVisible(true),
        onLongPress: () => {
            GET('lab/shouts/request_to_warehouse/')
        }
    })
    if (user.has_cameras) items.push({title: faVideo, onPress: () => {
        Linking.openURL(`http://85.235.164.102:58888/overview/0/${user.video_key}/`)
        }})
    if (user.has_red_button) items.push({
        textStyle: dangerLight,
        title: faPowerOff,
        onPress: () => {
            setAlert({
                title: 'Выключить систему?',
                buttons: [
                    {
                        title: 'выключить',
                        style: 'danger',
                        onPress: async () => {
                            await PATCH('main/ServiceStatuses/1', {online: 0})
                            navigation.navigate('ServerOff')
                        }
                    },
                    esc
                ]
            })
        }
    })
    return <BtnBox style={style} items={items}/>
}
