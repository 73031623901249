import {Flex, Row} from "../../../lib/markup/markup";
import {bgDanger, borderTable, flex, fRow, mt1, p1} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext, WarehouseBoxContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import WarehouseTable from "./WarehouseTable";
import {ScrollView} from "react-native";
import WarehouseModal from "./WarehouseModal";


export default function (props) {
    if (!props.boxID) return null
    const pureModal = {row: 0, col: 0, visible: false, number: 0, vendor: null, tech: null, error: '', index: -1}
    const [state, setState] = useState({
        ready: false,
        tables: [],
        transactions: [],
        modal: pureModal
    })
    const {GET, isFocused, PATCH, POST} = useContext(AppContext)
    const url = 'lab/tables/'

    function updateState(args) {
        setState({...state, ...args})
    }

    async function createTransaction(struct, args) {
        let tables = state.tables.slice()
        tables[state.modal.index].struct = struct
        PATCH('lab/tables/' + tables[state.modal.index].id, {struct})
        let transaction = await POST('lab/Transactions', args)
        updateState({tables, modal: {...state.modal, error: '', visible: false}})
        props.addTransaction(transaction)
    }

    function setError(error) {
        updateState({modal: {...state.modal, error}})
    }

    useEffect(async () => {
        let isMounted = true
        let tables = await GET(url + 'filter', {box: props.boxID})

        tables.sort((a, b) => {
            if (a.number > b.number) return 1
            return -1
        })

        if (isMounted) updateState({ready: true, tables})
        return () => isMounted = false
    }, [isFocused, props])

    if (!state.ready) return <Loading/>
    return <WarehouseBoxContext.Provider value={{
        updateState,
        ...state,
        pureModal,
        setError,
        createTransaction
    }}>
        {!state.modal.visible ?
            <ScrollView style={[p1, borderTable, {borderTopColor: 'transparent'}]}>
                <Row>
                    {state.tables.map((table, key) => <WarehouseTable
                        key={key}
                        index={key}
                        {...table}
                    />)}
                </Row>
            </ScrollView>
            :
            <WarehouseModal/>}
    </WarehouseBoxContext.Provider>
}
