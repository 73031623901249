import {useContext, useEffect, useState} from "react";
import {Dimensions, Text} from "react-native";
import {AppContext} from "../../contexts";
import {esc, ml1, mt2, p1} from "../../../const";
import {Col, Flex, Row} from "../../../lib/markup/markup";
import Loading from "../../../lib/static/Loading";
import TextField from "../../../lib/fields/TextField";
import Creator from "../../../lib/fields/Creator";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import BtnDanger from "../../../lib/buttons/BtnDanger";
import Filter from "./Filter";
import JoinClinics from "./JoinClinics";


export default function () {
    const {GET, DELETE, isFocused, POST, setAlert} = useContext(AppContext)
    const [state, setState] = useState({
        selected: [],
        clinics: [],
        filter: '',
        ready: false,
        mode: 'filter',
        refresh: false
    })

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let args = {ready: true}
        if (state.filter && state.mode === 'filter') {
            args.clinics = await GET('main/clinics/filter', {is_removed: 0, name__istartswith: state.filter})
            args.clinics.concat(await GET('main/clinics/filter', {is_removed: 0, name__istartswith: state.filter}))
        }
        updateState(args)
    }, [state.filter, isFocused, state.mode, state.refresh])

    if (!state.ready) return <Loading/>
    let maxWidth = Dimensions.get('window').width - 40
    if (maxWidth < 600) maxWidth = 600
    return <Flex style={p1}>
        <Col visible={state.mode === 'filter'}>
        <Text>добавить клинику:</Text>
        <Row>
            <Creator
                url={'main/clinics'}
                placeholder={'наименование клиники'}
                mainField={'name'}
                onPress={async name => {
                    await POST('main/clinics', {name})
                }}
            />
        </Row>
        <Row style={mt2}>
            <Text>поиск (начните набирать):</Text>
        </Row>
        </Col>
        <Row>
            <TextField
                visible={state.mode === 'filter'}
                value={state.filter}
                placeholder={'наименование...'}
                onChange={filter => updateState({filter})}
            />
            <BtnPrimary
                style={ml1}
                visible={state.selected.length > 1 && state.mode === 'filter'}
                title={`объединить ${state.selected.length}`}
                onPress={() => updateState({mode: 'join'})}
            />
            <BtnPrimary
                style={ml1}
                visible={state.selected.length === 1 && state.mode === 'filter'}
                title={`посмотреть`}
                onPress={() => updateState({mode: 'join'})}
            />
            <BtnDanger
                style={ml1}
                visible={state.selected.length && state.mode === 'filter'}
                title={'удалить'}
                onPress={() => {
                    setAlert({
                        title: 'удалить клиники?',
                        body: 'действие необратимо',
                        buttons: [
                            {
                                title: 'удалить',
                                style: 'danger',
                                onPress: () => {
                                    for (let clinic of state.selected) {
                                        DELETE('main/clinics/' + clinic)
                                    }
                                    updateState({selected: [], refresh: !state.refresh})
                                }
                            }, esc
                        ]
                    })
                }}
            />
        </Row>
        <Filter state={state} updateState={updateState}/>
        <JoinClinics state={state} updateState={updateState}/>
    </Flex>
}

