import {useEffect, useState} from "react";
import Image from "../../static/Image";
import storage from "../../storage";


export default function Icon({avatarLoader, chatId}) {
    const [state, setState] = useState({url: ''})

    useEffect(async () => {
        let isMounted = true
        const storageName = 'ChatIcon' + chatId
        let url = await storage.get(storageName, false)
        if (!url) {
            url = await avatarLoader()
            if (url) await storage.set(storageName, url, false)
        }
        if (isMounted) setState({url})
        return () => {isMounted = false}
    }, [])

    const style = {height: 50, width: 50, resizeMode: 'contain', borderRadius: 25}
    if (state.url === '') return <Image style={style} url={require('./noAvatar.png')}/>
    return <Image url={state.url} style={style}/>
}

