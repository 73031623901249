import {useContext} from "react";
import {AppContext, CurrentUserContext} from "../../contexts";
import Select from "../../../lib/fields/Select";
import Loading from "../../../lib/static/Loading";

export default function () {
    const {currentUser} = useContext(CurrentUserContext)
    const {screens, PATCH} = useContext(AppContext)
    if (!currentUser) return <Loading/>
    return <Select
        items={screens}
        label={'title'}
        value={'name'}
        selected={currentUser.default_url}
        placeholder={'первый экран'}
        onChange={default_url => {
            PATCH(`main/users/${currentUser.id}`, {default_url})
        }}
    />
}