import {center} from "../../const";
import {Text} from "react-native";
import React from "react";
import {Row} from "../markup/markup";
import {Capitalize} from "./Style";
import {capitalize} from "../sugar";

export default function FIO({style = {}, obj = null, visible=true, full=false}) {
    if (!visible || !obj) return null
    if (full) return <Text>
        {capitalize(obj.last_name)} {capitalize(obj.first_name)} {capitalize(obj.middle_name)}
    </Text>

    const end = obj.first_name && obj.first_name.length ?
        obj.middle_name && obj.middle_name.length?
            obj.first_name.charAt(0).toUpperCase() + obj.middle_name.charAt(0).toUpperCase()
            :
            obj.first_name.charAt(0).toUpperCase()
        :
        ''
    return <Row style={[center]}>
        <Capitalize style={style}>{obj.last_name}</Capitalize>
        <Text style={style}> {end}</Text>
    </Row>
}
