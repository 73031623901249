import {View, Text, TextInput} from "react-native";
import {bold, esc, field, mt1, mt3, p1, primary} from "../../../const";
import {useContext, useState} from "react";
import {AppContext} from "../../contexts";
import NumberField from "../../../lib/fields/NumberField";
import BtnDanger from "../../../lib/buttons/BtnDanger";
import {fixWidth} from "../../../lib/sugar";
import Creator from "../../../lib/fields/Creator";

export default function () {
    const {DELETE, PATCH, POST, route, navigation, setAlert} = useContext(AppContext)
    const [state, setState] = useState({...route.params.box, url: route.params.url})

    function saveBox(args) {
        PATCH(state.url + state.id, args)
        setState({...state, ...args})
    }

    async function deleteBox() {
        setAlert({
            title: 'удалить раздел?',
            buttons: [
                {
                    title: 'удалить',
                    style: 'danger',
                    onPress: async () => {
                        await DELETE(state.url + state.id)
                        navigation.goBack()
                    }
                },
                esc
            ]
        })

    }

    async function createTable(name) {
        if (!name) setAlert({title: 'введите имя таблицы'})
        const table = await POST('lab/Tables', {name, box: state.id})
        navigation.navigate('TableEdit', {table})
    }


    return <View style={p1}>
        <Text style={[bold, primary]}>очередность</Text>
        <NumberField
            value={state.number}
            style={field}
            onChangeText={number => saveBox({number})}
        />
        <Text style={[bold, primary, mt1]}>наименование</Text>
        <TextInput
            value={state.name}
            style={field}
            onChangeText={name => saveBox({name})}
        />
        <View style={[mt3, fixWidth(field.width)]}>
            <BtnDanger title={'удалить раздел'} onPress={deleteBox}/>
        </View>
        <Creator
            style={mt3}
            placeholder={'новая таблица'}
            onPress={name => createTable(name)}
        />
    </View>
}
