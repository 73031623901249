import {Text, View} from "react-native";
import {danger, primary, success, warning} from "../const";

export default function Relation({children}) {
    switch (children) {
        case 'in':
            return <Text style={warning}>приглашение</Text>
        case 'ba':
            return <Text style={danger}>блок</Text>
        case 'do':
            return <Text>доктор</Text>
        case 'ad':
            return <Text style={primary}>админ</Text>
        case 'ow':
            return <Text style={success}>владелец</Text>
    }
    return <View/>
}