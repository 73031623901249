import {useContext, useEffect, useState} from "react";
import {ScrollView, Pressable, Text, Dimensions, View} from "react-native";
import {AppContext} from "../contexts";
import Table from "../../lib/markup/Table";
import Loading from "../../lib/static/Loading";
import {bgPrimary, bgPrimaryLight, borderTable, center, flex, jCenter, mv1, p1} from "../../const";
import {H3} from "../../lib/text/H";
import {Flex, PressRow, Row} from "../../lib/markup/markup";
import {fixWidth} from "../../lib/sugar";
import BtnToggle from "../../lib/buttons/BtnToggle";
import BtnSecondary from "../../lib/buttons/BtnSecondary";


export default function (props) {
    const {currentDate, GET, holidays, navigation} = useContext(AppContext)
    const [state, setState] = useState(
        {head: [], body: [], total: 0, mode: 'unallocated', unallocated: [], ready: false})

    function updateState(args) {
        setState({...state, ...args})
    }

    function goToPatient(patient_id) {
        navigation.navigate('Patient', {patient_id})
    }

    useEffect(async () => {
        updateState({ready: false})
        if (state.mode === 'total') {
            let dates = []
            let date = currentDate.copy()
            let max = Math.floor((Dimensions.get('window').width - 110) / 40)
            if (max > 10) max = 10
            while (dates.length < max) {
                if (!holidays.includes(date.isoDate()) && date.getDay()) {
                    dates.push(date.copy())
                }
                date.change({days: 1})
            }

            GET('main/users/filter', {is_active: 1, groups__name: 'техники'}).then(async data => {
                const stages = await GET(
                    'main/stages/filter', {
                        lab_date__range: [dates[0].isoDate(), dates[dates.length - 1].isoDate()],

                    })
                let techs = data.slice()
                techs.sort((a, b) => {
                    if (a.tech_type > b.tech_type) return 1
                    if (a.tech_type < b.tech_type) return -1
                    if (a.code > b.code) return 1
                    return -1
                })
                let body = []
                let totalStages = 0
                for (let tech of techs) {
                    if (!tech.code) continue
                    let techArray = getTechArray(
                        techID => navigation.navigate('Tech', {techID}),
                        tech,
                        dates,
                        stages
                    )
                    totalStages += techArray[techArray.length - 1]
                    body.push(techArray)
                }
                let head = ['']
                for (let date of dates) head.push(String(date.getDate()))
                head.push(String(totalStages))
                updateState({head, body, ready: true})
            })
        } else {
            const response = await GET('main/stages/unallocated')
            updateState({unallocated: response, ready: true})
        }
    }, [currentDate, state.mode])

    if (!state.ready) return <Loading/>
    if (state.mode === 'unallocated') {
        return <ScrollView>
            <BtnSecondary
                style={[fixWidth(200), mv1]}
                title={'нагрузка техников'}
                onPress={() => updateState({mode: 'total'})}
            />
            {!state.unallocated.length ?
                <H3>всё расписано</H3>
                :
                <View style={fixWidth(320)}>
                    <Row style={[borderTable, bgPrimaryLight, p1]}>
                        <Text style={flex}>клиника</Text>
                        <Text style={flex}>доктор</Text>
                        <Text style={flex}>пациент</Text>
                    </Row>
                    {
                        state.unallocated.map((obj, key) => {
                            return (
                                <PressRow style={[borderTable, p1]} key={key} onPress={() => goToPatient(obj.patient_id)}>
                                    <Text style={flex}>{obj.clinic_name}</Text>
                                    <Text style={flex}>{obj.doctor_last_name}</Text>
                                    <Text style={flex}>{obj.patient_last_name}</Text>
                                </PressRow>
                            )
                        })
                    }
                </View>
            }
        </ScrollView>
    }
    return <ScrollView>
        <BtnSecondary
            style={[fixWidth(200), mv1]}
            title={'нераспределенное'}
            onPress={() => updateState({mode: 'unallocated'})}
        />
        <Table
            maxRows={100}
            widths={[60]}
            head={state.head}
            body={state.body}
        />
    </ScrollView>
}

function getTechArray(onPress, tech, dates, stages) {
    let array = new Array(dates.length + 2)
    let totalStages = 0
    array[0] =
        <Pressable style={[flex, techTypeColor(tech.tech_type), jCenter, center]} onPress={() => onPress(tech.id)}>
            <Text>{tech.code}</Text>
        </Pressable>
    for (let n = 0; n < dates.length; n++) {
        let stagesCount = 0
        const date = dates[n].isoDate()
        for (let stage of stages) {
            for (let status of stage.statuses) {
                if (status.tech === tech.id && date === stage.lab_date) {
                    stagesCount += 1
                    break
                }
            }
        }
        totalStages += stagesCount
        array[n + 1] = stagesCount ? stagesCount : ''
    }
    array[array.length - 1] = totalStages
    return array
}

function techTypeColor(type) {
    switch (type) {
        case 'waxup':
            return {backgroundColor: '#fff'};
        case 'syomshik':
            return {backgroundColor: '#ffa5a5'};
        case 'ceramist':
            return {backgroundColor: '#f8e299'};
        case 'carcasist':
            return {backgroundColor: '#9f9f9f'};
        default:
            return {backgroundColor: '#ababfd'}
    }
}
