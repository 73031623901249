import {ScrollView} from "react-native";
import PriceSection from "./PriceSection";
import {AppContext, SectionsContext} from "../../contexts";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import BtnToggle from "../../../lib/buttons/BtnToggle";
import {useContext, useEffect, useState} from "react";
import {mb1, ml1, p1} from "../../../const";
import {Flex, Row} from "../../../lib/markup/markup";
import {isAdministrations} from "../../../objects/user_methods";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Creator from "../../../lib/fields/Creator";

export default function (props) {
    const [sections, setSections] = useState([])
    const [editable, setEditable] = useState(false)
    const {GET, user, POST} = useContext(AppContext)
    const [openedIndex, setOpenedIndex] = useState(-1)

    useEffect(async () => {
        let cleanupFunction = false
        if (!cleanupFunction) {
            const response = await GET('main/PriceSections/filter', {price_list: props.id})
            setSections(response)
        }
        return () => cleanupFunction = true
    }, [props.id])

    return <SectionsContext.Provider
        value={{sections, setSections, editable}}>
        <Flex style={p1}>
            <Row style={mb1}>
                <BtnToggle
                    isActive={editable}
                    onPress={() => setEditable(!editable)}
                    visible={Boolean(user.can_price_edit)}
                    title={['редактировать', faEdit]}
                />
                <Flex style={ml1}>
                    <Creator
                        visible={editable}
                        placeholder={'новая категория'}
                        onPress={async name => {
                            const data = await POST('main/PriceSections', {name, price_list: props.id})
                            setSections(sections.concat(data))
                        }}
                    />
                </Flex>
            </Row>
            <ScrollView>
                {sections.map((section, key) =>
                    <PriceSection
                        key={key}
                        {...section}
                        open={key === openedIndex}
                        setOpen={value => {
                            if (value) setOpenedIndex(key)
                            else setOpenedIndex(-1)
                        }}
                    />)}
            </ScrollView>
        </Flex>
    </SectionsContext.Provider>
}
