import {isDesktop, screenSize} from "../sugar";
import {useRef, useState} from "react";
import {View} from "react-native";
import {absolute, border, center, field, fRow, jBetween, ml1, pv0} from "../../const";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import BtnSecondary from "./BtnSecondary";
import {ModalWindow} from "../markup/ModalWindow";

export default function ({
                           items=[],
                           visible = true,
                           style={},
                           compact = screenSize() === 'desktop' ? false : items.length !== 1
                       }) {
    if (!visible) return null
    const [positionStyle, setPositionStyle] = useState({height: 0, width: 0, top: 0, left: 0})
    const componentRef = useRef()
    const [open, setOpen] = useState(false)
    function measure() {
        componentRef.current.measure((width, height, px, py, fx, fy) => {
            // console.log(px, py, fx, fy)
            const top = py-5
            let _height = field.height * items.length + 20
            setPositionStyle({height: _height, top, left: px+10, width: 40})
        })
    }
    if (!items.length) return null
    if (compact) {
        return <View style={style} ref={componentRef} onLayout={measure}>
            <BtnSecondary
                style={style}
                title={open ? faChevronUp: faChevronDown}
                onPress={() => setOpen(true)}
            />
            <ModalWindow
                style={[border, center, pv0, jBetween, absolute, positionStyle]}
                visible={open} onClose={() => setOpen(false)}
            >
                {items.map(({textStyle, onPress, title}, key) =>
                    <BtnSecondary
                        key={key} title={title}
                        onPress={onPress}
                        textStyle={textStyle}
                    />
                )}
            </ModalWindow>
        </View>
    }
    return <View style={fRow}>
        {items.map(({textStyle, onPress, title, onLongPress}, key) => {
            return <BtnSecondary
                style={isDesktop() ? ml1: null}
                key={key}
                title={title}
                onPress={onPress}
                textStyle={textStyle}
                onLongPress={onLongPress}
            />
        })}
    </View>
}
