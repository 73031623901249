import {useContext, useEffect, useState} from "react";
import {Dimensions, Linking} from "react-native";
import {AppContext} from "../contexts";
import {Row} from "../../lib/markup/markup";
import {borderTable} from "../../const";
import {View} from "react-native";
import {defaultUrl} from "../../objects/user_methods";



export default function (props) {
    const {GET, user, navigation} = useContext(AppContext)
    if (user) {
            if (user.has_cameras) Linking.openURL(`http://85.235.164.102:58888/overview/0/${user.video_key}/`)
        else navigation.navigate(defaultUrl(user))
        }
    else navigation.navigate('Login')
    const [state, setState] = useState({ready: false})

    if (!user.has_cameras) return null

    useEffect(async () => {
        const monitors = await GET('lab/monitors/filter')
        setState({monitors, ready: true, index: 0})
    }, [])

    if (!state.ready) return false
    let {width, height} = Dimensions.get('window')
    width /= 2
    height /=2
    let scale = 20
    let src = 'http://85.235.164.102:50106'
    let fps = 10

    let url = 'http://85.235.164.102:50106/zm/cgi-bin/nph-zms?width=2716px&height=1528px&scale=100&mode=jpeg&maxfps=10&monitor=53&'
    return <View style={{width, height}}>
        <VlCPlayerView
            autoplay={true}
            url={url}
            //Orientation={Orientation}
            //BackHandle={BackHandle}
            //ggUrl=""
            showGG={true}
            showTitle={true}
            //title=""
            showBack={true}
            // onLeftPress={()=>{}}
            // startFullScreen={() => {
            //     this.setState({
            //         isFull: true,
            //     });
            // }}
            // closeFullScreen={() => {
            //     this.setState({
            //         isFull: false,
            //     });
            // }}
        />
    </View>
    return <Row style={borderTable}>
        {state.monitors.map((monitor, key) => {
            // console.log(`${src}/zm/cgi-bin/nph-zms?width=${width}px&amp;height=${height}px&amp;scale=${scale}&amp;
            //     mode=jpeg&amp;maxfps=${fps}&amp;monitor=${monitor.video_server_id}&amp;`)
            console.log(url)
            return <Video
                    source={{
                        uri: url,
                    }}
                    useNativeControls
                    resizeMode="contain"
                    isLooping
                    // onPlaybackStatusUpdate={status => setStatus(() => status)}
                />
        })}
    </Row>
}
