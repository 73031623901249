import {Flex, Flex3, FlexRow, Row} from "../../../lib/markup/markup";
import {
    border, mb1,
    ml1, mt1,
    p1
} from "../../../const";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {EDate, isDesktop} from "../../../lib/sugar";
import Transactions from "./Transactions";
import Boxes from "./Boxes";
import {Tab} from "../../../lib/markup/TabBar";
import {ScrollView} from "react-native";

export default function () {
    const [state, setState] = useState({
        ready: false,
        boxesURL: 'lab/boxes/',
        refresh: false,
        active: 0,
        transactions: [],
        mode: 'warehouse'
    })
    const {GET, POST, navigation, isFocused} = useContext(AppContext)

    function refresh() {
        updateState({refresh: !state.refresh})
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let isMounted = true
        let boxes = await GET(state.boxesURL)
        boxes.sort((a, b) => {
            if (a.number > b.number) return 1
            return -1
        })
        let date = new EDate()
        date.change({days: -7})
        let transactions = await GET('lab/Transactions/filter', {datetime__gt: date.isoDate()})
        transactions.sort((a, b) => {
            if (a.datetime > b.datetime) return -1
            return 1
        })
        if (isMounted) updateState({ready: true, boxes, transactions})
        return () => isMounted = false
    }, [state.refresh, isFocused])

    async function createBox() {
        const box = await POST(state.boxesURL, {name: 'новый раздел'})
        navigation.navigate('BoxEdit', {box, url: state.boxesURL})
    }

    if (!state.ready) return <Loading/>

    if (isDesktop()) return <FlexRow style={p1}>
        <Flex3>
            <Boxes updateState={updateState} {...state} createBox={createBox}/>
        </Flex3>
        <Flex style={[border, ml1]}>
            <Transactions {...state}/>
        </Flex>
    </FlexRow>
    return <Flex>
        <Row style={mb1}>
            <Tab
                title={'склад'}
                active={state.mode === 'warehouse'}
                onPress={() => updateState({mode: 'warehouse'})}
            />
            <Tab
                title={'транзакции'}
                active={state.mode === 'transactions'}
                onPress={() => updateState({mode: 'transactions'})}
            />
        </Row>
        {state.mode === 'warehouse' ? <Boxes updateState={updateState} {...state} createBox={createBox}/> : null}
        {state.mode === 'transactions' ? <Transactions {...state}/> : null}
    </Flex>

}
