import {Text} from "react-native";
import React from "react";

export default function Number({style = {}, children, end='', visible=true}) {
    if (!visible) return null
    let text = String(children)
    if (text.length === 4) text = text.slice(0, 1) + ' ' + text.slice(1)
    else if (text.length === 5) text = text.slice(0, 2) + ' ' + text.slice(2)
    else if (text.length === 6) text = text.slice(0, 3) + ' ' + text.slice(3)
    return <Text style={style}>{text + end}</Text>
}

export function Ruble({style = {}, children, visible=true}) {
    return <Number style={style} end={'р.'} visible={visible}>{children}</Number>
}