import React, {useContext} from "react";
import {AppContext, OrderContext} from "../../contexts";
import {center, ml1, ml3, ph1, primary} from "../../../const";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Btn from "../../../lib/buttons/Btn";
import Toggle from "../../../lib/fields/Toggle";
import {H3} from "../../../lib/text/H";
import {Flex, Row} from "../../../lib/markup/markup";
import TextField from "../../../lib/fields/TextField";
import OrderStatus from "../../../components/OrderStatus";
import {isAdministrations} from "../../../objects/user_methods";


export default function ({remove}) {
    const {state, saveOrder, setAlert} = useContext(OrderContext)
    const {user} = useContext(AppContext)
    return <Row>
        <TextField
            visible={isAdministrations(user)}
            style={[ml1, {width: 40}]}
            placeholder={'номер наряда'}
            value={state.order.number}
            onChange={number => saveOrder('number', number)}
        />
        <Row style={center}>
            <H3 style={primary}>статус:</H3>
            <OrderStatus
                status={state.order.status}
                orderID={state.order.id}
                patientID={state.order.patient}
            />
        </Row>
        <Toggle
            visible={isAdministrations(user)}
            style={ml3}
            text={'личная'}
            selected={state.order.personal}
            onPress={value => saveOrder('personal', value)}
        />
        <TextField
            visible={isAdministrations(user) && state.order.personal}
            placeholder={'адрес доставки'}
            value={state.order.personal_comment}
            style={ml1}
            onChange={personal_comment => saveOrder('personal_comment', personal_comment)}
        />
        <Flex/>
        <Btn
            visible={isAdministrations(user)}
            title={faTrash}
            onPress={() => {
                setAlert({
                    isVisible: true,
                    title: 'удалить наряд?',
                    buttons: [
                        {
                            title: 'удалить',
                            style: 'danger',
                            onPress: async () => {
                                await saveOrder('is_removed', 1)
                                remove()
                            }
                        },
                        {
                            title: 'отмена'
                        }
                    ]
                })
            }}
        />
    </Row>
}
