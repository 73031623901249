import {Text, TouchableOpacity, ScrollView} from "react-native";
import {Flex, PressRow} from "../../../lib/markup/markup";
import {
    bgPrimaryLight,
    flex,
    fRow,
    jCenter,
    p1
} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {EDate, fixWidth} from "../../../lib/sugar";
import {View} from "react-native";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import FA from "../../../lib/static/FA";


export default function () {
    const {GET, navigation, currentDate, isFocused, PATCH} = useContext(AppContext)
    const [state, setState] = useState({ready: false, orderBy: 'id', orders: []})

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        updateState({ready: false})
        let date = currentDate.isoDate()
        let orders = await GET('main/CadCams/in_work', {date})
        updateState({ready: true, orders})
    }, [currentDate, isFocused])

    if (!state.ready) return <Loading/>
    const colStyle = {...p1, ...fRow, ...flex}
    return <Flex>
        <ScrollView>
            <View style={[bgPrimaryLight, fRow]}>
                <Text numberOfLines={1} style={colStyle}>клиника</Text>
                <Text numberOfLines={1} style={colStyle}>доктор</Text>
                <Text numberOfLines={1} style={colStyle}>пациент</Text>
                <Text numberOfLines={1} style={colStyle}>исполнитель</Text>
                <TouchableOpacity style={fixWidth(50)}/>
            </View>
            {state.orders.map(({id, patient_id, patient, clinic, doctor, executor, was_finished}, key) => {
                return <PressRow
                    visible={!was_finished}
                    key={key}
                    style={[fRow, was_finished ? {backgroundColor: '#85dbff'} : null]}
                    onPress={() => navigation.navigate('Patient', {patient_id})}

                >
                    <Text numberOfLines={1} style={colStyle}>{clinic}</Text>
                    <Text numberOfLines={1} style={colStyle}>{doctor}</Text>
                    <Text numberOfLines={1} style={colStyle}>{patient}</Text>
                    <Text numberOfLines={1} style={colStyle}>{executor}</Text>
                    <TouchableOpacity
                        style={[fixWidth(50), flex, jCenter]}
                        onPress={() => {

                                let time = was_finished ? null: new EDate().isoDate()
                                let orders = state.orders.slice()
                                orders[key].was_finished = time
                                PATCH('main/cadcams/' + id, {was_finished: time})
                                updateState({orders})

                        }}
                    >
                        {was_finished ?
                            <Text>{new EDate(was_finished).ruDate(false)}</Text>
                            :
                            <FA icon={faCheck}/>}
                    </TouchableOpacity>
                </PressRow>
            })}
        </ScrollView>
    </Flex>
}



