import {View, Text} from "react-native";
import {Flex, Row} from "../../../lib/markup/markup";
import {borderTable, center, flex, mt2, p1} from "../../../const";
import {useContext,} from "react";
import {AppContext} from "../../contexts";
import {Tab} from "../../../lib/markup/TabBar";
import Btn from "../../../lib/buttons/Btn";
import {faEdit, faPlus} from "@fortawesome/free-solid-svg-icons";
import Box from "./Box";

export default function (props) {
    const {navigation} = useContext(AppContext)

    return <Flex>
        <Row>
            {props.boxes.map((item, key) =>
                <Tab
                    key={key}
                    title={<Row style={center}>
                        <Text>{item.name}</Text>
                        <Btn
                            title={faEdit}
                            onPress={() => navigation.navigate('BoxEdit', {box: item, url: props.boxesURL})}
                        />
                    </Row>}
                    active={key === props.active}
                    onPress={() => {
                        props.updateState({active: key})
                    }}
                />
            )}

            <View style={{
                borderBottomColor: borderTable.borderColor,
                borderBottomStyle: borderTable.borderStyle,
                borderBottomWidth: borderTable.borderWidth,
                marginTop: -1,
            }}>
                <Btn style={mt2} title={faPlus} onPress={props.createBox}/>
            </View>
        </Row>
        <Box
            boxID={props.boxes[props.active]?.id}
            addTransaction={transaction => {
                let transactions = props.transactions.slice()
                transactions.unshift(transaction)
                props.updateState({transactions})
            }}
        />
    </Flex>
}
