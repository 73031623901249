import {Flex, Flex3, Flex4, Row} from "../../../lib/markup/markup";
import {isDesktop} from "../../../lib/sugar";
import PercentOfWork from "./PercentOfWork";
import CadcamInWork from "./CadcamInWork";
import CadcamToday from "./CadcamToday";
import TabBar from "../../../lib/markup/TabBar";
import {bgSecondary, flex} from "../../../const";
import {View} from "react-native";

// ненужный файл?
export default function () {
    if (isDesktop()) return <View style={[flex, bgSecondary]}>
        <Row>
            <PercentOfWork/>
        </Row>
        <Flex>
            <CadcamInWork/>
            <CadcamToday/>
        </Flex>
    </View>
    else return <Flex>
        <PercentOfWork/>
        <Row style={{width: 10}}/>
        <TabBar
            items={[
                {
                    'title': 'в работе',
                    'body': <CadcamInWork/>
                },
                {
                    'title': 'сегодня',
                    'body': <CadcamToday/>
                }
            ]}
        />
    </Flex>
}



