import {View, Text, TouchableOpacity} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../contexts";
import Loading from "../../lib/static/Loading";
import {Flex, Row} from "../../lib/markup/markup";
import {Tab} from "../../lib/markup/TabBar";
import WhatsApp from "./WhatsApp";
import Telegram from "./Telegram";

export default function (props) {
    const [state, setState] = useState({ready: false, mode: 'whatsapp'})
    const {GET} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        updateState({ready: true})
    }, [])

    if (!state.ready) return <Loading/>
    return <Flex>
        <Row>
            <Tab
                title={'WhatsApp'}
                active={state.mode === 'whatsapp'}
                onPress={() => updateState({mode: 'whatsapp'})}
            />
            <Tab
                title={'Telegram'}
                active={state.mode === 'telegram'}
                onPress={() => updateState({mode: 'telegram'})}
            />
        </Row>
        <Flex>
            {state.mode === 'whatsapp' ?
                <WhatsApp/>
                :
                <Telegram/>
            }
        </Flex>
    </Flex>
}
