import React from "react";
import * as Device from "expo-device";
import {Dimensions, Linking, Platform, StyleSheet} from "react-native";
import {field} from "../const";
import * as FileSystem from 'expo-file-system';
import {manipulateAsync, SaveFormat} from "expo-image-manipulator";

export const randInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min


export function zfill(string, length=2) {
        let new_str = String(string)
        while (new_str.length < length) new_str = '0' + new_str
        return new_str
    }


export class EDate extends Date {
    constructor(...options) {
        if (typeof options[0] === 'string' && options[0].length < 9) super('2000-01-01T' + options[0])
        else super(...options);
    }

    copy() {
        return new EDate(
            this.getFullYear(),
            this.getMonth(),
            this.getDate(),
            this.getHours(),
            this.getMinutes(),
            this.getSeconds(),
            this.getMilliseconds()
        )
    }

    isoDate() {
        let month = String(this.getMonth() + 1)
        let day = String(this.getDate())
        return this.getFullYear() + '-' + zfill(month) + '-' + zfill(day)
    }

    isoTime(seconds=true) {
        if (seconds) return zfill(this.getHours()) + ':' + zfill(this.getMinutes()) + ':' + zfill(this.getSeconds())
        return zfill(this.getHours()) + ':' + zfill(this.getMinutes())
    }

    isoDatetime() {
        return this.isoDate() + ' ' + this.isoTime()
    }

    ruDate(full=true) {
        let month = String(this.getMonth() + 1)
        let day = String(this.getDate())
        if (full) return zfill(day) + '.' + zfill(month) + '.' + this.getFullYear()
        return zfill(day) + '.' + zfill(month)
    }

    ruDateTime(full=true) {
        return this.ruDate(full) + ' ' + this.isoTime(full)
    }

    weekDay(short = false) {
        if (short) {
            switch (this.getDay()) {
                case 1:
                    return 'ПН'
                case 2:
                    return 'ВТ'
                case 3:
                    return 'СР'
                case 4:
                    return 'ЧТ'
                case 5:
                    return 'ПТ'
                case 6:
                    return 'СБ'
                case 0:
                    return 'ВС'
            }
        }
        switch (this.getDay()) {
            case 1:
                return 'понедельник'
            case 2:
                return 'вторник'
            case 3:
                return 'среда'
            case 4:
                return 'четверг'
            case 5:
                return 'пятница'
            case 6:
                return 'суббота'
            case 0:
                return 'воскресенье'
        }
    }

    change({days, hours, minutes, seconds}) {
        let delta = days * 86400000 || 0
        delta += hours * 3600000 || 0
        delta += minutes * 60000 || 0
        delta += seconds * 1000 || 0
        this.setTime(this.getTime() + delta)
        return this
    }

    monthName() {
        switch (this.getMonth()) {
            case 0:
                return 'январь'
            case 1:
                return 'февраль'
            case 2:
                return 'март'
            case 3:
                return 'апрель'
            case 4:
                return 'май'
            case 5:
                return 'июнь'
            case 6:
                return 'июль'
            case 7:
                return 'август'
            case 8:
                return 'сентябрь'
            case 9:
                return 'октябрь'
            case 10:
                return 'ноябрь'
            case 11:
                return 'декабрь'
        }
    }
}

export function objectLength(obj) {
    return Object.keys(obj).length;
}

export function replaceAll(string, what, to) {
    while (string.includes(what)) string = string.replace(what, to)
    return string
}

export function getDeviceName() {
    return Device.modelId || Device.modelName
}
export function ru(key) {
    const known = {
        last_name: 'фамилия',
        first_name: 'имя',
        middle_name: 'отчество',
        phone: 'телефон',
        address: 'адрес',
        comment: 'комментарий',
        comment2: 'отправка',
        patient: 'пациент',
        doctor: 'доктор',
        clinic: 'клиника',
        time: 'время',
        date: 'дата',
        tech: 'техник',
        status: 'статус',
    }
    if (key in known) return known[key]
    return "can't translate"
}

export const toObject = (field, value) => {
    let attr = {}
    attr[field] = value
    return attr
}

export function formatPhone(string) {
    let phone = string
    if (phone[0] === '+') phone = phone.slice(1, phone.length)
    phone = replaceAll(phone, ' ', '')
    phone = replaceAll(phone, '(', '')
    phone = replaceAll(phone, ')', '')
    if (phone.length === 11 && +phone) {
        if (phone[0] === '8') phone = '7' + phone.slice(1, phone.length)
        return phone
    }
}

export function copyObject(obj) {
    let newObj = {}
    return Object.assign(newObj, obj)
}


export function asObject(field, value) {
    let obj = {}
    obj[field] = value
    return obj
}

let __screenSize = null
export function screenSize () {
    function define() {
        const width = Dimensions.get('window').width
        if (width < 360) return 'small'
        if (width < 576) return 'phone'
        if (width < 992) return 'tablet'
        return 'desktop'
    }
    if (!__screenSize) __screenSize = define()
    return __screenSize
}

export function hasGroup(currentUserGroups, targetGroupName) {
    for (let g of currentUserGroups) {
        if (g.name === targetGroupName) return true
    }
    return false
}


export function isPhoneCorrect(phone) {
    try {
        let value = String(+phone.replace(/[^0-9]/g, ''))
        if (!Boolean(value)) return
        if (value.length === 11) value = '7' + value.slice(1)
        if (value.length === 10) value = '7' + value
        if (value.length !== 11) return
        return value
    } catch (e) {return null}
}

export function arrayToDict(array, key='id') {
    let dict = {}
    for (let item of array) dict[item[key]] = item
    return dict
}

export function getWidth(style, offset=0) {
    return style && style.width ?
        {width: style.width-offset, maxWidth: style.width-offset, minWidth: style.width-offset}
        :
        {width: field.width-offset, maxWidth: field.maxWidth-offset, minWidth: field.minWidth-offset}
}

export function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export function isDesktop() {
    return screenSize() === 'desktop'
}

export function isTablet() {
    return screenSize() === 'tablet'
}

export function isPhone() {
    return screenSize() === 'phone'
}

export function isSmall() {
    return screenSize() === 'small'
}

export function lessDesktop() {
    return screenSize() !== 'desktop'
}

export function lessTablet() {
    return ['phone', 'small'].includes(screenSize())
}

export function fixHeight(height) {
    return {minHeight: height, height, maxHeight: height}
}

export function fixWidth(width) {
    return {minWidth: width, width, maxWidth: width}
}

export function getWrpSize(style) {
    if (!style) return null
    style = StyleSheet.flatten(style)
    if (Object.keys(style).includes('flex')) {
        const {flex} = style
        return {flex}
    }
    style = [
        fixWidth(style?.width ? style.width: field.width),
        fixHeight(style?.height ? style.height: field.height),
    ]
    return StyleSheet.flatten(style)
}

export function getBorderStyle(style) {
    if (!style) return null
    const {
        borderTopRightRadius,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderRadius,
        borderColor,
        borderWidth,
        borderStyle
    } = StyleSheet.flatten([field, style])
    return {
        borderTopRightRadius,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderRadius,
        borderColor,
        borderWidth,
        borderStyle
    }
}

export function getMargin(style) {
    if (!style) return null
    style = StyleSheet.flatten(style)
    const {marginLeft, marginTop, marginBottom, marginRight, marginVertical, marginHorizontal, margin} = style
    return {marginLeft, marginTop, marginBottom, marginRight, marginVertical, marginHorizontal, margin}
}

export function getTextStyle(style) {
    if (!style) return null
    const {fontWeight, fontStyle, fontSize, color} = StyleSheet.flatten([field, style])
    return {fontWeight, fontStyle, fontSize, color}
}

async function ensureDirExists(path) {
    const dirInfo = await FileSystem.getInfoAsync(path);
    if (!dirInfo.exists) {
        console.log("CreativeLab directory doesn't exist, creating...");
        await FileSystem.makeDirectoryAsync(path, { intermediates: true });
    }
}

export async function downloadFileAsync(url, ext) {
    // const callback = downloadProgress => {
    //     const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    //     this.setState({
    //         downloadProgress: progress,
    //     });
    // };
    if (Platform.OS === 'web') {
        await Linking.openURL(url)
    } else {
        const path = FileSystem.documentDirectory + 'CreativeLab/'
        await ensureDirExists(path)
        const filename = `${(new EDate()).ruDateTime().replace(' ', '_')}.${ext}`
        const downloadResumable = FileSystem.createDownloadResumable(
            url,
            FileSystem.documentDirectory + filename,
            {},
        );

        try {
            const {uri} = await downloadResumable.downloadAsync();
            console.log('Finished downloading to ', uri);
        } catch (e) {
            console.error(e);
        }
    }
}

export async function compressImageAsync(uri) {
    const manipResult = await manipulateAsync(
        uri,
        [{resize: {height: 1920, width: 1080}},],
        {compress: 1, format: SaveFormat.JPEG, base64: true}
    )
    return uri
}
