import {TouchableOpacity, ScrollView, Text} from "react-native";
import {bgSecondary, border, center, mh1, mt1, p1, primary} from "../../../const";
import {AppContext} from "../../contexts";
import {useContext, useEffect, useState} from "react";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import TextField from "../../../lib/fields/TextField";
import Btn from "../../../lib/buttons/Btn";
import {Col, Flex} from "../../../lib/markup/markup";
import {asObject} from "../../../lib/sugar";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import Loading from "../../../lib/static/Loading";


export default function (props) {
    const {navigation, PATCH, GET} = useContext(AppContext)
    const [state, setState] = useState({ready: false})

    useEffect(async () => {
        let doctors = await GET('main/users/filter', {relations__clinic: props.id})
        doctors.sort((a, b) => {
            if (a.last_name > b.last_name) return 1
            return -1
        })

        const patients = await GET('main/patients/filter', {clinic: props.id})
        setState({ready: true, doctors, patients})
    }, [])

    const fields = [
        ['name', 'наименование'],
        ['phone', 'телефон'],
        ['address', 'адрес']
    ]

    function save(field, value) {
        PATCH('main/clinics/' + props.id, asObject(field, value))
    }

    if (!state.ready) return <Loading/>

    return <Flex style={[p1, border, bgSecondary, mh1]}>
        <BtnPrimary
            visible={props.isPrimary}
            title={'основная'}
        />
        <BtnSecondary
            visible={!props.isPrimary && props.selected.length > 1}
            title={'сделать основной'}
            onPress={props.setPrimary}
        />
        <Btn
            title={'перейти к клинике'}
            style={mt1}
            onPress={() => {
                navigation.navigate('ClinicProfile', {clinic_id: props.id})
            }}
        />
        <Col>
            {fields.map(([field, placeholder], key) => {
                return <TextField
                    key={key}
                    value={props[field]}
                    placeholder={placeholder}
                    onChange={value => save(field, value)}
                />
            })}
        </Col>
        <Text style={[primary, center, mh1]}>
            {`${state.doctors.length} врачей`}
        </Text>
        <Flex>
            <ScrollView>
                {state.doctors.map((doc, key) => {
                    return <TouchableOpacity
                        key={key}
                        onPress={() => navigation.navigate('UserProfile', {user_id: doc.id})}
                    >
                        <Text style={primary}>{doc.last_name}</Text>
                    </TouchableOpacity>
                })}
            </ScrollView>
        </Flex>
        <Text style={[center, mh1]}>
            {`${state.patients.length} пациентов`}
        </Text>
        <Flex>
            <ScrollView>
                {state.patients.map((patient, key) => <TouchableOpacity
                    key={key}
                    onPress={() => navigation.navigate('Patient', {patient_id: patient.id})}
                >
                    <Text>{patient.last_name}</Text>
                </TouchableOpacity>)}
            </ScrollView>
        </Flex>
    </Flex>
}
