import {Text, View} from "react-native";
import React from "react";
import {flex, jCenter, mh1} from "../../const";

export default function ({text}) {
    return <View style={[mh1, jCenter]}>
        <Text numberOfLines={1}>
            {text}
        </Text>
    </View>
}
