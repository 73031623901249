import React, {useEffect} from "react";
import {useState} from "react";
import {NavigationContainer} from "@react-navigation/native";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import Login from "./screens/main/Login";
import {api} from "./lib/api";

const Stack = createNativeStackNavigator();
import {
    faBuilding, faCalendar,
    faFan, faFileSignature, faMoneyBill,
    faSitemap,
    faTeeth,
    faThermometer, faTools,
    faTooth,
    faUser,
    faUsers, faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import Wrapper from "./screens/Wrapper";
import {ScreenContext} from "./screens/contexts";
import Users from "./screens/main/Users";
import Manager from "./screens/lab/manager/Manager";
import Patient from "./screens/main/patient/Patient";
import Tech from "./screens/lab/tech/Tech"
import Repair from "./screens/lab/Repair";
import Monitors from "./screens/lab/Monitors";
import Clinics from "./screens/lab/clients/Clinics";
import ServerUnavailable from "./components/ServerUnavailable";
import ClinicAdd from "./screens/clinic/ClinicAdd";
import ClinicProfile from "./screens/clinic/clinic_profile/ClinicProfile";
import {
    defaultUrl,
    isAdministrations,
    isCadCam,
    isClinic, isCourier,
    isDirector,
    isLab,
    isManager,
    isTech, isWarehouse, loadDataAsync
} from "./objects/user_methods";
import UserProfile from "./screens/main/user_profile/UserProfile";
import Prices from "./screens/main/prices/Prices";
import Archive from "./screens/lab/archive/Archive";
import Loading from "./lib/static/Loading";
import {MenuProvider} from "react-native-popup-menu";
import Total from "./screens/lab/Total";
import Find from "./screens/main/Find";
import Camera from "./screens/main/Camera";
import Couriers from "./screens/lab/couriers/Couriers";
import CourierTasks from "./screens/lab/courier_tasks/CourierTasks";
import Attorneys from "./screens/lab/attorneys/Attorneys";
import {isDesktop} from "./lib/sugar";
import Warehouse from "./screens/lab/warehouse/Warehouse";
import BoxEdit from "./screens/lab/warehouse/BoxEdit";
import TableEdit from "./screens/lab/warehouse/TableEdit";
import Calendar from "./screens/main/Calendar";
import Notifications from "./screens/notifications/Notifications";
import ServerOff from "./screens/main/ServerOff";
import Chats from "./screens/lab/Chats";


export default function App() {
    const [state, setState] = useState({user: null, serverStatus: null, attemptCount: 0})

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let response = await api.get('main/users/info')

        if (response.status === 503) {
            updateState({serverStatus: 'offline'})
            setTimeout(() => updateState({attemptCount: state.attemptCount + 1}), 5000)
        } else {
            let args = {serverStatus: 'online', ready: true}
            if (response.status === 200)  {
                args = {...args, ...await loadDataAsync(response.data), user: response.data}
            }
            updateState(args)
        }
    }, [state.attemptCount])

    if (state.serverStatus === 'offline') return <ServerUnavailable/>
    if (state.serverStatus === 'online') {
        const screens = [
            {
                title: 'вход',
                name: "Login",
                component: Login,
                burger: false,
            },
            {
                title: 'профиль пользователя',
                name: "UserProfile",
                component: UserProfile,
                burger: state.user,
                icon: faUser
            },
            {
                title: 'пользователи',
                name: "Users",
                component: Users,
                burger: isAdministrations(state.user),
                icon: faUsers
            },
            {
                title: 'клиники',
                name: "Clinics",
                component: Clinics,
                burger: isAdministrations(state.user),
                icon: faBuilding
            },
            {
                title: 'лаборатория',
                name: "Laboratory",
                component: Manager,
                burger: isAdministrations(state.user),
                icon: faTooth
            },
            {
                title: 'CAD/CAM',
                name: "CadCam",
                component: Manager,
                burger: isAdministrations(state.user),
                icon: faFan
            },
            {
                title: 'литье',
                name: "Foundry",
                component: Manager,
                burger: isAdministrations(state.user),
                icon: faThermometer
            },
            {
                title: 'пациент',
                name: "Patient",
                component: Patient,
                burger: false
            },
            {
                title: 'чаты',
                name: "Chats",
                component: Chats,
                burger: false
            },
            {
                title: 'мои работы',
                name: "Tech",
                component: Tech,
                burger: isTech(state.user),
                icon: faTooth
            },
            {
                title: 'все работы',
                name: "Total",
                component: Total,
                burger: isDirector(state.user) || isManager(state.user),
                icon: faSitemap
            },
            {
                title: 'работы CAD/CAM',
                name: "Cadcamist",
                component: Tech,
                burger: isCadCam(state.user),
                icon: faTeeth
            },
            {
                title: 'заявки на ремонт',
                name: "Repair",
                component: Repair,
                burger: isLab(state.user) && !isCourier(state.user),
                icon: faTools
            },
            {
                title: 'камеры',
                name: "Monitors",
                component: Monitors,
                burger: false,
            },
            {
                title: 'прайс-листы',
                name: "Prices",
                component: Prices,
                burger: isAdministrations(state.user) || isClinic(state.user),
                icon: faMoneyBill
            },
            {
                title: 'поиск',
                name: "Find",
                component: Find,
                burger: false,
            },
            {
                title: 'архив',
                name: "Archive",
                component: Archive,
                burger: false,
            },
            {
                title: 'регистрация клиники',
                name: "ClinicAdd",
                component: ClinicAdd,
                burger: false,
            },
            {
                title: 'профиль клиники',
                name: "ClinicProfile",
                component: ClinicProfile,
                burger: false,
            },
            {
                title: 'отправка курьеров',
                name: "Couriers",
                component: Couriers,
                burger: false,
            },
            {
                title: 'мои поездки',
                name: "CourierTasks",
                component: CourierTasks,
                burger: false,
            },
            {
                title: 'доверенности',
                name: 'Attorneys',
                component: Attorneys,
                icon: faFileSignature,
                burger: (isManager(state.user) || isWarehouse(state.user)) && isDesktop()
            },
            {
                title: 'склад',
                name: 'Warehouse',
                component: Warehouse,
                icon: faWarehouse,
                burger: isCadCam(state.user) || isWarehouse(state.user) || isDirector(state.user)
            },
            {
                title: 'редактировать раздел',
                name: 'BoxEdit',
                component: BoxEdit,
                burger: false
            },
            {
                title: 'редактировать таблицу',
                name: 'TableEdit',
                component: TableEdit,
                burger: false
            },
            {
                title: 'календарь',
                name: 'Calendar',
                component: Calendar,
                icon: faCalendar,
                burger: true
            },
            {
                title: 'события',
                name: 'Notifications',
                component: Notifications,
                burger: false
            },
            {
                title: 'сервер отключен',
                name: 'ServerOff',
                component: ServerOff,
                burger: false
            },
        ]
        screens.sort((a, b) => {
            if (a.title > b.title) return 1
            if (a.title === b.title) return 0
            return -1
        })

        return <ScreenContext.Provider value={[
            state,
            updateState,
            screens
        ]}>
            <MenuProvider>
                <NavigationContainer>
                    <Stack.Navigator
                        screenOptions={{headerShown: false}}
                        initialRouteName={defaultUrl(state.user)}
                    >
                        {screens.map((obj, key) =>
                            <Stack.Screen
                                key={key}
                                options={{title: obj.title}}
                                name={obj.name}
                                component={Wrapper}
                            />
                        )}
                        <Stack.Screen
                            options={{title: 'камера'}}
                            name={'Camera'}
                            component={Camera}
                        />
                    </Stack.Navigator>
                </NavigationContainer>
            </MenuProvider>
        </ScreenContext.Provider>
    }
    return <Loading/>
}


