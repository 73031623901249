import {createContext} from "react";

export const AppContext = createContext()
export const ScreenContext = createContext()
export const DeliveryContext = createContext()
export const PatientContext = createContext()
export const ClinicContext = createContext()
export const OrderContext = createContext()
export const CurrentUserContext = createContext()
export const SectionsContext = createContext()
export const CouriersContext = createContext()
export const WarehouseBoxContext = createContext()
