import {bgSecondaryLight, bold, borderTable, mh1, p1} from "../../../const";
import Icon from "./Icon";
import {Text} from "react-native";
import Time from "../../../lib/modules/chat/Time";
import {Col, PressRow} from "../../markup/markup";

export default function Title({avatarLoader, chatName, messages, setCurrentChat, isActive, chatId}) {
    if (!messages.length) return null
    const {datetime, body} = messages[messages.length - 1]
    return <PressRow
        style={[borderTable, {height: 65}, p1, isActive ? bgSecondaryLight : null]}
        onPress={() => setCurrentChat()}
    >
        <Col style={{width: 50}}>
            <Icon avatarLoader={avatarLoader} chatId={chatId}/>
        </Col>
        <Col style={mh1}>
            <Text style={bold}>{chatName}</Text>
            <Time datetime={datetime}/>
            <Text style={{width: 300}} numberOfLines={1}>{
                body.startsWith('http') ? 'файл': body
            }</Text>
        </Col>
    </PressRow>
}
