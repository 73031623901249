import {useContext, useEffect, useState} from "react";
import {Text, View} from "react-native";
import {PressCol, PressRow, Row} from "../../../lib/markup/markup";
import {AppContext, ClinicContext} from "../../contexts";
import {isAdministrations, isClinic} from "../../../objects/user_methods";
import {H3} from "../../../lib/text/H";
import {screenSize} from "../../../lib/sugar";
import {
    absolute,
    bgPrimaryLight,
    border,
    center,
    esc,
    flex,
    fRow, jBetween,
    jCenter,
    mh1,
    mr1, mr3,
    mt1,
    mt2,
    mv1,
    p1, w100,
    w50
} from "../../../const";
import Loading from "../../../lib/static/Loading";
import FIO from "../../../lib/text/FIO";
import Relation from "../../../components/Relations";
import {ModalWindow} from "../../../lib/markup/ModalWindow";
import BtnDanger from "../../../lib/buttons/BtnDanger";
import BtnWarning from "../../../lib/buttons/BtnWarning";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";

export default function RelationWindow(props) {
    const {clinic} = useContext(ClinicContext)
    const {user, GET, PATCH, DELETE} = useContext(AppContext)
    const [doctor, setDoctor] = useState()
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState(props.relation.status || '')
    const [visible, setVisible] = useState(true)

    useEffect(async () => {
        const response = await GET('main/users/' + props.relation.user)
        setDoctor(response)
    }, [])

    const url = 'clinic/UserClinicRelation/'

    async function setRelation(status) {
        await PATCH(url + props.relation.id, {status})
        setStatus(status)
        setOpen(false)
    }

    async function deleteRelation() {
        await DELETE(url + props.relation.id)
        setOpen(false)
        setVisible(false)
    }

    if (!visible) return null
    if (!doctor) return <Loading/>
    const style = [border, p1]
    const btnStyle = screenSize() === 'desktop' ? [flex, mh1] : [mt1]
    return <PressRow
        style={[mt1, border, p1, screenSize() === 'desktop' ? w50 : w100, jBetween, mr3]}
        onPress={() => setOpen(true)}
    >
        <FIO obj={doctor}/>
        <Relation>{status}</Relation>
        <ModalWindow
            visible={open} onClose={() => setOpen(false)}
            style={[{maxWidth: 800, maxHeight: 600}, border]}
            positionStyle={{top: 60, ...center}}
        >
            <View>
                <Row>
                    <H3>{`роль "${doctor.last_name}" в "${clinic.name}"`}</H3>
                </Row>
                <View>
                    <Row style={screenSize() === 'desktop' ? null : [jCenter, center]}>
                        <PressCol
                            style={[style, status === 'do' ? bgPrimaryLight : null]}
                            onPress={() => setRelation('do')}
                        >
                            <H3>доктор</H3>
                            <Text> - просмотр только своих пациентов</Text>
                        </PressCol>
                        <PressCol
                            visible={status === 'ow' || isAdministrations(user)}
                            style={[
                                style,
                                status === 'ad' ? bgPrimaryLight : null,
                                screenSize() === 'desktop' ? mh1 : mv1
                            ]}
                            onPress={() => setRelation('ad')}
                        >
                            <H3>администратор</H3>
                            <Text> - просмотр всех пациентов клиники</Text>
                            <Text> - управление врачами</Text>
                        </PressCol>
                        <PressCol
                            visible={status === 'ow' || isAdministrations(user)}
                            style={[style, status === 'ow' ? bgPrimaryLight : null]}
                            onPress={() => setRelation('ow')}
                        >
                            <H3>владелец</H3>
                            <Text> - просмотр всех пациентов</Text>
                            <Text> - управление врачами</Text>
                            <Text> - управление администраторами</Text>
                        </PressCol>
                    </Row>
                </View>
                <View style={[screenSize() === 'desktop' ? fRow : null, mt2]}>
                    <BtnDanger
                        style={btnStyle}
                        visible={props.relation && ['ad', 'do'].includes(status) && isClinic(user)}
                        title={'заблокировать'}
                        onPress={() => setRelation('ba')}
                    />
                    <BtnWarning
                        style={btnStyle}
                        visible={props.relation && 'in' === status && isClinic(user)}
                        title={'отменить приглашение'}
                        onPress={deleteRelation}
                    />
                    <BtnDanger
                        style={btnStyle}
                        visible={isAdministrations(user) && props.relation}
                        title={'удалить отношение'}
                        onPress={deleteRelation}
                    />
                    <BtnSecondary
                        style={btnStyle}
                        title={'отмена'}
                        onPress={() => setOpen(false)}
                    />
                </View>
            </View>
        </ModalWindow>
    </PressRow>
}
