import {useContext} from "react";
import {AppContext, ClinicContext} from "../../contexts";
import {esc} from "../../../const";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";

export default function LeaveBtn() {
    const {setAlert, navigation, DELETE} = useContext(AppContext)
    const {relations, self, clinic} = useContext(ClinicContext)

    return <BtnSecondary
        visible={['ad', 'do'].includes(relations[self].status)}
        title={'покинуть организацию'} onPress={() => {
        setAlert({
            title: 'Выйти из ' + clinic.name + '?',
            buttons: [
                {
                    text: 'выйти',
                    style: 'danger',
                    onPress: async () => {
                        await DELETE(`clinic/UserClinicRelation/${relations[self].id}/`)
                        navigation.goBack()
                    }
                },
                esc
            ]
        })
    }}/>
}
