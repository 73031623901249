import React, {useContext, useEffect, useState} from 'react'
import {mr2} from "../../../const";
import {screenSize} from "../../../lib/sugar";
import {AppContext, PatientContext} from "../../contexts";
import Personal from "./Personal";
import Loading from "../../../lib/static/Loading";
import {Flex, Flex2, FlexRow} from "../../../lib/markup/markup";
import TabBar from "../../../lib/markup/TabBar";
import Orders from "./Orders";
import PatientChat from "./PatientChat";
import {isManager} from "../../../objects/user_methods";

export default function () {
    const [patient, setPatient] = useState()
    const {route, GET, POST, PATCH, user} = useContext(AppContext)

    useEffect(async () => {
        let patient = {}
        if (route.params.patient_id) patient = await GET(`main/patients/${route.params.patient_id}`)
        else {
            patient = await POST(`main/patients`)
            patient.justAdded = true
        }
        patient.clinic = patient.clinic ? await GET('main/clinics/' + patient.clinic, null, false) : null
        patient.doctor = patient.doctor ? await GET('main/users/' + patient.doctor) : null
        setPatient(patient)
        if (isManager(user)) GET('main/notifications/manager_read', {patient: patient.id})
        else GET('main/notifications/manager_read', {patient: patient.id})
    }, [])


    async function savePatient(args) {
        let sendArgs = {}
        for (let key in args) {
            if (typeof args[key] === 'object') sendArgs[key] = args[key].id
            else sendArgs[key] = args[key]
        }
        await PATCH('main/patients/' + patient.id, sendArgs)
        setPatient({...patient, ...args})

    }

    if (!patient) return <Loading/>
    else return <PatientContext.Provider value={{patient, savePatient}}>
        <Flex style={screenSize() === 'desktop' ? mr2 : null}>
            <Personal/>
            <Flex visible={(patient.doctor || patient.clinic) && patient.last_name}>
                {screenSize() === 'desktop' ?
                    <FlexRow>
                        <Flex2>
                            <Orders/>
                        </Flex2>
                        <Flex>
                            <PatientChat/>
                        </Flex>
                    </FlexRow>
                    :
                    <TabBar
                        items={[
                            {title: 'наряды', body: <Orders/>},
                            {title: 'комментарии', body: <PatientChat/>},
                        ]}
                    />
                }
            </Flex>
        </Flex>
    </PatientContext.Provider>
}

