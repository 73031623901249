import {faPrint} from "@fortawesome/free-solid-svg-icons";
import BtnSecondary from "../../lib/buttons/BtnSecondary";
import {useContext, useEffect, useState} from "react";
import Loading from "../../lib/static/Loading";
import {AppContext} from "../../screens/contexts";
import {Linking} from "react-native";
import {host} from "../../labConsts";

export default function (props) {
    if (props.visible !== true) return null
    const [state, setState] = useState({loading: false})
    const {currentDate, GET} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        if (!state.loading) return
        const response = await GET('main/deliveries/print', {date: currentDate.isoDate()})
        await Linking.openURL(host + '/' + response.url)
        updateState({loading: false})
    }, [state.loading])

    if (state.loading) return <Loading/>
    return <BtnSecondary
        title={faPrint}
        style={props.style}
        onPress={() => updateState({loading: true})}
    />
}
