import {Row} from "../../../lib/markup/markup";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {CouriersContext} from "../../contexts";
import {ScrollView, Text, View, TouchableOpacity} from "react-native";
import {H2, H3} from "../../../lib/text/H";
import {
    border,
    danger,
    esc,
    flex, mt1,
    mt2, mv1,
    p1, ph1, primary,
    success, warning
} from "../../../const";
import {Time} from "../../../lib/fields/Time";
import {HR} from "../../../lib/static/HR";
import {EDate} from "../../../lib/sugar";

export default function () {
    const [state, setState] = useState({ready: false, url: 'main/CourierTasks/'})
    const {GET, user, currentDate} = useContext(AppContext)
    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let tasks = await GET(state.url + 'filter', {
            lab_date: currentDate.isoDate(),
            courier: user.id,
            confirmed: 1
        })
        tasks.sort((a, b) =>{
            if (!a.courier_time) return 1
            if (!b.courier_time) return -1
            if (a.courier_time > b.courier_time) return 1
            return -1
        })
        updateState({ready: true, tasks})
    }, [currentDate])

    if (!state.ready) return <Loading/>
    return <CouriersContext.Provider value={{
        ...state,
        updateState
    }}>
        {state.tasks.length ? null : <H2 style={mt2}>нет поездок</H2>}
        <ScrollView style={{maxWidth: 400}}>
            {state.tasks.map((task, key) => <Task key={key} index={key} url={state.url}/>)}
        </ScrollView>
    </CouriersContext.Provider>
}

function Task({index}) {
    const {PATCH, setAlert, getClinic} = useContext(AppContext)
    let {updateState, tasks, url} = useContext(CouriersContext)
    let task = tasks[index]

    function finishTask() {
        let now = new EDate()
        tasks[index].finished_at = now.isoTime()
        PATCH(url + task.id, {finished_at: now.isoDatetime()})
        updateState({tasks})
    }

    const id = +task.direction
    if (id) {
        task.clinic = getClinic(id)
        task.direction = task.clinic?.name
    }

    let patients = []
    for (let stage of task.stages) {
        patients.push(stage.patient)
    }
    if (!patients.length) patients = null
    else patients = patients.join(', ')
    const body = <View>
        <Row style={mt1} visible={task.clinic?.address}>
            <Text>{task.clinic?.address}</Text>
        </Row>
        <Row style={mt1} visible={task.clinic?.phone}>
            <Text>{task.clinic?.phone}</Text>
        </Row>
        <HR/>
        <Row style={mt1} visible={task.stages.length}>
            <Text style={[danger]}>забрать</Text>
        </Row>
        <Row style={mv1} visible={patients}>
            <Text style={primary}>отвезти: {patients}</Text>
        </Row>
        <HR/>
        <Row style={mv1} visible={task.comment}>
            <Text style={warning}>{task.comment}</Text>
        </Row>

    </View>

    let buttons = []
    if (!task.finished_at) {
        buttons.push({
            title: 'завершить',
            style: 'success',
            onPress: finishTask
        })
    }
    buttons.push(esc)

    return <TouchableOpacity
        style={[p1, border]}
        onPress={async () => {
            setAlert({
                title: task.direction,
                body,
                buttons
            })
        }}
    >
        <Row>
            <H3 style={[flex, task.finished_at ? success: null]}>{task.direction}</H3>
            <Time style={{width: 40}} time={task.finished_at} editable={false}/>
        </Row>
        <Row>
            <Time time={task.courier_time} editable={false}/>
            <Text style={ph1}>{task.comment}</Text>
        </Row>
    </TouchableOpacity>

}
