import React, {useState} from "react";
import {getWidth} from "../sugar";
import {TextInput, View} from "react-native";
import {field, flex, jRow, stickLeft, stickRight} from "../../const";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import BtnPrimary from "../buttons/BtnPrimary";

export default function ({visible = true, placeholder, value = '', onPress, style = {}}) {
    const [currentValue, setCurrentValue] = useState(value)

    if (!visible) return null
    const width = getWidth(style)
    return <View visible={visible} style={[width, jRow, style]}>
        <TextInput
            style={[flex, stickRight, field]}
            placeholder={placeholder}
            value={currentValue}
            onChangeText={setCurrentValue}
            onKeyPress={({nativeEvent: {key: keyValue}}) => {
                if (keyValue === 'Enter') {
                    onPress(currentValue.trim())
                    setCurrentValue('')
                }
            }}
        />
        <BtnPrimary
            style={stickLeft}
            title={faPlus}
            onPress={() => {
                onPress(currentValue.trim())
                setCurrentValue('')
            }}
        />
    </View>
}
