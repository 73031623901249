import * as React from 'react';
import {View} from 'react-native';
import {Audio} from 'expo-av';
import {faPlay, faStop} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import BtnPrimary from "../buttons/BtnPrimary";
import BtnSecondary from "../buttons/BtnSecondary";


export default function ({url, style}) {
    const [state, setState] = useState({isPlaying: false})

    async function play() {
        const {sound} = await Audio.Sound.createAsync(url)
        setState({sound, isPlaying: true})
        await sound.playAsync()
    }

    async function stop() {
        state.sound.stopAsync()
        setState({...state, isPlaying: false})
    }

    useEffect(() => {
        return state.sound
            ? () => {
                console.log('Unloading Sound');
                state.sound.unloadAsync(); }
            : undefined;
    }, [state.sound]);

    return (
        <View style={{width: 60}}>
            <BtnPrimary title={faPlay} visible={!state.isPlaying} onPress={play}/>
            <BtnSecondary title={faStop} visible={state.isPlaying} onPress={stop}/>
        </View>
    );
}
