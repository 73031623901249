import React, {useEffect, useState} from "react";
import {center, fontBig, margin, primary, secondary} from "../../const";
import {faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {Text} from "react-native";
import {PressRow} from "../markup/markup";
import FA from "../static/FA";

export default function ({
                             editable = true,
                             selected = false,
                             onPress,
                             text = '',
                             style = {},
                             visible = true
                         }) {
    if (!visible) return null
    const [isSelected, setIsSelected] = useState(selected)
    useEffect(() => {
        let isMounted = true
        if (isMounted) setIsSelected(selected)
        return () => isMounted = false
    }, [selected])
    return <PressRow
        disabled={!editable}
        style={[center, margin, style]}
        onPress={() => {
            onPress(!isSelected)
            setIsSelected(!isSelected)
        }}
    >
        <FA
            icon={isSelected ? faToggleOn : faToggleOff}
            style={[{color: isSelected ? primary.color : secondary.color}, style]}
            size={fontBig.fontSize}
        />
        <Text> {text}</Text>
    </PressRow>
}
