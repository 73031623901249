import React, {useState} from "react";
import {TextInput} from "react-native";
import {field, getFieldStyles} from "../../const";
import {capitalize} from "../sugar";
import NonEditableField from "./NonEditableField";

export default function ({
                             value,
                             style,
                             onChange = null,
                             onFocus,
                             onBlur = null,
                             placeholder,
                             visible = true,
                             onKey = null,
                             autoCapitalize = 'none',
                             forwardRef = null,
                             editable = true,
                             onBlurVal = null,
                            autoFocus=false
                         }) {
    if (!visible) return null
    const [wrpStyle, fieldStyle] = getFieldStyles(style)
    if (!editable) return <NonEditableField
        text={value}
        wrpStyle={wrpStyle}
        fieldStyle={fieldStyle}
    />
    const [val, setVal] = useState(value)

    return <TextInput
        ref={forwardRef}
        autoCapitalize={autoCapitalize}
        onKeyPress={({nativeEvent: {key: keyValue}}) => {
            if (!onKey || !onKey[keyValue]) return
            onKey[keyValue](val)
        }}
        style={[field, style]}
        value={val}
        onChangeText={text => {
            text = autoCapitalize ? capitalize(text) : text
            setVal(text)
            if (onChange) onChange(text)
        }}
        onFocus={onFocus ? () => onFocus(val) : null}
        onBlur={() => {
            if (onBlurVal !== null) {
                setVal(onBlurVal)
            }
            if (onBlur) onBlur(val)
        }}
        placeholder={placeholder}
    />
}
