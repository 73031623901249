import {AppContext} from "../../../screens/contexts";
import {
    bgSecondaryLight, bgWarningLight,
    border, btnPrimary,
    danger, dangerLight,
    esc,
    flexEnd, flexStart,
    fontSmall, fRow,
    mb1, ml1,
    mt1,
    padding, primary,
    secondary,
    w100,
    w75
} from "../../../const";
import {Row} from "../../markup/markup";
import {Linking, Pressable, Text, TouchableOpacity, View} from "react-native";
import FA from "../../static/FA";
import {useContext, useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Time, {signatureColor} from "./Time";
import Image from "../../static/Image";
import Video from "../../static/Video";
import Audio from "../../static/Audio";
import File from "../../static/File";
import {isSuperuser} from "../../../objects/user_methods";


export default function Message({message, deleteMessage, inBuffer, setInBuffer, chatId}) {
    const [isRemoved, setIsRemoved] = useState(false)
    const {setAlert, user} = useContext(AppContext)
    if (isRemoved) return null
    const hiddenColorBorder = message.hidden ? {borderColor: dangerLight.color} : null
    const styles = {
        mine: {
            box: [w100, flexEnd, mb1],
            wrapper: [
                mt1,
                padding,
                border,
                hiddenColorBorder,
                w75,
                {backgroundColor: '#93c4ff', borderBottomRightRadius: 0, alignItems: 'flex-end'}
            ],
            text: [flexEnd, {color: btnPrimary.color}],
            signature: [secondary],
            icon: [fontSmall, danger]
        },
        opponent: {
            box: [w100, flexStart, mb1],
            wrapper: [
                flexStart,
                mt1,
                padding,
                border,
                w75,
                {borderBottomLeftRadius: 0},
                bgSecondaryLight,
                hiddenColorBorder,
            ],
            text: [flexStart],
            signature: [secondary],
        },
        system: {
            box: [{marginTop: 3}],
            wrapper: [padding],
            text: [flexStart, fontSmall, secondary],
        },
    }
    if (message.type === 'system') return <Row style={styles.system.box}>
        <Text style={styles[message.type].text}>
            {message.datetime.ruDateTime(false)} {message.author ?
            message.author : "система"}: {message.body}
        </Text>
    </Row>

    return <View style={[styles[message.type].box]}>
        <TouchableOpacity
            style={[
                styles[message.type].wrapper,
                inBuffer ? bgWarningLight : null,
            ]}
            onPress={() => setInBuffer(!inBuffer)}
        >
            {getMessageMedia(message)}
            <Text style={styles[message.type].text}>{message.body}</Text>
        </TouchableOpacity>
        <Row>
            <Time style={signatureColor} datetime={message.datetime}/>
            <Text style={[signatureColor, ml1]}>{message.author}</Text>
            {
                (message.type === 'mine' || isSuperuser(user)) && deleteMessage &&
                <Pressable onPress={() => setAlert({
                    title: 'Удалить сообщение?',
                    buttons: [
                        {
                            style: 'danger',
                            text: 'удалить',
                            onPress: () => {
                                deleteMessage(chatId, message.id)
                                setIsRemoved(true)
                            }
                        },
                        esc
                    ]
                })}>
                    <FA icon={faTimes} style={styles[message.type].icon}/>
                </Pressable>
            }
        </Row>
    </View>
}

function getMessageMedia(message) {
    if (!message.file) return null
    const mediaStyle = {width: 150, height: 120}
    const ext = message.file.slice(-4)
    if (['.mp4', '.vmw'].includes(ext)) return <Video url={message.file} style={mediaStyle}/>
    if (['.mp3', '.oga'].includes(ext)) return <Audio url={message.file}/>
    if (['.jpg', 'jpeg', '.png', '.gif', '.bmp'].includes(ext)) return <Image url={message.file} style={mediaStyle}/>
    return null
}


