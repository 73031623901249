import {Text} from "react-native";
import {bold, center, fontBig, fontNormal, italic, margin, primary} from "../../const";
import React from "react";

export function H1({style, children, visible=true}) {
    if (!visible) return null
    return <Text style={[center, fontBig, primary, bold, margin, {backgroundColor: 'transparent'}, style]}>
        {children}
    </Text>
}

export function H2({style, children, visible=true}) {
    if (!visible) return null
    return <Text style={[center, fontNormal, primary, bold, margin, {backgroundColor: 'transparent'}, style]}>
        {children}
    </Text>
}

export function H3({style, children, visible=true}) {
    if (!visible) return null
    return <Text style={[center, fontNormal, primary, margin, {backgroundColor: 'transparent'}, style]}>
        {children}
    </Text>
}

export function H4({style, children, visible=true}) {
    if (!visible) return null
    return <Text style={[center, fontNormal, primary, italic, margin, {backgroundColor: 'transparent'}, style]}>
        {children}
    </Text>
}
