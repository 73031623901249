import {View, Text, Dimensions, Linking} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import {Col, FlexRow} from "../../../lib/markup/markup";
import {
    bgSecondaryLight, esc, flex,
    jCenter,
    pb1,
    ph1,
    primary,
    stick,
} from "../../../const";
import {Date} from "../../../lib/fields/Date";
import Select from "../../../lib/fields/Select";
import {asObject, EDate, isDesktop, toObject} from "../../../lib/sugar";
import Btn from "../../../lib/buttons/Btn";
import {faCheck, faPlus, faPrint, faTrash} from "@fortawesome/free-solid-svg-icons";
import {isManager} from "../../../objects/user_methods";
import {host} from "../../../labConsts";
import Job from "./Job";
import Loading from "../../../lib/static/Loading";


export default function (props) {
    const {setAlert, DELETE, PATCH, user, GET, POST} = useContext(AppContext)
    const jobsUrl = `main/${props.type}jobs`

    const [state, setState] = useState({
        visible: true,
        mustCompleteIn: props.must_complete_in,
        wasFinished: props.was_finished,
        jobs: [],
        ready: false
    })

    useEffect(async () => {
        updateState({ready: false})
        const args = toObject(props.type, props.id)
        const jobs = await GET(jobsUrl + `/filter`, args)
        if (!jobs.length) {
            const data = await POST(jobsUrl, args)
            updateState({jobs: [data], ready: true})
        }
        else updateState({jobs, ready: true})
    }, [])

    function save(args) {
        PATCH(props.url + '/' + props.id, args)
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    const jobOffsetWidth = 70
    const codeWidth = 70
    if (!state.ready) return <Loading/>
    const editable = isManager(user) && !state.wasFinished
    console.log(editable, '--------------')
    return <Col style={[pb1, isDesktop() ? {width: 600} : flex]} visible={state.visible}>
        <FlexRow style={[bgSecondaryLight]}>
            <View style={[{width: jobOffsetWidth}, jCenter]}>
                <Text style={[primary, ph1]}>id: {props.id}</Text>
            </View>
            <View style={{width: codeWidth}}>
                <Date
                    showYear={false}
                    date={props.created_at}
                    editable={false}
                />
            </View>
            <Select
                editable={editable}
                style={[isDesktop() ? flex : {width: Dimensions.get('window').width - 350}, stick]}
                placeholder={'исполнитель'}
                canBeNull={true}
                selected={props.executor}
                items={props.users}
                label={'last_name'}
                onChange={executor => save({executor})}
            />

            <Date
                editable={editable}
                style={stick}
                date={state.mustCompleteIn}
                useButtons={false}
                showYear={false}
                weekdayMode={false}
                onChange={date => {
                    save({must_complete_in: date.isoDate()})
                    updateState({date})
                }}
            />

            <Date
                visible={state.wasFinished}
                date={state.wasFinished}
                showYear={false}
                editable={false}
            />
            <Btn
                title={faPlus}
                visible={editable}
                onPress={async () => {
                    const data = await POST(
                        jobsUrl,
                        toObject(props.type, props.id)
                    )
                    updateState({jobs: state.jobs.concat(data)})
                }}
            />
            <Btn
                visible={isManager(user)}
                title={faPrint}
                onPress={async () => {
                        const response = await GET(
                            `${props.url}/get_pdf`, asObject(props.type + '_id', props.id))
                        if (response.url) await Linking.openURL(host + '/' + response.url)
                }}
            />
            <Btn
                visible={isManager(user) && !state.wasFinished}
                title={faCheck}
                onPress={() => setAlert({
                    title: 'завершить наряд?',
                    buttons: [
                        {
                            title: 'завершить',
                            style: 'primary',
                            onPress: async () => {
                                const wasFinished = new EDate().isoDate()
                                await save({was_finished: wasFinished})
                                props.reload()
                            }
                        },
                        esc
                    ]
                })}
            />

            <Btn
                visible={editable}
                title={faTrash}
                onPress={() => {
                    setAlert({
                        title: 'удалить наряд?',
                        buttons: [
                            {
                                title: 'удалить',
                                style: 'danger',
                                onPress: async () => {
                                    await DELETE(props.url + '/' + props.id)
                                    updateState({visible: false})
                                }
                            },
                            esc
                        ]
                    })
                }}
            />
        </FlexRow>

        <Col>
            {state.jobs.map((job, key) =>
                <Job
                    jobOffsetWidth={jobOffsetWidth}
                    codeWidth={codeWidth}
                    key={key}
                    {...job}
                    url={jobsUrl}
                    editable={editable}
                    price_list={props.type === 'cadcam' ? 1: 3}
                    deleteJob={async () => {
                        DELETE(jobsUrl + '/' + job.id)
                        let jobs = state.jobs.slice()
                        for (let i=0; i< state.jobs.length; i++) {
                            if (jobs[i].id === job.id) {
                                jobs.splice(i, 1)
                                break
                            }
                        }
                        updateState({jobs})
                    }}
                />)}
        </Col>
    </Col>
}
