import {useContext, useState} from "react";
import {OrderContext} from "../../contexts";
import {Flex, Row} from "../../../lib/markup/markup";
import {flex, jCenter, stick} from "../../../const";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import Select from "../../../lib/fields/Select";
import {shades} from "../../../labConsts";
import NumberField from "../../../lib/fields/NumberField";
import Btn from "../../../lib/buttons/Btn";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {isDesktop} from "../../../lib/sugar";
import {View} from "react-native";
import {isManager} from "../../../objects/user_methods";

export default function Job(props) {
    const {PATCH, getPrice, getPrices, user} = useContext(OrderContext)
    const [state, setState] = useState({price: getPrice(props.price, props.price_list)})

    function save(args) {
        PATCH(props.url + '/' + props.id, args)
    }

    return <Row>
        <View style={[{width:props.jobOffsetWidth}]}/>
            <View style={{width: 60}}>
                <AutocorrectField
                    findField={'code'}
                    minSymbols={1}
                    editable={props.editable}
                    style={[stick, {width: props.codeWidth}]}
                    placeholder={'код'}
                    object={state.price}
                    find={text => getPrices(text, 'code', props.price_list)}
                    onSubmit={object => {
                        save({price: object.id})
                        setState({price: object})
                    }}
                />
            </View>
            <Flex>
                <AutocorrectField
                    editable={props.editable}
                    style={[stick, flex]}
                    placeholder={'позиция'}
                    object={state.price}
                    find={text => getPrices(text, 'name', props.price_list)}
                    onSubmit={object => {
                        save({price: object.id})
                        setState({price: object})
                    }}
                />
            </Flex>
        <View>
            <Select
                editable={props.editable}
                selected={props.color}
                style={[{width: 85}, stick, isDesktop() ? null: {marginLeft: 65}]}
                placeholder={'цвет'}
                canBeNull={true}
                items={shades}
                onChange={color => {
                    save({color})
                }}
            />
        </View>

        <NumberField
            editable={props.editable}
            value={props.count}
            style={[{width: 30}, stick]}
            onChange={count => save({count})}
        />
        <Btn
            visible={props.editable}
            title={faMinus}
            onPress={() => props.deleteJob()}
        />
    </Row>
}
