import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useContext} from "react";
import {AppContext} from "../../screens/contexts";
import BtnSecondary from "../../lib/buttons/BtnSecondary";


export default function (props) {
    if (props.visible !== true) return null
    const {navigation, route} = useContext(AppContext)
    return <BtnSecondary
        title={faPlus}
        onPress={async () => {
            navigation.navigate('Patient', {previewPage: route.name})
        }}
        style={props.style}
    />
}
