import {center, jCenter, mb1} from "../const";
import {Text, View} from "react-native";
import React from "react";
import Loading from "../lib/static/Loading";
import {Flex} from "../lib/markup/markup";

export default function () {
    return <Flex style={[jCenter, center]}>
        <View style={{height: 50}}>
            <Text style={mb1}>проблемы с подключением к интернету или сервер недоступен</Text>
            <Loading/>
        </View>
    </Flex>
}
