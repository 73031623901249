import {Platform, Text, View} from "react-native";
import {useState} from "react";
import DateTimePicker from '@react-native-community/datetimepicker'
import {field} from "../../const";
import {EDate, zfill} from "../sugar";
import Btn from "../buttons/Btn";
import {TimePickerModal} from "react-native-paper-dates";

export const Time = ({
                         visible = true,
                         time,
                         onChange,
                         editable = true,
                         showSeconds = false,
                         isOpen = false,
                         style = {}
                     }) => {
    if (!visible) return null
    const empty = showSeconds ? '--:--:--' : '--:--'
    if (typeof time === 'string') time = new EDate(time)
    function getWidth() {
        return showSeconds ? 105 : 60
    }
    if (!editable) return <Text style={[{width: getWidth()}, style]}>
        {time ? time.isoTime(showSeconds) : empty}
    </Text>
    const [show, setShow] = useState(isOpen)
    const [currentTime, setCurrentTime] = useState(time ? new EDate(time) : null)

    function onChangeTime(selectedTime) {
        if (selectedTime) {
            const newTime = new EDate(selectedTime)
            setCurrentTime(newTime)
            if (onChange) onChange(newTime.isoTime(showSeconds))
        }
        setShow(Platform.OS === 'ios')
    }

    return (
        <View style={[field, {width: getWidth()}, style]}>
            <Btn
                onPress={() => {
                    if (!currentTime) setCurrentTime(new EDate())
                    setShow(true)
                }}
                title={currentTime ? currentTime.isoTime(showSeconds) : empty}
            />

            {show ?
                Platform.OS === 'web' ?
                    <TimePickerModal
                        onDismiss={() => setShow(false)}
                        onConfirm={params => {
                            onChangeTime(`${zfill(params.hours)}:${zfill(params.minutes)}:00`)
                        }}
                        hours={currentTime ? currentTime.getHours() : 0} // default: current hours
                        minutes={currentTime ? currentTime.getMinutes() : 0} // default: current minutes
                        label=" " // optional, default 'Select time'
                        uppercase={false} // optional, default is true
                        cancelLabel="отмена" // optional, default: 'Cancel'
                        confirmLabel="ок" // optional, default: 'Ok'
                        animationType="fade" // optional, default is 'none'
                        locale="ru" // optional, default is automically detected by your system
                    />
                    :
                    <DateTimePicker
                        visible={false}
                        testID="dateTimePicker"
                        value={currentTime}
                        mode={'time'}
                        is24Hour={true}
                        display="default"
                        onChange={(event, time) => onChangeTime(time)}
                    />
                : null
            }
        </View>
    )
}
