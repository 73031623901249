import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import {api} from "../../../lib/api";
import {ScrollView, Text, View} from "react-native";
import {screenSize} from "../../../lib/sugar";
import {bgSecondaryLight, bgWhite, flexStart, mt1} from "../../../const";
import {Col, Row} from "../../../lib/markup/markup";
import Find from "../../../lib/fields/Find";
import ArchiveStatus from "../manager/laboratory/ArchiveStatus";
import {H1} from "../../../lib/text/H";
import Result from "./Result";

export default function (props) {
    const [list, setList] = useState([])
    const [hosts, setHosts] = useState([])
    const {setAlert} = useContext(AppContext)
    const [uploadingStatus, setUploadingStatus] = useState('')
    const [hostsWindowVisible, setHostWindowVisible] = useState(false)

    useEffect(async () => {
        const response = await api.get('main/hosts')
        if (response.status === 200) setHosts(response.data)
        else setAlert({title: 'ошибка сервера', body: 'не могу получить список хостов'})
    }, [])

    async function upload(archive, target) {
        if (!['готово', 'ошибка', 'создаю задание'].includes(uploadingStatus)) {
            setAlert({title: 'уже загружаю', body: 'погоди чутка...'})
            return
        }
        setUploadingStatus('создаю задание')
        const response = await api.post('lab/ArchTasks', {archive, target})
        if (response.status === 201) {
            const task_id = response.data.id
            setUploadingStatus('задание создано')
            while (!['готово', 'ошибка'].includes(uploadingStatus)) {
                setInterval(async () => {
                    const response = await api.get(`lab/ArchTasks/${task_id}`)
                    if (response.status !== 200) setUploadingStatus('ошибка')
                    else setUploadingStatus(response.data)
                }, 500)
            }
        }
    }

    return <View>
        <Row>
            <Find
                placeholder={'пациент, доктор'}
                onPress={async text => {
                    const response = await api.get(`lab/archives/find`, {text})
                    if (response.status === 200) {
                        setList(response.data)
                        if (!response.data.length) {
                            setAlert({title: 'не найдено'})
                            setList([])
                        }
                    } else setAlert({title: 'ошибка сервера'})
                }}
            />
            <H1>{uploadingStatus}</H1>
            <View style={screenSize() === 'desktop' ? {} : mt1}>
                <ArchiveStatus/>
            </View>
        </Row>
        <Row visible={!list.length} style={[mt1, flexStart]}>
            <Col>
                <Text>1) Искать можно по пациенту и по клинике</Text>
                <Text style={mt1}>2) Регистр не важен</Text>
                <Text style={mt1}>3) Можно вводить только начало фамилии,</Text>
                <Text> например: "ивано"</Text>
                <Text style={mt1}>4) Дата очень ПРИМЕРНЫЙ параметр.</Text>
                <Text> Это или дата СОЗДАНИЯ проекта</Text>
                <Text> или дата ПОСЛЕДНЕГО ИЗМЕНЕНИЯ.</Text>
                <Text> Может не соответствовать действительности.</Text>
            </Col>
        </Row>

        <ScrollView style={mt1}>
            {list.map((item, key) =>
                <Result
                    setHostWindowVisible={setHostWindowVisible}
                    key={key}
                    {...item}
                    hosts={hosts}
                    upload={upload}
                    background={key % 2 ? bgWhite : bgSecondaryLight}
                />)}
            <Row style={{height: 40}}/>
        </ScrollView>
    </View>

}
