import {isPhoneCorrect} from "../sugar";
import {Text} from "react-native";
import React from "react";
import Error from "./Error";

export default function ({children, style, visible = true}) {
    if (!visible) return null
    let view = isPhoneCorrect(children)
    if (!view) return <Error>неверный номер</Error>
    view = '+' + view
    return <Text style={[style]}>
        {view.slice(0, 2) + '(' + view.slice(2, 5) + ')' + view.slice(5, 8) + '-' +
            view.slice(8, 10) + '-' + view.slice(10)}
    </Text>
}
