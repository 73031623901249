import {screenSize} from "../../lib/sugar";
import {useContext} from "react";
import {AppContext} from "../../screens/contexts";
import BtnToggle from "../../lib/buttons/BtnToggle";
import {Row} from "../../lib/markup/markup";
import Select from "../../lib/fields/Select";

export default function (props) {
    if (props.visible !== true) return null
    const {deliveryNumber, setDeliveryNumber} = useContext(AppContext)
    if (screenSize() === 'desktop') {
        return <Row>
            {['1 отправка', '2 отправка', '3 каркасы'].map((title, key) =>
                <BtnToggle
                    style={props.style}
                    title={title}
                    onPress={() => setDeliveryNumber(key + 1)}
                    key={key}
                    isActive={key + 1 === deliveryNumber}
                />
            )}
        </Row>
    } else return <Select
            style={[{width: 45, maxWidth: 45, minWidth: 45}, props.style]}
            items={[
                {name: 1, id: 1},
                {name: 2, id: 2},
                {name: 3, id: 3},
            ]}
            selected={1}
            onChange={setDeliveryNumber}
            canBeNull={false}
        />
}
