import {View, TextInput, Text, Linking} from "react-native";
import {FlexRow, PressRow, Row} from "../../../lib/markup/markup";
import {Date} from "../../../lib/fields/Date";
import {
    bold,
    borderTable, esc,
    field,
    flex,
    flex2, flexField,
    fRow, jCenter,
    ml1, ml5,
    mt1, mt4, mt5, p1, primary,
    stick,
    stickLeft,
    stickRight
} from "../../../const";
import Select from "../../../lib/fields/Select";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {EDate, fixWidth} from "../../../lib/sugar";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import Creator from "../../../lib/fields/Creator";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import NumberField from "../../../lib/fields/NumberField";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import {host} from "../../../labConsts";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


export default function () {
    const pureParagraphs = [0, 1, 2, 3, 4].map((key, index) => {
        return {what_to_get: '', count: '', measure_unit: ''}
    })

    const pureAttorney = {
        bill_date: new EDate(),
        bill: '',
    }

    const pureVendor = {name: '', law_name: '', fact_address: '', phones: ''}

    const [state, setState] = useState({
        ready: false,
        attorney: pureAttorney,
        oldAttorneys: [],
        vendor: pureVendor,
        our_company: 0,
        paragraphs: pureParagraphs.slice(),
        refresh: false
    })
    const {GET, POST, PATCH, DELETE, setAlert} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }

    function saveParagraph(index, args) {
        let paragraphs = state.paragraphs.slice()
        paragraphs[index] = {...paragraphs[index], ...args}
        updateState({paragraphs})
    }

    function saveVendor(args) {
        if (!state.vendor) return
        PATCH('lab/companies/' + state.vendor.id, args)
        updateState({vendor: {...state.vendor, ...args}})
    }

    function saveOurCompany(args) {
        PATCH('lab/companies/' + state.our_companies[state.our_company].id, args)
        let our_companies = state.our_companies.slice()
        our_companies[state.our_company] = {...state.our_companies[state.our_company], ...args}
        updateState({our_companies})
    }

    async function saveAttorney(args) {
        if (state.attorney.id) PATCH('lab/attorneys/' + state.attorney.id, args)
        updateState({attorney: {...state.attorney, ...args}})
    }

    async function print() {
        if (!state.vendor.id) {
            setAlert({title: 'укажите поставщика'})
            return
        }
        if (!state.attorney.bill) {
            setAlert({title: 'укажите номер счёта'})
            return
        }
        let id = state.attorney.id
        if (!state.attorney.id) {
            console.log(state)
            const args = {
                bill: state.attorney.bill,
                bill_date: state.attorney.bill_date.isoDate(),
                our_company: state.our_companies[state.our_company].id,
                vendor: state.vendor.id,
                courier: state.attorney.courier,
            }
            console.log(args)
            let attorney = await POST('lab/attorneys/', args)
            let paragraphs = state.paragraphs.slice()
            for (let i = 0; i < 5; i++) {
                if (!paragraphs[i].what_to_get) continue
                const p = await POST('lab/AttorneyParagraphs', {attorney: attorney.id, ...paragraphs[i]})
                paragraphs[i].id = p.id
            }
            let oldAttorneys = state.oldAttorneys.slice()
            oldAttorneys.unshift(attorney)
            updateState({oldAttorneys})
            id = attorney.id
        }
        const response = await GET('lab/attorneys/print', {id})
        await Linking.openURL(host + '/' + response.url)
        clean()
    }

    function clean() {
        updateState({
            paragraphs: pureParagraphs.slice(),
            attorney: pureAttorney,
            vendor: pureVendor
        })
    }

    function deleteCompany(company) {
        setAlert({
            title: 'удалить компанию',
            body: company.name,
            buttons: [
                {
                    title: 'удалить',
                    style: 'danger',
                    onPress: async () => {
                        await DELETE('lab/companies/' + company.id)
                        updateState({refresh: !state.refresh})
                    }
                },
                esc
            ]
        })
    }

    async function createCompany(name, is_our) {
        await POST('lab/companies', {name, is_our})
        updateState({refresh: !state.refresh})
    }

    useEffect(async () => {
        let date = new EDate()
        date.change({days: -30})
        let couriers = await GET('main/users/filter', {groups__name: 'курьеры', is_active: 1})
        couriers.sort((a, b) => {
            if (a.code > b.code) return 1
            return -1
        })
        let oldAttorneys = await GET('lab/attorneys/filter', {date__gt: date.isoDate()})
        oldAttorneys.sort((a, b) => {
            if (a.date > b.date) return 1
            return -1
        })
        const our_companies = await GET('lab/companies/filter', {is_our: 1})
        updateState({
            vendor: pureVendor,
            ready: true,
            couriers,
            our_companies,
            oldAttorneys,
            selectedCourier: couriers[0].id
        })
    }, [state.refresh])


    if (!state.ready) return <Loading/>
    return <View>
        <Row style={mt1}>
            <Creator
                placeholder={'новый поставщик'}
                onPress={name => createCompany(name, 0)}
            />
            <Creator
                style={ml1}
                placeholder={'новый получатель'}
                onPress={name => createCompany(name, 1)}
            />
        </Row>
        <Row style={mt4}>
            <Select
                items={state.our_companies}
                onChange={our_company_id => {
                    let our_company = 0
                    for (let i = 0; i < state.our_companies.length; i++) {
                        if (state.our_companies[i].id === our_company_id) {
                            our_company = i
                            break
                        }
                    }
                    updateState({our_company})
                }}
                canBeNull={false}
                selected={state.our_company}
                style={[flexField, stick]}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].law_name}
                placeholder={'юр.наименование'}
                onChangeText={law_name => saveOurCompany({law_name})}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].law_address}
                placeholder={'юр.адрес'}
                onChangeText={law_address => saveOurCompany({law_address})}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].fact_address}
                placeholder={'факт.адрес'}
                onChangeText={fact_address => saveOurCompany({fact_address})}
            />
        </Row>
        <Row>
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].bill}
                placeholder={'№ счёта'}
                onChangeText={bill => saveOurCompany({bill})}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].bank}
                placeholder={'банк'}
                onChangeText={bank => saveOurCompany({bank})}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].director}
                placeholder={'директор'}
                onChangeText={director => saveOurCompany({director})}
            />
            <TextInput
                style={[flexField, stick]}
                value={state.our_companies[state.our_company].accountant}
                placeholder={'гл. бухгалтер'}
                onChangeText={accountant => saveOurCompany({accountant})}
            />
            <BtnSecondary
                style={stick}
                title={faTrash}
                onPress={() => deleteCompany(state.our_companies[state.our_company])}
            />
        </Row>
        <View style={[mt4, flex, fRow, {height: field.height}]}>
            <AutocorrectField
                style={[Boolean(state.vendor.id) ? stickRight : null]}
                placeholder={'поставщик'}
                find={async text => await GET('lab/companies/filter', {name__istartswith: text, is_our: 0})}
                onSubmit={vendor => updateState({vendor})}
                onSubmitClear={true}
                object={state.vendor}
                findField={'name'}
            />
            <FlexRow visible={Boolean(state.vendor.id)}>
                <TextInput
                    style={[flexField, stick, flex2]}
                    placeholder={'юр. наименование'}
                    onChangeText={law_name => saveVendor({law_name})}
                    value={state.vendor?.law_name}
                />
                <TextInput
                    style={[flexField, stick, flex2]}
                    placeholder={'фактический адрес'}
                    onChangeText={fact_address => saveVendor({fact_address})}
                    value={state.vendor?.fact_address}
                />
                <TextInput
                    style={[field, stick, flex]}
                    placeholder={'телефоны'}
                    onChangeText={phones => saveVendor({phones})}
                    value={state.vendor?.phones}
                />
                <BtnSecondary
                    style={stickLeft}
                    title={faTrash}
                    onPress={() => deleteCompany(state.vendor)}
                />
            </FlexRow>

        </View>
        <Row style={mt1}>
            <Select
                placeholder={'курьер'}
                items={state.couriers}
                canBeNull={false}
                label={'code'}
                onChange={selectedCourier => {
                    saveAttorney({selectedCourier, courier: selectedCourier})
                }}
                selected={state.selectedCourier}
            />
            <TextInput
                style={[field, ml1]}
                placeholder={'№ счёта'}
                onChangeText={bill => saveAttorney({bill})}
                value={state.attorney?.bill}
            />
            <Date
                style={[field, ml1]}
                onChange={bill_date => saveAttorney({bill_date})}
                date={state.attorney?.bill_date}
            />
            <BtnPrimary
                style={ml1}
                title={'печать'}
                onPress={print}
            />
            <BtnSecondary
                style={ml5}
                title={'очистить'}
                onPress={clean}
            />
        </Row>
        <View style={mt1}>
            {state.paragraphs.map((paragraph, key) => {
                return <Row key={key}>
                    <TextInput
                        style={[flexField, stick]}
                        placeholder={'что получить'}
                        onChangeText={what_to_get => saveParagraph(key, {what_to_get})}
                        value={state.paragraphs[key].what_to_get}
                    />
                    <NumberField
                        style={[stick, fixWidth(60)]}
                        placeholder={'кол-во'}
                        onChangeText={count => saveParagraph(key, {count})}
                        value={state.paragraphs[key].count}
                    />
                    <TextInput
                        style={[field, stick, fixWidth(60)]}
                        placeholder={'ед.'}
                        onChangeText={measure_unit => saveParagraph(key, {measure_unit})}
                        value={state.paragraphs[key].measure_unit}
                    />
                </Row>
            })}
        </View>
        <View style={[mt5, fixWidth(800)]}>
            <Text style={[bold, primary]}>история</Text>
            {state.oldAttorneys.map((a, key) => {
                return <PressRow
                    key={key}
                    onPress={async () => {
                        let paragraphs = pureParagraphs.slice()
                        const response = await GET('lab/AttorneyParagraphs/filter', {attorney: a.id})
                        for (let i = 0; i < response.length; i++) {
                            paragraphs[i] = response[i]
                        }
                        const vendor = await GET('lab/companies/' + a.vendor)
                        updateState({attorney: a, paragraphs, vendor})
                    }}
                >
                    <View style={[p1, borderTable, fixWidth(120)]}>
                        <Date editable={false} date={a.date}/>
                    </View>
                    <View style={[flex, p1, borderTable, jCenter, fixWidth(300)]}>
                        <CompanyName companyID={a.our_company}/>
                    </View>
                    <View style={[flex, p1, borderTable, jCenter, fixWidth(260)]}>
                        <Text>{a.bill}</Text>
                    </View>
                    <View style={[p1, borderTable, fixWidth(120)]}>
                        <Date editable={false} date={a.bill_date}/>
                    </View>
                </PressRow>
            })}
        </View>
    </View>
}

function CompanyName({companyID}) {
    const {GET} = useContext(AppContext)
    const [name, setName] = useState()

    useEffect(async () => {
        const company = await GET('lab/companies/' + companyID)
        setName(company.name)
    }, [])
    if (!name) return <Loading/>
    return <Text>{name}</Text>
}
