import {Keyboard, Text} from "react-native";
import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../contexts";
import {Col, Flex, Row} from "../../lib/markup/markup";
import {isPhoneCorrect} from "../../lib/sugar";
import {api} from "../../lib/api";
import {center, green, itemWidth, jCenter, mt1, mv1, red, maxWidth} from "../../const";
import BtnPrimary from "../../lib/buttons/BtnPrimary";
import {BtnTimer} from "../../lib/buttons/BtnTimer";
import NumberField from "../../lib/fields/NumberField";
import {defaultUrl, loadDataAsync} from "../../objects/user_methods";


export default function () {
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);
    const {navigation, user, isFocused, setUser} = useContext(AppContext)

    useEffect(async () => {
        if (user) {
            navigation.navigate(defaultUrl(user), {previewsScreen: 'Login'})
            return null
        } else {
            const response = await api.get('main/users/info/')
            if (response.status === 200) {
                setUser(response.data)
                navigation.navigate(defaultUrl(response.data))
            }
        }
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            () => {
                setKeyboardVisible(true); // or some other action
            }
        )
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            () => {
                setKeyboardVisible(false); // or some other action
            }
        )
        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, [user, isFocused]);

    return <Flex>
        <InputGroup/>
        {!isKeyboardVisible ? <Signature/>: null}
    </Flex>
}

function InputGroup() {
    const {navigation, setUser, GET, updateAppState} = useContext(AppContext)
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordSent, setPasswordSent] = useState(false)

    async function requestPassword() {
        if (!phone) {
            setError('введите номер телефона')
            return
        }
        const _phone = isPhoneCorrect(phone)
        if (!_phone) {
            setError('некорректный номер телефона')
            return
        }
        const status = await api.requestPassword( {phone: _phone})
        if (status === 503) {
            setError('сервер недоступен')
            return
        }
        if (status === 208) {
            setPasswordSent(true)
            return
        }
        if (status === 404) {
            setError('пользователь не зарегистрирован')
            return
        }
        if (status !== 200) {
            setError('ошибка ' + status)
            return
        }
        setPasswordSent(true)
    }

    async function login() {
        const _phone = isPhoneCorrect(phone)
        if (password.length !== 4) {
            setError('неверный код авторизации')
            return
        }
        const status = await api.login({phone: _phone, password})
        if (status === 503) {
            setError('сервер недоступен')
            return
        }
        const data = await GET('main/users/info')

        if (data) {
            updateAppState({user: data, ...await loadDataAsync(data)})
            navigation.navigate(defaultUrl(data))
        }
    }

    return <Flex style={[center, jCenter]}>

        <NumberField
            style={[center, mv1]}
            placeholder={'номер телефона'}
            onChange={setPhone}
            onKey={{
                Enter: requestPassword
            }}
        />

        <Col visible={passwordSent} style={mv1}>
            <Text style={[green, maxWidth, center]}>
                мы отправили Вам код в SMS
            </Text>
            <Text style={[green, maxWidth, center]}>
                введите его ниже
            </Text>
        </Col>

        <NumberField
            visible={passwordSent}
            style={[center, mv1]}
            placeholder={'код'}
            onChange={setPassword}
            onKey={{
                Enter: login
            }}
        />

        <Text style={[center, red]}>{error}</Text>


        <Col style={[mt1, jCenter]} visible={passwordSent}>
            <BtnPrimary title={'войти'} onPress={login} wrpStyle={itemWidth}/>
            <BtnTimer
                wrpStyle={[mt1, itemWidth]}
                start={60}
                endMessage={'выслать повторно'}
                onPress={requestPassword}
            />
        </Col>

        <Row visible={!passwordSent} style={[jCenter]}>
            <BtnPrimary title={'выслать код'} onPress={requestPassword} style={itemWidth}/>
        </Row>
    </Flex>
}

function Signature() {
    return <Flex style={[center]}>
        <Text>Алексей Ардов</Text>
        <Text>2021-2022</Text>
    </Flex>
}

