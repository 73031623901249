import {useEffect, useState} from "react";
import {api} from "../../../../lib/api";
import {Row} from "../../../../lib/markup/markup";
import Label from "../../../../lib/text/Label";

export default function () {
    const [status, setStatus] = useState([])

    useEffect(async () => {
        const response = await api.get('lab/archive_status')
        if (response.status === 200) setStatus(response.data)
    }, [])

    return <Row>
        {status.map((arg, key) => <Label key={key} title={arg.label} value={arg.value}/>)}
    </Row>
}