import {Col} from "../../lib/markup/markup";
import {isAdministrations, isLab} from "../../objects/user_methods";
import {H3} from "../../lib/text/H";
import {Date} from "../../lib/fields/Date";
import TimeRow from "./TimeRow";
import {useContext} from "react";
import {AppContext} from "../../screens/contexts";
import {isSmall} from "../../lib/sugar";

export default function ({save, lab_date, lab_time, pickup, clinic_date, times, empty}) {
    const {user} = useContext(AppContext)
    return <Col
        visible={isLab(user)}
    >
        <H3 visible={isAdministrations(user) && !isSmall()}>лабораторное (планируемое)</H3>
        <Date
            date={lab_date}
            onChange={lab_date => {
                let args = {lab_date, courier_task: null}
                let log = `лаб. дата: ${lab_date.ruDate()} > ${lab_date.ruDate()}`
                if (lab_date > clinic_date) {
                    args.clinic_date = lab_date
                    log += ` клин. дата: ${!clinic_date ? "" : clinic_date.ruDate()} `
                    log += `> ${args.clinic_date.ruDate()}`
                }
                save(args, log)
            }}
        />

        {times.map((str, key) =>
            <TimeRow
                time={lab_time}
                save={(lab_time, old) => save(
                    {lab_time, pickup: false}, `лаб.время: ${old} > ${lab_time}`
                )}
                str={str}
                key={key}
                empty={empty}
                pickup={pickup}
            />
        )}
    </Col>
}
