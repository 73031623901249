import {Col} from "../../lib/markup/markup";
import {isAdministrations, isClinic} from "../../objects/user_methods";
import {isDesktop, isSmall} from "../../lib/sugar";
import {H3} from "../../lib/text/H";
import {Date} from "../../lib/fields/Date";
import TimeRow from "./TimeRow";
import {AppContext} from "../../screens/contexts";
import {useContext} from "react";
import {ml2, mt2} from "../../const";

export default function ({clinic_date, lab_date, clinic_time, empty, pickup, times, save}) {
    const {user} = useContext(AppContext)

    return <Col
        visible={isAdministrations(user) || isClinic(user)}
        style={isDesktop() ? ml2 : mt2}
    >
        <H3 visible={isAdministrations(user) && !isSmall()}>клиническое (фактическое)</H3>
        <Date
            date={clinic_date}
            onChange={clinic_date => {
                if (clinic_date < lab_date) return
                let log = `клин.дата: ${clinic_date.ruDate(false)} >`
                log += `${clinic_date.ruDate(false)}`
                save({clinic_date}, log)
            }}
        />

        {times.map((str, key) =>
            <TimeRow
                time={clinic_time}
                save={(clinic_time, old) => save(
                    {clinic_time, pickup: false}, `лаб.время: ${old} > ${clinic_time}`
                )}
                str={str}
                key={key}
                empty={empty}
                pickup={pickup}
            />
        )}
    </Col>
}
