import {
    background, bgSecondaryLight, border, btnSecondary,
    center, field, fRow,
    ml1, mr2,
    mt1,
    p1,
    stick,
    stickLeft,
    stickRight
} from "../const";
import {faArchive, faArrowLeft, faSearch, faSkiingNordic} from "@fortawesome/free-solid-svg-icons";
import {Image, Platform, ScrollView} from "react-native";
import {useContext} from "react";
import Burger from "./Burger";
import {AppContext} from "./contexts";
import CreatePatient from "../components/header/CreatePatient";
import PrintDeliveries from "../components/header/PrintDeliveries";
import DeliveriesNumber from "../components/header/deliveriesNumber";
import Messengers from "../components/header/Messengers";
import {fixWidth, isDesktop, isSmall, lessTablet, screenSize} from "../lib/sugar";
import {isAdministrations, isCadCam, isLab, isManager, isTech} from "../objects/user_methods";
import {Col, Flex, FlexRow, PressCol, Row} from "../lib/markup/markup";
import FA from "../lib/static/FA";
import {LinearGradient} from "expo-linear-gradient";
import BtnSecondary from "../lib/buttons/BtnSecondary";
import {Date} from "../lib/fields/Date";
import LabTools from "../components/header/LabTools";
import NotificationChecker from "../components/header/NotificationChecker";


export default function () {
    const {navigation, route, user, currentDate, setCurrentDate} = useContext(AppContext)
    const canGoBack = navigation.canGoBack() && route.name !== 'Login' &&
        (typeof route.params !== 'undefined' && route.params.previewsScreen !== 'Login')
    const isManagerWindow = ['Laboratory', 'CadCam', 'Foundry'].includes(route.name)
    const offset = screenSize() === 'desktop' ? ml1 : [stick,
        isAdministrations(user) ?
            isSmall() ? fixWidth(26) : fixWidth(38)
            :
            null
    ]
    return <FlexRow style={[background.header, p1]}>
        <PressCol
            onPress={() => {
                if (canGoBack) {
                    navigation.goBack()
                }
            }}
        >
            <Row style={[center]}>
                <FA icon={faArrowLeft} visible={canGoBack}/>
                <Image style={{height: 40, width: 40}} source={require('../assets/icon.png')}/>
            </Row>
        </PressCol>

            <Flex style={mt1} visible={user}>
                <ScrollView style={fRow} horizontal={true}>

                    <LabTools style={offset} visible={isLab(user)}/>
                    <CreatePatient
                        visible={isManagerWindow}
                        style={offset}
                    />
                    <PrintDeliveries
                        visible={route.name === 'Laboratory' && isDesktop()}
                        style={offset}
                    />
                    <BtnSecondary
                        visible={isManager(user)}
                        style={offset}
                        title={faSkiingNordic}
                        onPress={() => navigation.navigate(
                            'Couriers',
                            {department: ['Cadcam', 'Foundry'].includes(route.name) ? 'cadcam': 'lab'})}
                    />
                    <DeliveriesNumber visible={route.name === 'Laboratory'} style={offset}/>
                    <Messengers
                        visible={user && isAdministrations(user)}
                        style={offset}
                    />
                    <BtnSecondary
                        title={faSearch}
                        visible={Boolean(user) && route.name !== 'Find' &&
                            (isAdministrations(user) || isTech(user) || isCadCam(user))}
                        style={[
                            offset
                        ]}
                        onPress={() => navigation.navigate('Find', {cadcam: ['CadCam', 'Foundry'].includes(route.name)})}
                    />
                    <BtnSecondary
                        style={[offset, lessTablet() ? stick: ml1]}
                        title={isDesktop() ? 'архив': faArchive}
                        visible={isCadCam(user)}
                        onPress={() => navigation.navigate('Archive')}
                    />
                    <Date
                        visible={['Total'].includes(route.name) || isManagerWindow}
                        displayMode={lessTablet() ? 'button': 'normal'}
                        date={currentDate}
                        showYear={false}
                        onChange={setCurrentDate}
                        style={[offset, lessTablet() ? stick: ml1]}
                        useButtons={isDesktop()}
                    />


                    <Date
                        visible={['Tech', 'Cadcamist', 'CourierTasks'].includes(route.name)}
                        displayMode={'normal'}
                        date={currentDate}
                        showYear={false}
                        onChange={setCurrentDate}
                        style={ml1}
                        useButtons={true}
                    />

                </ScrollView>
            </Flex>
        <NotificationChecker/>
            <Col style={[mt1, isDesktop() ? mr2 : null]}>
                {user && <Burger navigation={navigation} route={route}/>}
            </Col>
    </FlexRow>
}

function BtnBorder({style}) {
    const {user} = useContext(AppContext)
    if (!lessTablet() || !user) return null
    return <LinearGradient
        colors={btnSecondary.background}
        style={[{width: border.borderRadius, height: field.height}, border, bgSecondaryLight, style]}
    >
    </LinearGradient>
}
