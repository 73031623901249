import React, {useContext, useRef, useState} from "react";
import {AppContext, PatientContext} from "../../contexts";
import {isAdministrations, isClinic, isLab} from "../../../objects/user_methods";
import {isDesktop, screenSize} from "../../../lib/sugar";
import {center, esc, mh1, ml1, ml2, mt1, primary} from "../../../const";
import {Text, View} from "react-native";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Row} from "../../../lib/markup/markup";
import FIO from "../../../lib/text/FIO";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import TextField from "../../../lib/fields/TextField";
import Btn from "../../../lib/buttons/Btn";
import {H2} from "../../../lib/text/H";
import {TouchableOpacity} from "react-native";


export default function () {
    const {patient, savePatient} = useContext(PatientContext)
    const {user, GET, DELETE,navigation, setAlert} = useContext(AppContext)
    const [editable, setEditable] = useState(!((patient.doctor || patient.clinic) && patient.last_name))

    const patientRef = useRef()
    const doctorRef = useRef()

    const offset = screenSize() === 'desktop' ? ml1 : null

    return <View>
        <Row>
            <H2 visible={patient.justAdded === true}>добавление пациента</H2>
        </Row>
        <Row>
            <Row style={[center, mt1]} visible={!editable}>
                <TouchableOpacity
                    onPress={() => {
                    if (isAdministrations(user)) navigation.navigate('ClinicProfile', {clinic_id: patient.clinic.id})
                }}>
                    <Text style={[primary]}>{patient.clinic ? patient.clinic.name : ''} / </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                    if (isAdministrations(user)) navigation.navigate('UserProfile', {user_id: patient.doctor.id})
                }}>
                    <FIO
                        style={[primary]}
                        obj={patient.doctor}
                        visible={!editable && isLab(user)}
                    />
                </TouchableOpacity>
                <Text> / </Text>
                <FIO style={primary} obj={patient} visible={!editable && isLab(user)}/>
            </Row>
            <AutocorrectField
                object={patient?.clinic}
                visible={editable && isLab(user)}
                placeholder={'клиника'}
                find={async text => await GET('main/clinics/filter', {name__istartswith: text})}
                findField={'name'}
                onSubmit={async clinic => {
                    const doctors = await GET('main/users/filter', {relations__clinic: clinic.id})
                    if (doctors.length === 1) {
                        await savePatient({doctor: doctors[0], clinic})
                        patientRef.current.focus()
                    } else {
                        await savePatient({clinic})
                        doctorRef.current.focus()
                    }
                }}
            />
            <AutocorrectField
                forwardRef={doctorRef}
                style={isDesktop() ? mh1 : null}
                object={patient.doctor}
                visible={editable && isLab(user)}
                placeholder={'врач'}
                minSymbols={0}
                find={async text => {
                    return await GET(
                        'main/users/filter',
                        {
                            last_name__istartswith: text,
                            relations__clinic: patient.clinic.id,
                        }
                    )
                }}
                findField={'last_name'}
                onSubmit={async doctor => {
                    await savePatient({doctor})
                    patientRef.current.focus()
                }}
            />
            <TextField
                forwardRef={patientRef}
                style={[offset, screenSize() === 'desktop' ? ml2 : null]}
                visible={editable}
                placeholder={'фамилия'} value={patient.last_name}
                onChange={text => savePatient({last_name: text})}
                autoCapitalize={'sentences'}
            />
            <TextField
                style={offset}
                visible={editable && isClinic(user)}
                placeholder={'имя'}
                value={patient.first_name}
                onChange={text => savePatient({first_name: text})}
            />
            <TextField
                style={offset}
                visible={editable && isClinic(user)}
                placeholder={'отчество'} value={patient.middle_name}
                onChange={text => savePatient({middle_name: text})}/>
            <TextField
                style={offset}
                visible={editable && isClinic(user)}
                placeholder={'телефон'}
                value={patient.phone}
                onChange={text => savePatient({phone: text})}
            />
            <Btn
                style={ml1}
                visible={patient.last_name && (isClinic(user) || isAdministrations(user))}
                title={faEdit}
                onPress={() => setEditable(!editable)}
            />
            <Btn
                style={ml1}
                visible={isClinic(user) || isAdministrations(user)}
                title={faTrash}
                onPress={() => setAlert({
                    title: 'удалить пациента?',
                    buttons: [
                        {
                            title: 'удалить',
                            style: 'danger',
                            onPress: () => {
                                DELETE('main/patients/' + patient.id)
                                navigation.goBack()
                            }
                        },
                        esc
                    ]
                }) }
            />
        </Row>
    </View>
}
