import {ScrollView, Text} from "react-native";
import {flex, flexStart, mt1, maxWidth} from "../../../const";
import Table from "../../../lib/markup/Table";
import CheckBox from "../../../lib/fields/CheckBox";
import Phone from "../../../lib/text/Phone";
import {H3} from "../../../lib/text/H";
import {AppContext} from "../../contexts";
import {PressCol} from "../../../lib/markup/markup";
import {useContext} from "react";

export default function ({state, updateState}) {
    if (state.mode !== 'filter') return null
    return <ScrollView style={mt1}>
        <Table
            maxWidth={800}
            body={state.clinics.map((clinic, key) => {
                if (clinic.is_removed) return null
                return [
                    <CheckBox
                        selected={state.selected.includes(clinic.id)}
                        onPress={() => {
                            const index = state.selected.indexOf(clinic.id)
                            if (index === -1) updateState({selected: state.selected.concat(clinic.id)})
                            else {
                                let newSelected = state.selected.slice()
                                newSelected.splice(index, 1)
                                updateState({selected: newSelected})
                            }
                        }}
                    />
                    ,
                    <ClinicCol clinic_id={clinic.id}>
                        <Phone style={flex} visible={clinic.phone}>{clinic.phone}</Phone>
                    </ClinicCol>,
                    <ClinicCol clinic_id={clinic.id}>
                        <H3 style={flexStart}>{clinic.name}</H3>
                    </ClinicCol>,
                    <ClinicCol clinic_id={clinic.id}>
                        <Text>{clinic.address}</Text>
                    </ClinicCol>
                ]
            })}
            widths={[
                30,
                120,
                250,
                350,
            ]}
        />
    </ScrollView>
}

function ClinicCol({children, clinic_id}) {
    const {navigation} = useContext(AppContext)
    return <PressCol
        style={flex} onPress={() => navigation.navigate('ClinicProfile', {clinic_id})}
    >
        {children}
    </PressCol>
}
