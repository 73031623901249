import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../contexts";
import {EDate, isDesktop} from "../../../../lib/sugar";
import {Dimensions, ScrollView} from "react-native";
import {m0, jRow, stick, stickLeft, stickRight} from "../../../../const";
import BtnSecondary from "../../../../lib/buttons/BtnSecondary";
import BtnPrimary from "../../../../lib/buttons/BtnPrimary";
import BtnDanger from "../../../../lib/buttons/BtnDanger";

export default function () {
    const {route, holidays, currentDate, setCurrentDate} = useContext(AppContext)
    if (route.name !== 'Laboratory') return null
    const btnWidth = 62
    const datesTotal = isDesktop() ?
        Math.floor(Dimensions.get('window').width * 3/(4*btnWidth))
        :
        Math.floor(Dimensions.get('window').width / btnWidth)
    const [pool, setPool] = useState([])

    function generatePool() {
        let date = new EDate()
        let dates = []
        while (dates.length < datesTotal) {
            if (!holidays.includes(date.isoDate())) {
                dates.push(date.copy())
            }
            date.change({days: 1})
        }
        setPool(dates)
    }

    useEffect(() => {
        if (holidays.length && !pool.length) generatePool()
    }, [holidays, currentDate])

    return <ScrollView horizontal={true} style={[jRow, {maxHeight: 40, minHeight: 40, height: 40}]}>
        {
            pool.map((date, key) => {
                const args = {
                    key: key,
                    title: `${date.getDate()} ${date.monthName().slice(0, 3)}`,
                    style: [key ? key === datesTotal - 1 ? stickLeft : stick : stickRight, m0],
                    onPress: () => setCurrentDate(date)
                }
                if (currentDate.isoDate() === date.isoDate()) return <BtnDanger {...args}/>
                if (date.getDay() === 6) return <BtnPrimary {...args}/>
                return <BtnSecondary {...args}/>
            })
        }
    </ScrollView>
}
