import {useContext} from "react";
import {AppContext} from "../../screens/contexts";
import {Col, Row} from "../../lib/markup/markup";
import {isAdministrations, isClinic} from "../../objects/user_methods";
import {Text} from "react-native";
import {EDate} from "../../lib/sugar";
import {Date} from "../../lib/fields/Date";
import {center, jCenter, primaryLight} from "../../const";

export default function ({clinic_date, clinic_time}) {
    const {user} = useContext(AppContext)
    return <Row visible={isAdministrations(user) || isClinic(user)}>
            <Date
                style={primaryLight}
                editable={false}
                date={clinic_date}
                showYear={false}
                weekdayMode={false}
            />
            <Col style={{width: 50, ...center, ...jCenter}}>
                <Text style={primaryLight}>{
                    clinic_time ?
                        new EDate(clinic_time).isoTime(false)
                        :
                        ' --:-- '
                }</Text>
            </Col>
        </Row>
}
