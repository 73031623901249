import {useContext, useEffect, useState} from "react";
import TabBar, {Tab} from "../../../lib/markup/TabBar";
import {AppContext} from "../../contexts";
import PriceList from "./PriceList";
import Loading from "../../../lib/static/Loading";
import {Flex, Row} from "../../../lib/markup/markup";

export default function (props) {
    const [priceLists, setPriceLists] = useState([])
    const [active, setActive] = useState(0)
    const {GET} = useContext(AppContext)

    useEffect(async () => {
        const response = await GET('main/PriceLists')
        setPriceLists(response)
    }, [])

    if (!priceLists.length) return <Loading/>
    return <Flex>
        <Row>
            {priceLists.map((pl, key) => {
                return <Tab
                    title={pl.name}
                    key={key}
                    active={key === active}
                    onPress={() => setActive(key)}
                />
            })}
        </Row>
        <PriceList {...priceLists[active]}/>
    </Flex>
}









