import {screenSize} from "../../../lib/sugar";
import {useContext} from "react";
import Comments from "./Coments";
import Cadcam from "./Cadcam";
import Foundry from "./Foundry";
import {AppContext} from "../../contexts";
import DateButtons from "./laboratory/DateButtons";
import TabBar from "../../../lib/markup/TabBar";
import {Flex, Flex3, Flex4, Flex5, FlexRow, Row} from "../../../lib/markup/markup";
import Laboratory from "./laboratory/Laboratory";
import {ScrollView} from "react-native";
import {flex, fRow, ml1, mr1, mt1} from "../../../const";
import {isManager} from "../../../objects/user_methods";
import CadcamToday from "./CadcamToday";
import CadcamInWork from "./CadcamInWork";
import {View} from "react-native";
import PercentOfWork from "./PercentOfWork";


export default function Manager() {
    const {route, user} = useContext(AppContext)
    const department = route.name === 'Laboratory' ? 'lab' : 'cadcam'
    if (!isManager(user)) return <Flex>
        <DateButtons/>
        <ScrollView style={[mr1]}>
            <MainWindow/>
        </ScrollView>
    </Flex>
    return screenSize() === 'desktop' ?
        <FlexRow>
            <Flex3>
                {department === 'cadcam' ? <PercentOfWork/>: null}
                <Flex>
                    <DateButtons/>
                    <MainWindow/>
                </Flex>
            </Flex3>
            <Flex>
                <Comments department={department}/>
            </Flex>
        </FlexRow>
        :
        <Flex>
            <Row>
                <DateButtons/>
                {department === 'cadcam' ? <PercentOfWork/>: null}
            </Row>
            <TabBar
                items={department !== 'cadcam' ?
                    [
                        {title: 'отправки', body: <MainWindow/>},
                        {title: 'комментарии', body: <Comments department={department}/>},
                    ]
                    :
                    [
                        {title: 'не сдано', body: <CadcamInWork/>},
                        {title: 'сегодня', body: <CadcamToday/>},
                        {title: 'комментарии', body: <Comments department={department}/>},
                    ]
                }
            />
        </Flex>

}

function MainWindow() {
    const {route} = useContext(AppContext)
    switch (route.name) {
        case 'Laboratory':
            return <Laboratory/>
        case 'CadCam':
            return <View style={[fRow, flex, mr1]}>
                <Flex>
                    <CadcamInWork/>
                </Flex>
                <Flex style={ml1}>
                    <CadcamToday/>
                </Flex>
            </View>
        default:
            return <Foundry/>
    }
}


