import {TouchableOpacity} from "react-native";
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";
import {p1, primaryLight, secondary} from "../../const";
import FA from "../static/FA";


export default function ({visible=true, style, selected=false, onPress}) {
    if (!visible) return null
    return <TouchableOpacity style={[p1, style]} onPress={onPress}>
        {selected ?
            <FA icon={faCheckSquare} style={primaryLight}/>
            :
            <FA icon={faSquare} style={secondary}/>
        }
    </TouchableOpacity>
}