import {useContext, useEffect, useState} from "react";
import {AppContext, CurrentUserContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import Toggle from "../../../lib/fields/Toggle";
import {Row} from "../../../lib/markup/markup";
import {isAdministrations, isManager, isSuperuser, isWarehouse} from "../../../objects/user_methods";
import {hasGroup} from "../../../lib/sugar";

export default function () {
    const {currentUser, reload} = useContext(CurrentUserContext)
    const {GET, PATCH, user} = useContext(AppContext)

    const [groups, setGroups] = useState([])
    useEffect(async () => {
        let isMounted = true
        let data = await GET('main/groups')
        if (isMounted) setGroups(data)
        return () => isMounted = false
    }, [currentUser])
    if (!currentUser || !groups.length) return <Loading/>

    return <Row>
        {groups.map((group, key) =>
            <Toggle
                visible={user.is_superuser ? true : group.name === 'клиника' && isManager(user)}
                key={key}
                selected={hasGroup(currentUser.groups, group.name)}
                text={group.name}
                onPress={async selected => {
                    await PATCH(
                        `main/users/${currentUser.id}/relation`,
                        {
                            groups: group.id,
                            method: selected ? 'add' : 'remove'
                        }
                    )
                    reload()
                }}
            />)}
    </Row>
}
