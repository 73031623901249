import {arrayToDict, EDate} from "../lib/sugar";
import {api} from "../lib/api";
import storage from "../lib/storage";

export function hasGroup(user, name) {
    if (user && name) {
        const names = typeof name === 'string' ? [name] : name
        for (let group of user.groups) {
            if (names.includes(group.name)) return true
        }
    }
    return false
}

export function isLab(user) {
    return hasGroup(
        user,
        ['техники', 'менеджеры', 'руководители', 'склад', 'cadcam', 'бухгалтерия', 'курьеры', 'литейщики']
    )
}

export function isClinic(user) {
    return hasGroup(user,'клиника')
}

export function isManager(user) {
    return hasGroup(user,'менеджеры')
}

export function isDirector(user) {
    return hasGroup(user,'руководители')
}

export function defaultUrl(user) {
    if (!user) return 'Login'
    if (user.default_url) return user.default_url
    return 'UserProfile'
}

export function isSuperuser(user) {
    return user.is_superuser || user.id === 1
}

export function isTech(user) {
    return hasGroup(
        user,
        ['техники']
    )
}

export function isCadCam(user) {
    return hasGroup(
        user,
        ['cadcam']
    )
}

export function isFoundry(user) {
    return hasGroup(
        user,
        ['литейщики']
    )
}

export function isAdministrations(user) {
    if (user?.is_superuser) return true
    return hasGroup(
        user,
        ['менеджеры', 'руководители', 'склад', 'бухгалтерия']
    )
}

export function isMaster(user) {
    return hasGroup(
        user,
        ['ремонтники']
    )
}

export function isCourier(user) {
    return hasGroup(
        user,
        ['курьеры']
    )
}

export function isWarehouse(user) {
    return hasGroup(
        user,
        ['склад']
    )
}

export async function loadDataAsync(user) {
    let args = {}
    let date = new EDate()
    date.change({days: -31})
    let response = await api.get('main/holidays/closest', {date: date.isoDate()})
    args.holidays = response.status === 200 ? response.data : []

    response = await api.get('main/OrderStatuses')
    response = response.status === 200 ? response.data: []
    args.orderStatuses = {}
    for (let status of response) {
        args.orderStatuses[status.id] = status
        args.orderStatuses[status.name] = status
    }
    if (isLab(user)) {
        let response = await api.get('main/LabStatuses')
        response = response.status === 200? response.data:  []
        args.labStatuses = arrayToDict(response)

        response = await api.get('main/Clinics')
        args.clinics = response.status === 200 ? response.data : []

        response = await api.get('main/users/filter', {is_active: 1, groups__name: 'техники'})
        response = response.status === 200 ? response.data : []
        args.techs = arrayToDict(response)

        let whatsapp = await storage.get('WhatsApp')
        if (!whatsapp) {
            response = await api.get('main/WhatsApps/1')
            if (response.status === 200) {
                whatsapp = response.data
                await storage.set('whatsapp', whatsapp)
            }
        }
        args.whatsapp = whatsapp
    }
    return args
}
