import {Platform, Text, View, TouchableOpacity} from "react-native";
import {useState} from "react";
import {faCalendar, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import DateTimePicker from '@react-native-community/datetimepicker'
import {DatePickerModal} from 'react-native-paper-dates';
import BtnSecondary from "../buttons/BtnSecondary";
import {
    borderTable,
    center,
    field,
    flex,
    fRow,
    jCenter,
    ph1,
    stickLeft,
    stickRight
} from "../../const";
import {EDate, fixHeight, fixWidth, getBorderStyle, getMargin, getTextStyle} from "../sugar";
import {Col} from "../markup/markup";


export function Date({
                         date,
                         onChange,
                         displayMode = 'normal',
                         useButtons = true,
                         weekdayMode = 'short',
                         editable = true,
                         showYear = true,
                         isOpen = false,
                         style = {},
                         placeholder = null,
                         visible = true
                     }) {
    if (!visible) return null
    if (typeof date === 'string') date = new EDate(date)
    let empty = placeholder ? placeholder : `--.--`
    if (!placeholder && showYear) empty += '.----'

    return <View style={{
        ...fixHeight(style?.height ? style?.height: field.height),
        ...fixWidth(style?.width ? style.width: null),
        ...getMargin(style),
    }}>
        {editable ?
            <EnableDate
                isOpen={isOpen}
                displayMode={displayMode}
                style={style}
                useButtons={useButtons}
                weekdayMode={weekdayMode}
                date={date}
                empty={empty}
                showYear={showYear}
                onChange={onChange}
            />
            :
            <View style={[jCenter, center, flex]}>
                <DisabledDate
                    date={date}
                    empty={empty}
                    showYear={showYear}
                    weekdayMode={weekdayMode}
                    style={style}
                />
            </View>}
    </View>
}

function EnableDate({isOpen, displayMode, useButtons, weekdayMode, date, empty, showYear, onChange, style}) {
    const [open, setOpen] = useState(isOpen)

    function onChangeDate(newDate) {
        setOpen(false)
        if (newDate) onChange(new EDate(newDate))
    }

    return <View>
        <ButtonMode visible={displayMode === 'button'} style={style} onPress={() => setOpen(true)}/>
        <Col visible={displayMode === 'normal'} style={[flex, fRow]}>
            <ChangeDateBtn
                title={faChevronLeft}
                visible={useButtons}
                onChange={onChange}
                days={-1}
                date={date}
                style={stickRight}
            />

            <TouchableOpacity
                style={[
                    useButtons ? borderTable : getBorderStyle(style),
                    {
                        backgroundColor: field.backgroundColor,
                    },
                    jCenter,
                    center,
                    flex,
                ]}
                onPress={() => setOpen(true)}
            >
                <DisabledDate
                    style={style}
                    date={date}
                    empty={empty}
                    showYear={showYear}
                    weekdayMode={weekdayMode}
                />
            </TouchableOpacity>

            <ChangeDateBtn
                title={faChevronRight}
                visible={useButtons}
                onChange={onChange}
                days={1}
                date={date}
                style={stickLeft}
            />
        </Col>
        {open ?
            Platform.OS === 'web' ?
                <DatePickerModal
                    locale="ru"
                    mode="single"
                    visible={open}
                    onDismiss={() => setOpen(false)}
                    date={date}
                    onChange={params => {
                        onChangeDate(params.date)
                    }}
                    label={' '}
                    saveLabel=" "
                    onConfirm={() => setOpen(false)}/>
                :
                <DateTimePicker
                    testID="dateTimePicker"
                    value={date}
                    mode={'date'}
                    display="default"
                    onChange={(event, newDate) => {
                        onChangeDate(newDate)
                    }}
                />
            : null
        }
    </View>
}

function DisabledDate({weekdayMode, date, showYear, empty, style}) {
    return <Text style={[ph1, getTextStyle(style)]}>
        {date ? date.ruDate(showYear) : empty}
        {weekdayMode ?
            date ? " " + date.weekDay(weekdayMode === 'short') : '--'
            :
            null
        }
    </Text>
}

function ButtonMode({onPress, style, visible}) {
    if (!visible) return null
    return <BtnSecondary
        style={[{maxWidth: 45, minWidth: 45, width: 45}, style]}
        onPress={onPress}
        title={faCalendar}
    />
}

function ChangeDateBtn({visible, date, onChange, days, title, style}) {
    if (!visible) return null
    return <BtnSecondary
        style={style}
        title={title}
        onPress={() => {
            let newDate = date ? date.copy() : new EDate()
            newDate.change({days})
            onChange(newDate)
        }}
    />
}
