import {Col} from "../../../lib/markup/markup";
import {H1, H3} from "../../../lib/text/H";
import Loading from "../../../lib/static/Loading";
import {Text, View} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import {center, mt1} from "../../../const";


export default function ({clinics, onFinish, primaryClinicID}) {
    if (!clinics.length || !primaryClinicID) {
        onFinish()
        return null
    }
    const {GET, PATCH, POST, DELETE} = useContext(AppContext)
    const [state, setState] = useState({
        doctorsMoved: 0,
        patientsMoved: 0,
        clinicsDeleted: 0,
        patients: null,
        doctors: null,
        ready: false,
        done: false
    })

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        if (!state.ready) {
            const clinic_ides = []
            for (let clinic of clinics) {
                if (clinic.id !== primaryClinicID) clinic_ides.push(clinic.id)
            }
            const doctors = await GET('main/users/filter', {relations__clinic__id__in: clinic_ides})
            const patients = await GET('main/patients/filter', {clinic__id__in: clinic_ides})
            updateState({doctors, patients, ready: true})
        } else if (state.doctorsMoved < state.doctors.length) {
            const url = 'clinic/UserClinicRelations/'
            const doctorID = state.doctors[state.doctorsMoved].id
            const status = await GET(url + 'filter', {clinic: primaryClinicID, user:doctorID}, false)
            if (!status.length) await POST(url, {clinic: primaryClinicID, user: doctorID, status: 'do'})
            let clinic_ides = []
            for (let clinic of clinics) {
                if (clinic.id !== primaryClinicID) clinic_ides.push(clinic.id)
            }
            const relations = await GET(url + 'filter', {clinic__id__in: clinic_ides, user: doctorID})
            for (let relation of relations) await DELETE(url + relation.id)
            updateState({doctorsMoved: state.doctorsMoved + 1})
        } else if (state.patientsMoved < state.patients.length) {
            const url = 'main/patients/'
            const patient = state.patients[state.patientsMoved].id
            await PATCH(url + patient, {clinic: primaryClinicID})
            updateState({patientsMoved: state.patientsMoved + 1})
        } else if (state.clinicsDeleted < clinics.length){
            const clinic = clinics[state.clinicsDeleted].id
            if (clinic !== primaryClinicID) {
                await DELETE('main/clinics/' + clinic)
                updateState({
                    clinicsDeleted: state.clinicsDeleted + 1,
                })
            } else updateState({clinicsDeleted: state.clinicsDeleted + 1})
        } else updateState({done: true})
    }, [state.doctorsMoved, state.patientsMoved, state.clinicsDeleted, state.patients, state.doctors])
    if (!state.ready) return <Loading/>
    return <View style={[{width: 300}, center]}>
        <Col visible={!state.done}>
            <H1>НИЧЕГО НЕ ДЕЛАЙТЕ!!!</H1>
            <H3>объединение...</H3>
            <Loading/>
        </Col>
        <H3 visible={state.done} style={mt1}>готово</H3>
        <Text>переношу врачей: {`${state.doctorsMoved}/${state.doctors.length}`}</Text>
        <Text>переношу пациентов: {`${state.patientsMoved}/${state.patients.length}`}</Text>
        <Text>удаляю клиники: {`${state.clinicsDeleted ? state.clinicsDeleted-1: 0}/${clinics.length-1}`}</Text>
        <BtnPrimary visible={state.done} title={'OK'} onPress={onFinish}/>
    </View>
}
