import {bgPrimaryLight, bgWhite, borderTable, field, fRow, h100, jCenter, white} from "../../const";
import {Text, View} from "react-native";
import React from "react";

export default function ({title, style, value}) {
    const fieldStyle = [h100, jCenter, {paddingHorizontal: field.paddingHorizontal, minWidth: 40}]
    return <View style={[fRow, borderTable, {height: field.height, minWidth: field.minWidth}, style]}>
        <View style={[bgPrimaryLight, fieldStyle]}>
            <Text style={[white]}>{title}:</Text>
        </View>
        <View style={[bgWhite, fieldStyle]}>
            <Text>{value}</Text>
        </View>
    </View>
}