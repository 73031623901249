import React, {useState} from 'react';
import {
    bgPrimaryLight,
    bgSecondaryLight,
    bgWhite,
    borderTable,
    flex,
    fRow, jCenter, m1,
    ph1, stick, stickLeft, stickRight,
    white
} from "../../const";
import {View, Text, Platform, Dimensions} from "react-native";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import BtnSecondary from "../buttons/BtnSecondary";
import BtnPrimary from "../buttons/BtnPrimary";

export default function ({
                             head,
                             body,
                             widths = [],
                             visible = true,
                             maxWidth = Dimensions.get('window').width - m1.margin * 2,
                             rowHeight = 30,
                             maxRows = Platform.OS === 'android' ? 30 : 50,
                             style = {},
                         }) {
    if (!visible || !body) return null
    const pageTotal = body.length > maxRows ? Math.ceil(body.length / maxRows) : 1
    const [pageIndex, setPageIndex] = useState(0)
    let cellWidths = new Array(widths.length)
    let flexIndexes = []
    let flexTotal = 0
    let definedWidth = 0
    for (let n = 0; n < widths.length; n++) {
        if (typeof widths[n] === 'number') {
            cellWidths[n] = {width: widths[n], minWidth: widths[n], maxWidth: widths[n]}
            definedWidth += widths[n]
        } else {
            cellWidths[n] = widths[n].flex
            flexIndexes.push(n)
            flexTotal += widths[n].flex
        }
    }
    let limit = maxWidth - definedWidth
    if (limit < 0) limit = 0
    for (let i of flexIndexes) {
        const width = Math.round(cellWidths[i] * limit / flexTotal)
        cellWidths[i] = {width, minWidth: width, maxWidth: width}
    }

    return <View style={style}>
        {head ? <Row
            data={head}
            style={{...bgPrimaryLight, ...white, ...borderTable, height: rowHeight}}
            cellWidths={cellWidths}
        />: null}
        {
            body.slice(pageIndex, pageIndex * maxRows + maxRows).map((dataRow, key) => {
                if (dataRow === null || dataRow === undefined) return null
                const bg = key % 2 ? bgWhite : bgSecondaryLight
                return <Row
                    key={key}
                    data={dataRow}
                    style={{...bgPrimaryLight, ...white, ...bg, ...borderTable, height: rowHeight}}
                    cellWidths={cellWidths}
                />
            })
        }
        {pageTotal > 1 && <Navigation pageTotal={pageTotal} pageIndex={pageIndex} setPageIndex={setPageIndex}/>}
    </View>
}

function Navigation({pageTotal, pageIndex, setPageIndex}) {
    let pageNumbers = []
    for (let n = 0; n < pageTotal; n++) pageNumbers.push(n)
    return <View style={fRow}>
        <BtnSecondary
            title={faChevronLeft}
            style={[stickRight, {borderTopLeftRadius: 0}]}
            onPress={() => {
                if (pageIndex) setPageIndex(pageIndex - 1)
            }}
        />
        {pageNumbers.map((_, number) => {
            if (pageIndex === number) return <BtnPrimary key={number} style={stick} title={number + 1}/>
            return <BtnSecondary key={number} style={stick} title={number + 1} onPress={() => setPageIndex(number)}/>
        })}
        <BtnSecondary
            style={[stickLeft, {borderTopRightRadius: 0}]}
            title={faChevronRight}
            onPress={() => {
                if (pageIndex < pageTotal - 1) setPageIndex(pageIndex + 1)
            }}
        />
    </View>
}

function Row({data, style, cellWidths}) {
    return <View style={fRow}>
        {data.map((col, key) =>
            <Cell key={key} style={[style, cellWidths[key]]}>{col}</Cell>)}
    </View>
}

function Cell({children, style}) {
    return <View style={[jCenter, style, {minWidth: 40}]}>
        {['string', 'number'].includes(typeof children) ?
            <View style={[flex, ph1, jCenter]}>
                <Text numberOfLines={1} >{children}</Text>
            </View>
            :
            children
        }
    </View>
}
