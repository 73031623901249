import {useContext} from "react";
import {AppContext, OrderContext, PatientContext} from "../../contexts";
import {esc, ml1, ml3, mt1, mt2, primaryLight, stick, stickLeft, stickRight} from "../../../const";
import {shades, shoulders, teethShapes} from "../../../labConsts";
import {View, Text} from "react-native";
import Select from "../../../lib/fields/Select";
import {Row} from "../../../lib/markup/markup";
import Toggle from "../../../lib/fields/Toggle";
import {isAdministrations, isClinic, isManager} from "../../../objects/user_methods";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import axios from "axios";
import {fixWidth, lessTablet} from "../../../lib/sugar";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";

export default function () {
    const {state, saveOrder, user} = useContext(OrderContext)
    const {patient} = useContext(PatientContext)
    const {POST, navigation, LOG} = useContext(AppContext)
    const fields = [
        {field: 'use_beam', text: 'использовать балку'},
        {field: 'gum_enlargement', text: 'расширение десны'},
        {field: 'agreement', text: 'согласование'},
        {field: 'facing', text: 'вестибулярная облицовка'},
    ]

    const selectors = [
        {field: 'shade', style: {width: 90, ...stickRight}, items: shades, placeholder: 'цвет'},
        {field: 'shoulder', style: {width: 120, ...stick}, items: shoulders, placeholder: 'ширина уступа'},
        {field: 'teeth_shape', style: {width: 140, ...stickLeft}, items: teethShapes, placeholder: 'форма зубов'},
    ]
    const editable = isAdministrations(user) || isClinic(user)

    async function request(requestText, field) {
        const body = `${user.last_name} ${user.first_name}: требуется ${requestText} пациент ${patient.last_name}`
        await POST('main/notifications/to_group', {
            group: 'менеджеры',
            patient: patient.id,
            body
        })
        saveOrder(field, 1)
        LOG(body, patient.id)
    }

    const btnWidth = fixWidth(130)
    return <View>
        <Row>
            {fields.map((param, key) => {
                return <Toggle
                    editable={editable}
                    key={key}
                    {...param}
                    selected={state.order[param.field]}
                    onPress={value => saveOrder(param.field, value)}
                />
            })}
        </Row>
        <Row style={mt2}>
            <BtnPrimary
                style={btnWidth}
                visible={state.order.shade === '--' && !state.order.shade_requested && !isManager(user)}
                title={'запросить цвет'}
                onPress={() => request('цвет', 'shade_requested')}
            />
            {state.order.shade === '--' && state.order.shade_requested && !isManager(user) ?
                <Text style={primaryLight}>цвет запрошен</Text> : null}

            <BtnPrimary
                style={[btnWidth, !!state.order.shade && !state.order.shade_requested ? ml1: null]}
                visible={state.order.photo_requested === 0 && !isManager(user)}
                title={'запросить фото'}
                onPress={() => request('фото', 'photo_requested')}
            />
            <BtnSecondary
                style={[btnWidth, lessTablet() ? mt1: ml1]}
                visible={state.order.photo_requested === 0 && isManager(user)}
                title={'фото имеется'}
                onPress={() => saveOrder('photo_requested', 2)}
            />
            {state.order.photo_requested === 1 && !isManager(user) ?
                <Text style={[primaryLight, ml3]}>фото запрошено</Text> : null}

            <BtnPrimary
                visible={isAdministrations(user)}
                style={[btnWidth, ml1, lessTablet() ? mt1: null]}
                title={'чат с доктором'}
                onPress={() => navigation.navigate('WhatsApp', {phone: patient.doctor.phone})}
            />
        </Row>
        <Row style={mt2}>

            {selectors.map((params, key) =>
                <Select
                    visible={editable && state.order[params.field]}
                    editable={editable}
                    {...params}
                    key={key}
                    onChange={value => {
                        saveOrder(params.field, value)
                    }}
                    selected={state.order[params.field]}
                />
            )}
        </Row>
    </View>
}
