import {ScrollView, Text, TouchableOpacity} from "react-native";
import {bold, danger, esc, fRow, ml1, p1, ph1, primary, secondary} from "../../../const";
import {useContext} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {EDate} from "../../../lib/sugar";
import {isWarehouse} from "../../../objects/user_methods";

export default function (props) {
    const {GET, setAlert, DELETE, PATCH, user} = useContext(AppContext)

    if (!props.ready) return <Loading/>
    return <ScrollView style={[p1]}>
        {props.transactions.map((t, key) => {
            return <TouchableOpacity
                key={key}
                style={[p1, fRow]}
                onPress={() => {
                    let date = new EDate()
                    date.change({hours: 24})
                    if (!isWarehouse(user) || new EDate(t.datetime) > date) return
                    setAlert({
                        title: 'отменить транзакцию?',
                        buttons: [
                            {
                                title: 'отменить',
                                style: 'danger',
                                onPress: async () => {
                                    let table = await GET('lab/tables/' + t.table)
                                    let count = +table.struct.cells[t.row][t.col]
                                    if (t.tech) count += +t.count
                                    else count -= +t.count
                                    table.struct.cells[t.row][t.col] = count
                                    await PATCH('lab/tables/' + t.table, {struct: table.struct})
                                    await DELETE('lab/transactions/' + t.id)
                                    props.refresh()
                                }
                            },
                            esc
                        ]
                    })
                }}
            >
                <Text style={secondary}>{new EDate(t.datetime).ruDateTime(false)}</Text>
                <Text style={ph1}>{t.table_name}</Text>
                <Text>{t.row_name}</Text>
                <Text style={ml1}>{t.col_name}</Text>
                <Text style={[ph1, t.tech_name ? danger : primary]}>
                    {t.tech_name ? t.tech_name : t.vendor_name}
                </Text>
                <Text style={[bold, t.tech_name ? danger : primary]}>{t.count}</Text>
            </TouchableOpacity>
        })}
    </ScrollView>
}
