import {ScrollView} from "react-native";
import {mt1, fRow, esc, field, ml1, ml3} from "../../../const";
import {AppContext} from "../../contexts";
import {Flex, Row} from "../../../lib/markup/markup";
import {useContext, useEffect, useState} from "react";
import Loading from "../../../lib/static/Loading";
import {isDesktop} from "../../../lib/sugar";
import ClinicDetail from "./ClinicDetail";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import Joining from "./Joining";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";

export default function ({state, updateState}) {
    if (state.mode !== 'join') return null
    const {setAlert} = useContext(AppContext)
    const [thisState, setThisState] = useState({
        ready: false,
        clinics: [],
        primaryClinicID: 0,
        joining: false
    })
    const {GET} = useContext(AppContext)

    function updateThisState(args) {
        setThisState({...thisState, ...args})
    }

    useEffect(async () => {
        const clinics = await GET('main/clinics/filter', {id__in: state.selected})
        updateThisState({ready: true, clinics})
    }, [])

    if (!thisState.ready) return <Loading/>

    if (thisState.joining) return <Joining
        clinics={thisState.clinics}
        primaryClinicID={thisState.primaryClinicID}
        onFinish={() => {
            updateThisState({joining: false})
            updateState({mode: 'filter', selected: []})
        }}
    />

    if (!thisState.joining) return <Flex>
        <Row>
        <BtnSecondary
            title={'назад'}
            style={[ml1, {width: field.width}]}
            onPress={() => updateState({mode: 'filter'})}
        />
        <BtnPrimary
            style={[{width: field.width}, ml3]}
            visible={thisState.primaryClinicID && state.clinics.length > 1}
            title={'объединить'}
            onPress={() => {
                if (!thisState.primaryClinicID) {
                    setAlert({
                        title: 'выберите основную клинику'
                    })
                    return
                }
                setAlert({
                    title: 'вы уверены?',
                    buttons: [
                        {
                            title: 'да, перенести',
                            style: 'danger',
                            onPress: () => updateThisState({joining: true})
                        },
                        esc
                    ]
                })
            }}
        />
        </Row>

        <ScrollView style={[mt1, isDesktop() ? fRow : null]} horizontal={isDesktop()}>
            {thisState.clinics.map((clinic, key) =>
                <ClinicDetail
                    selected={state.selected}
                    {...clinic}
                    key={key}
                    setPrimary={() => updateThisState({primaryClinicID: clinic.id})}
                    isPrimary={thisState.primaryClinicID === clinic.id}
                />)}
        </ScrollView>
    </Flex>
}

