import {useContext, useEffect, useState} from "react";
import {CouriersContext} from "../../contexts";
import Task from "./Task";
import {bold, borderTable, flex, mb1, mh1, p1, primary} from "../../../const";
import {View} from "react-native";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import {Row} from "../../../lib/markup/markup";
import Btn from "../../../lib/buttons/Btn";


export default function () {
    const {tasks, updateState, deliveryNumber} = useContext(CouriersContext)
    const [orderBy, setOrderBy] = useState('courier_time')
    // if (orderBy === 'direction') {
    //     tasks.sort((a, b) => {
    //         if (a.direction?.toLowerCase() > b.direction?.toLowerCase()) return 1
    //         return -1
    //     })
    // } else {
    //     tasks.sort((a, b) => {
    //         if (!a.courier_time) return -1
    //         if (!b.courier_time) return 1
    //         a = a.courier_time.split(':')
    //         b = b.courier_time.split(':')
    //         if (Number(a[0])*60 + +Number(a[1]) > Number(b[0])*60 + Number(b[1])) return 1
    //         return -1
    //     })
    // }

    return <View
        style={[flex, p1, {paddingVertical: 30}, borderTable, {borderTopColor: 'transparent'}]}
    >
        <BtnSecondary
            style={mb1}
            onPress={async () => {
                for (let i = 0; i < tasks.length; i++) {
                    if (tasks[i].selected) {
                        await tasks[i].setCourier(null)
                        await tasks[i].edit('confirmed', 0)
                    }
                }
                updateState({tasks})
            }}
            title={'сброс выделенных'}
        />
        {/*<Row style={mh1}>*/}
        {/*    <Btn style={[flex, primary, bold]} title={'по времени'} onPress={() => setOrderBy('direction')}/>*/}
        {/*    <Btn style={[flex, primary, bold]} title={'по алфавиту'} onPress={() => setOrderBy('courier_time')}/>*/}
        {/*</Row>*/}
        {tasks.map((task, key) => {
            if (task.deliveryNumber !== deliveryNumber || task.courier) return null
            return <Task key={key} index={key}/>
        })}
    </View>
}
