import {isDesktop} from "../../sugar";
import {Flex, FlexRow} from "../../markup/markup";
import {Dimensions, View} from "react-native";
import {useEffect, useState} from "react";
import Contacts from "./Contacts";
import Loading from "../../static/Loading";
import Messages from "./Messages";
import {mr1} from "../../../const";

export default function ({
                             showContacts = true,
                             createMessage,
                             deleteMessage,
                             chats,
                             currentChat = chats.length === 1 ? 0 : -1,
                            show='contacts',
                             fastAnswers=null
                         }) {
    const [state, setState] = useState({uploading: false})

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        updateState({
            currentChat,
            show,
            ready: true
        })
    }, [])

    if (!state.ready) return <Loading/>
    const messages_props = {
        createMessage: createMessage,
        deleteMessage: deleteMessage,
        messages: state.currentChat !== -1 ? chats[state.currentChat].messages : null,
        chatName: state.currentChat !== -1 ? chats[state.currentChat].chatName : null,
        chatId: state.currentChat !== -1 ? chats[state.currentChat].chatId : null,
        goToContacts: () => updateState({currentChat: -1, show: 'contacts'}),
        fastAnswers
    }

    const contacts_props = {
        chats: chats,
        currentChat: state.currentChat,
        setCurrentChat: currentChat => updateState({currentChat, show: 'messages'})
    }

    function getWidth() {
        let width = Dimensions.get('window').width - 430
        if (width > 800) width = 800
        return width
    }

    if (!showContacts) {
        return <Flex>
            <Messages {...messages_props}/>
        </Flex>
    }
    if (isDesktop()) return <FlexRow>
        {showContacts && <View style={{width: 400, ...mr1}}>
            <Contacts {...contacts_props}/>
        </View>}
        <View style={{width: getWidth()}}>
            <Messages {...messages_props}/>
        </View>
    </FlexRow>

    return <Flex>
        <Flex visible={showContacts && state.show === 'contacts'}>
            <Contacts {...contacts_props}/>
        </Flex>
        <Flex visible={state.show === 'messages'}>
            <Messages {...messages_props}/>
        </Flex>
    </Flex>
}
