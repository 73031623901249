import React, {useContext, useEffect, useState} from "react";
import {AppContext, PatientContext} from "../../contexts";
import {Platform} from "react-native";
import {mr1, mt1, ph1} from "../../../const";
import TabBar from "../../../lib/markup/TabBar";
import {isAdministrations, isClinic, isManager} from "../../../objects/user_methods";
import Order from "./Order";
import {Flex3} from "../../../lib/markup/markup";
import {TextInput} from "react-native";

export default function () {
    const {patient} = useContext(PatientContext)
    const [orders, setOrders] = useState([])
    const {user, setAlert, GET, POST, isFocused, PATCH} = useContext(AppContext)

    function convertToTab(index, obj) {

        return {
            title:  'id ' + obj.id,
            body: <Order
                order={obj}
                patient_id={patient.id}
                remove={loadOrders}
            />
        }
    }

    useEffect(async () => {
        await loadOrders()
    }, [isFocused])

    async function loadOrders() {
        let orders = await GET(`main/orders/filter`, {patient_id: patient.id, is_removed: 0})
        if (orders.length) await setOrders(orders)
        else {
            const newOrder = await POST('main/orders', {patient: patient.id, status: isManager(user) ? 5: 1})
            setOrders([newOrder])
        }
    }

    return <Flex3 style={mr1}>
        <TabBar
            selected={-1}
            style={mt1}
            items={orders.map((order, index) => convertToTab(index, order))}
            plus={(isManager(user) || isClinic(user)) ? async () => {
                setAlert({
                    title: 'добавить наряд?',
                    buttons: [
                        {
                            title: 'добавить',
                            style: 'primary',
                            onPress: async () => {
                                await POST(
                                    'main/orders',
                                    {
                                        patient: patient.id,
                                        status: isManager(user) ? 3 : 0
                                    }
                                )
                                await loadOrders()
                            }
                        },
                        {
                            title: 'отмена'
                        }
                    ]
                })
            } : undefined}
        />
    </Flex3>
}
