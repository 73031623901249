import React, {useState} from "react";
import {View} from "react-native";
import {field, flex, jRow, stickLeft, stickRight} from "../../const";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import TextField from "./TextField";
import BtnSecondary from "../buttons/BtnSecondary";


export default function ({textStyle, btnStyle, onPress, placeholder, style = {}}) {
    const [text, setText] = useState('')

    function find() {
        if (text.length > 2) onPress(text.trim())
        setText('')
    }

    return <View style={[jRow, {width: field.width}, style]}>
        <TextField
            placeholder={placeholder || 'поиск'}
            style={[field, stickRight, flex, textStyle]}
            onChange={setText}
            value={text}
            onKey={{
                Enter: find
            }}
            onBlurVal={''}
        />
        <BtnSecondary
            style={[stickLeft, btnStyle]}
            title={faSearch}
            onPress={find}
        />
    </View>
}
