import {useState} from "react";
import Loading from "../../static/Loading";
import {Flex} from "../../markup/markup";
import {ScrollView, TextInput, View} from "react-native";
import {bgWhite, border, field, flex, mt1, p1, stickBottom} from "../../../const";
import Title from "./Title";


export default function ({chats, currentChat, setCurrentChat}) {
    const [filter, setFilter] = useState('')
    if (!chats) return <Loading/>
    return <Flex style={mt1}>
        <View style={{height: field.height, ...bgWhite}}>
            <TextInput style={[stickBottom, flex, border, p1]} placeholder={'поиск...'} onChangeText={setFilter}/>
        </View>
        <Flex style={{borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#000'}}>
            <ScrollView>
                {chats.map((chat, key) => {
                    if (filter && !chat?.chatName?.toLowerCase().includes(filter?.toLowerCase())) return null
                    return <Title
                        key={key}
                        {...chat}
                        setCurrentChat={() => setCurrentChat(key)}
                        isActive={currentChat === key}
                    />
                })}
            </ScrollView>
        </Flex>
    </Flex>
}
