import {useContext} from "react";
import {AppContext, ClinicContext} from "../../contexts";
import {Text, View} from "react-native";
import {primary} from "../../../const";
import {isPhoneCorrect} from "../../../lib/sugar";
import Creator from "../../../lib/fields/Creator";

export default function InviteUser() {
    const {setAlert, POST, GET, reload} = useContext(AppContext)
    const {clinic} = useContext(ClinicContext)

    return <View>
        <Text style={primary}>пригласить:</Text>
        <Creator
            placeholder={'телефон'}
            onPress={async value => {
                const phone = isPhoneCorrect(value)
                if (!phone) {
                    setAlert({body: 'неверный номер телефона'})
                    return
                }
                let targetUser = await GET('main/users/filter', {phone})

                if (!targetUser.length) {
                    setAlert({body: 'пользователь не найден'})
                    return
                }
                targetUser = targetUser[0]
                const relation = await GET(`clinic/UserClinicRelation/filter`, {user: targetUser.id, clinic: clinic.id})
                if (relation.length) {
                    if (relation.status === 'in') {
                        setAlert({body: 'приглашение уже отправлено ранее'})
                        return
                    }
                    setAlert({body: 'Пользователь уже состоит в Вашей клинике'})
                    return
                }
                await POST(`clinic/UserClinicRelation/`, {clinic: clinic.id, user: targetUser.id})
                reload()
            }}
        />
    </View>
}