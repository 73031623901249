import {useContext, useEffect, useState} from "react";
import {View, Text, Pressable} from "react-native";
import {bold, danger, field, flex, fRow, mh1, ml1, mt1, ph1, primary, primaryLight} from "../../const";
import {AppContext} from "../contexts";
import {Col, Flex, Row} from "../../lib/markup/markup";
import Table from "../../lib/markup/Table";
import {Date} from "../../lib/fields/Date";
import {ScrollView} from "react-native";
import Toggle from "../../lib/fields/Toggle";
import TextField from "../../lib/fields/TextField";
import {AutocorrectField} from "../../lib/fields/AutocorrectField";
import {isDesktop} from "../../lib/sugar";
import BtnPrimary from "../../lib/buttons/BtnPrimary";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import cadcam from "../lab/manager/Cadcam";


export default function () {
    const {GET, navigation, route} = useContext(AppContext)
    const [state, setState] = useState({
        status: 'ready',
        data: [],
        visited: [],
        cadcam: route?.params?.cadcam,
        clinic: null,
        doctor: null,
        patient: ''
    })

    useEffect(() => {
        if (state.clinic || state.doctor || state.patient) find()
    }, [state.clinic, state.doctor])
    function goToPatient(patient_id) {
        updateState({visited: state.visited.concat(patient_id)})
        navigation.navigate('Patient', {patient_id})
    }

    async function find() {
        let data = []
        let args = {}
        if (state.patient) args.patient = state.patient
        if (state.clinic) args.clinic = state.clinic.id
        if (state.doctor) args.doctor = state.doctor.id
        if (Object.keys(args).length) {
            data = await GET('main/patients/find', args)
        }
        let status = data.length ? 'found' : 'notFound'
        updateState({status, data})
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    let found = []
    for (let row of state.data) {
        if (!cadcam && !row.date) continue
        const visited = state.visited.includes(row.id) ? [primaryLight, bold] : null
        let r = isDesktop() ? [<Pressable onPress={() => goToPatient(row.id)}>
                <Text numberOfLines={1} style={[ph1, visited]}>{row.id}</Text>
            </Pressable>] : []
        r = r.concat([
            <Pressable onPress={() => goToPatient(row.id)}>
                <Date editable={false} style={[ph1, visited]} date={row.date} weekdayMode={false}/>
            </Pressable>,
            <Pressable onPress={() => goToPatient(row.id)}>
                <Text numberOfLines={1} style={[ph1, visited]}>{row.clinic}</Text>
            </Pressable>,
            <Pressable onPress={() => goToPatient(row.id)}>
                <Text numberOfLines={1} style={[ph1, visited]}>{row.doctor}</Text>
            </Pressable>,
            <Pressable onPress={() => goToPatient(row.id)}>
                <Text numberOfLines={1} style={[ph1, visited]}>{row.patient}</Text>
            </Pressable>,
            <Pressable onPress={() => goToPatient(row.id)}>
                <Text numberOfLines={1} style={[ph1, visited]}>{row.techs}</Text>
            </Pressable>,
        ])
        found.push(r)
    }

    return <Flex>
        <View style={isDesktop() ? fRow : null}>
            <Toggle
                visible={isDesktop()}
                selected={state.cadcam}
                text={'CAD/CAM'}
                onPress={() => {
                    updateState({cadcam: !state.cadcam})
                }}
            />
            <AutocorrectField
                placeholder={'клиника'}
                object={state.clinic}
                findField={'name'}
                find={async text => await GET('main/clinics/filter', {name__istartswith: text})}
                onSubmit={clinic => updateState({clinic})}
                onBlurSubmit={true}
            />
            <View style={isDesktop() ? mh1 : null}>
                <AutocorrectField
                    placeholder={'врач'}
                    object={state.doctor}
                    findField={'last_name'}
                    find={async text => await GET('main/users/filter', {last_name__istartswith: text})}
                    onBlurSubmit={true}
                    onSubmit={doctor => updateState({doctor})}
                />
            </View>
            <TextField
                style={field}
                placeholder={'пациент'}
                value={state.patient}
                onChange={patient => {
                    updateState({patient})
                }}
                onKey={{'Enter': find}}
            />
            <Toggle
                visible={!isDesktop()}
                selected={state.cadcam}
                text={'CAD/CAM'}
                onPress={() => {
                    updateState({cadcam: !state.cadcam})
                }}
            />
            <BtnPrimary style={isDesktop() ? {marginLeft: 2}: {width: field.width}} title={faSearch} onPress={find}/>
        </View>
        <Row visible={state.status === 'notFound'}>
            <Text style={[bold, danger, ml1]}>не найдено</Text>
        </Row>
        <Col visible={state.status === 'ready'}>
            <Text style={[bold, primary, ml1]}>все поля заполнять не обязательно</Text>
            <Text style={[bold, primary, ml1]}>фамилию пациента можно писать не полностью</Text>
            <Text style={[bold, primary, ml1]}>регистр не важен</Text>
        </Col>
        <ScrollView style={mt1} horizontal={true}>
            <ScrollView>
                <Table
                    maxWidth={isDesktop() ? 800 : 500}
                    style={mt1}
                    visible={found.length}
                    head={isDesktop() ? [
                        'id',
                        'дата',
                        'клиника',
                        'врач',
                        'пациент',
                        'техники'
                    ]
                        :
                        [
                        'дата',
                        'клиника',
                        'врач',
                        'пациент',
                        'техники'
                    ]}
                    widths={isDesktop() ?
                        [
                            55,
                            90,
                            flex,
                            flex,
                            flex,
                            100
                        ] :
                        [
                            90,
                            flex,
                            flex,
                            flex,
                            100
                        ]
                    }
                    body={found}
                />
            </ScrollView>
        </ScrollView>
    </Flex>
}
