import {useContext, useEffect, useState} from "react";
import {EDate} from "../../lib/sugar";
import {View} from "react-native";
import {AppContext} from "../contexts";
import {faBan, faCheck} from "@fortawesome/free-solid-svg-icons";
import {flex, jCenter, m1, mt1, secondary, success} from "../../const";
import Loading from "../../lib/static/Loading";
import {Col, Flex, PressCol, Row} from "../../lib/markup/markup";
import Table from "../../lib/markup/Table";
import {Date} from "../../lib/fields/Date";
import {isMaster} from "../../objects/user_methods";
import FA from "../../lib/static/FA";
import Creator from "../../lib/fields/Creator";


export default function () {
    const [tasks, setTasks] = useState([])
    const {GET, POST, PATCH, user, DELETE} = useContext(AppContext)
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
        setLoading(true)
        if (isMaster(user)) setTasks(await GET('lab/MasterTasks'))
        else setTasks(await GET('lab/MasterTasks/filter', {user: user.id}))
        setLoading(false)
    }, [])

    async function finish(task_id) {
        const finished_at = new EDate()
        await PATCH(`lab/MasterTasks/${task_id}`, {finished_at: finished_at.isoDate()})
        let newTasks = tasks.slice()
        for (let n=0; n<newTasks.length; n++) {
            newTasks[n].finished_at = finished_at
            break
        }
        setTasks(newTasks)
    }

    async function cancel(task_id) {
        await DELETE(`lab/MasterTasks/${task_id}`)
        let newTasks = tasks.slice()
        for (let n=0; n<newTasks.length; n++) {
            newTasks[n].splice(n, 1)
        }
        setTasks(newTasks)
    }

    if (loading) return <Loading/>
    return <Flex>
        <Row>
            <Creator
                style={flex}
                placeholder={'что сломалось?'}
                onPress={async text => {
                    const task = await POST('lab/MasterTasks', {text, user: user.id})
                    setTasks([task].concat(tasks))
                }}
            />
        </Row>

        <Table
            widths={[50, 50, flex, 30]}
            titles={['создано', 'завершенно', 'текст', '']}
            style={mt1}
            body={
                tasks.map((task, key) => {
                    return [
                                <Date
                                    style={m1}
                                    editable={false}
                                    date={new EDate(task.created_at)}
                                    showYear={false}
                                />,
                                <Date
                                    style={m1}
                                    editable={false}
                                    date={new EDate(task.finished_at)}
                                    visible={task.finished_at}
                                    showYear={false}
                                />,
                        task.text,
                        <View>
                            <PressCol
                                style={[m1, jCenter]}
                                visible={!isMaster(user) && !task.finished_at}
                                onPress={() => cancel(task.id)}
                            >
                                <FA icon={faBan}/>
                            </PressCol>
                            <PressCol
                                style={[m1, jCenter]}
                                visible={isMaster(user) && !task.finished_at}
                                onPress={() => finish(task.id)}
                            >
                                <FA icon={faCheck} style={secondary}/>
                            </PressCol>
                            <Col
                                style={[m1, jCenter]}
                                visible={task.finished_at}
                            >
                                <FA icon={faCheck} style={success}/>
                            </Col>
                        </View>
                    ]
                })
            }
        />
    </Flex>
}
