import {Text, ScrollView, Platform} from "react-native";
import {useContext, useEffect, useState} from "react";
import {
    bgDanger, bgSecondary,
    borderTable,
    center,
    ml1,
    mr1,
    mt1,
    mt5,
    p2,
    primary
} from "../../const";
import {isDesktop, isPhoneCorrect, screenSize} from "../../lib/sugar";
import {Flex, Flex3, PressRow, Row} from "../../lib/markup/markup";
import TextField from "../../lib/fields/TextField";
import Select from "../../lib/fields/Select";
import {H3} from "../../lib/text/H";
import FIO from "../../lib/text/FIO";
import Phone from "../../lib/text/Phone";
import {AppContext} from "../contexts";
import NumberField from "../../lib/fields/NumberField";
import Creator from "../../lib/fields/Creator";


export default function () {
    const [state, setState] = useState({
        ready: false,
        groups: [],
        users: [],
        currentGroup: null,
        filter: '',
        phoneFilter: '',
        codFilter: ''
    })
    const {POST, navigation, GET, isFocused} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }
    useEffect(async () => {
        let args = {}
        if (!state.groups.length) {
            args.groups = await GET('main/groups')
        }
        if (state.filter.length > 2 || state.currentGroup || state.phoneFilter.length > 3 || state.codFilter) {
            let sendArgs = {}
            if (state.currentGroup) sendArgs.groups = state.currentGroup
            if (state.filter) sendArgs.last_name__istartswith = state.filter
            if (state.phoneFilter) sendArgs.phone__istartswith = state.phoneFilter
            if (state.codFilter) sendArgs.code__istartswith = state.codFilter
            args.users = await GET(
                'main/users/filter', sendArgs)
        }
        updateState(args)
    }, [state.filter, state.currentGroup, isFocused, state.phoneFilter, state.codFilter])

    return <Flex>
            <Text>добавить пользователя:</Text>
            <Creator
                placeholder={'номер телефона'}
                onPress={async phone => {
                    phone = isPhoneCorrect(phone)
                    if (!phone) {
                        updateState({error: 'некорректный номер'})
                        return
                    }
                    let user = await GET('main/users/filter', {phone})
                    if (user.length) user = user[0]
                    else user = await POST('main/users', {phone, is_active: 1})
                    navigation.navigate('UserProfile', {user_id: user.id})
                }}
            />
        <Text style={mt1}>поиск:</Text>
        <Row>
            <NumberField
                placeholder={'телефон...'}
                value={state.phoneFilter}
                onChange={phoneFilter => {
                    if (phoneFilter[0] === '8') phoneFilter = '7' + phoneFilter.slice(1)
                    else if (phoneFilter[0] !== '7') phoneFilter = '7' + phoneFilter.slice(1)
                    updateState({phoneFilter})
                }}
            />
            <TextField
                value={state.codFilter}
                style={screenSize() === 'desktop' ? ml1: mt1}
                placeholder={'код...'}
                onChange={codFilter => updateState({codFilter})}
            />
            <TextField
                value={state.filter}
                style={screenSize() === 'desktop' ? ml1: mt1}
                placeholder={'фамилия...'}
                onChange={filter => updateState({filter})}
            />
            <Select
                selected={state.currentGroup}
                canBeNull={true}
                style={screenSize() === 'desktop' ? ml1: mt1}
                items={state.groups}
                placeholder={'группа'}
                onChange={currentGroup => {
                    updateState({currentGroup})
                }}
            />
        </Row>
        <Row visible={Platform.OS === 'android'} style={mt5}/>
        <Flex style={mt1}>
        {state.users.length ?
            <ScrollView>
                {
                    state.users.map((user, key) =>
                        <User key={key} user={user} navigation={navigation}/>)
                }
            </ScrollView>
            : null}
        </Flex>
    </Flex>
}

function User({user, navigation}) {
    return <PressRow
        style={[
            center,
            borderTable,
            p2,
            {maxWidth: screenSize() === 'desktop' ? 600 : 400},
            user.is_active ? null : bgSecondary
        ]}
        onPress={() => navigation.navigate('UserProfile', {user_id: user.id})}
    >
        <Flex><Phone style={mr1}>{user.phone}</Phone></Flex>

            {isDesktop() ?
                <Flex3>
                    <H3>{user.last_name} {user.first_name} {user.middle_name}</H3>
                </Flex3>
                :
                <Flex>
                    <FIO style={primary} obj={user}/>
                </Flex>}
    </PressRow>
}
