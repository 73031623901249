import BtnSecondary from "./BtnSecondary";
import BtnPrimary from "./BtnPrimary";

export default function ({
                              title = null,
                              onPress=null,
                              isActive=false,
                              wrpStyle = {},
                              style = {},
                              textStyle = {},
                              bgStyle = {},
                              visible = true
                          }) {
    if (!visible) return null
    if (isActive) return <BtnPrimary
        title={title}
        onPress={onPress}
        wrpStyle={wrpStyle}
        textStyle={textStyle}
        bgStyle={bgStyle}
        style={style}
    />
    return <BtnSecondary
        title={title}
        onPress={onPress}
        wrpStyle={wrpStyle}
        textStyle={textStyle}
        bgStyle={bgStyle}
        style={style}
    />
}