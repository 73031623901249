import {useContext, useEffect, useState} from "react";
import {Dimensions, Text, TouchableOpacity} from "react-native";
import JobList from "./JobList";
import {AppContext} from "../../contexts";
import {Flex, Row} from "../../../lib/markup/markup";
import {bgPrimaryLight, center, flex, ph1, white} from "../../../const";
import Table from "../../../lib/markup/Table";
import {isDesktop} from "../../../lib/sugar";
import {View} from "react-native-web";
import Toggle from "../../../lib/fields/Toggle";


export default function () {
    const {route, user} = useContext(AppContext)
    return <Flex>
        {
            route.name === 'Tech' ?
                <JobList techID={route.params?.techID ? route.params.techID : user.id}/>
                : route.name === 'Cadcamist' ?
                    <CadCamJobList/>
                    :
                    <Total/>
        }

    </Flex>

}

function CadCamJobList() {
    const [jobs, setJobs] = useState([])
    const {currentDate, GET, user, navigation, PATCH} = useContext(AppContext)

    useEffect(async () => {
        const start = currentDate.isoDate() + ' 00:00:00'
        const stop = currentDate.isoDate() + ' 23:59:59'
        const response = await GET('main/cadcams/cadcamist_day', {created_at__range: [start, stop], executor: user.id})
        setJobs(response)
    }, [currentDate])
    let data = []
    for (let row of jobs) {
        const onPress = () => navigation.navigate('Patient', {patient_id: row.patient.id})

        data.push([
            <TouchableOpacity onPress={onPress}>
                <Text style={[ph1]}>{row.clinic}</Text>
            </TouchableOpacity>,
            <TouchableOpacity onPress={onPress}>
                <Text style={ph1}>{row.doctor}</Text>
            </TouchableOpacity>,
            <TouchableOpacity onPress={onPress}>
                <Text style={ph1}>{row.patient.last_name}</Text>
            </TouchableOpacity>,
            <TouchableOpacity onPress={onPress}>
                <Text style={ph1}>{row.techs}</Text>
            </TouchableOpacity>,
            <View>
                <Toggle
                    style={ph1}
                    selected={row.cadcamist_checked}
                    onPress={cadcamist_checked => PATCH('main/cadcams/' + row.id, {cadcamist_checked})}
                />
            </View>
        ])
    }
    return <Flex>
        <Table
            maxWidth={isDesktop() ? 600 : Dimensions.get('window').width - 10}
            widths={[flex, flex, flex, flex, 45]}
            head={['клиника', 'доктор', 'пациент', 'техники', '']}
            body={data}
        />
    </Flex>
}

function Total() {
    const {currentDate, GET} = useContext(AppContext)
    const [list, setList] = useState([])

    useEffect(async () => {
        const response = await GET('lab/total', {currentDate: currentDate.isoDate()})

    }, [currentDate])

    return <Flex>
        {/*<Stats/>*/}
        <Titles/>
    </Flex>
}

function Titles() {
    const {currentDate, holidays} = useContext(AppContext)
    let [days, setDays] = useState([])
    let titleWidth = 50
    let totalWidth = 50
    let dayWidth = 40
    let maxDays = Math.ceil(
        (Dimensions.get('window').width - titleWidth - totalWidth - ph1.paddingHorizontal)
        / (dayWidth + ph1.paddingHorizontal)
    )
    if (maxDays > 10) maxDays = 10

    const style = [ph1, center, white, bgPrimaryLight, {width: dayWidth}]

    useEffect(async () => {
        days = []
        let date = currentDate.copy()
        while (days.length < maxDays) {
            if (!holidays.includes(date.isoDate())) {
                days.push(date.getDate())
            }
            date.change({days: 1})
        }
        setDays(days)
    }, [currentDate])

    return <Row>
        <Text style={[style, {width: titleWidth}]}>техн.</Text>
        {days.map((day, key) =>
            <Text key={key} style={style}>{day}</Text>)}
        <Text style={[style, {width: totalWidth}]}>всего</Text>
    </Row>
}

// function HostStatus() {
//     const {user, GET} = useContext(AppContext)
//     if (!isCadCam(user) || screenSize() !== 'desktop') return null
//     const [host, setHost] = useState(user.host)
//
//     useEffect(async () => {
//         setInterval(async () => {
//             const response = await GET(`main/hosts/${user.host}`)
//             setHost(response)
//         }, 5000)
//     }, [])
//     switch (host.status_color) {
//         case 'danger':
//             return <B style={danger}>{host.status}</B>
//         case 'warning':
//             return <B style={warning}>{host.status}</B>
//         default:
//             return <B style={success}>{host.status}</B>
//     }
// }


















