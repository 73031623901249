import {Flex, Row} from "../../../lib/markup/markup";
import {Tab} from "../../../lib/markup/TabBar";
import TaskList from "./TaskList";
import {useContext} from "react";
import {CouriersContext} from "../../contexts";
import {ScrollView} from "react-native";


export default function () {
    const {deliveryNumber, updateState} = useContext(CouriersContext)
    return <Flex>
        <ScrollView>
            <Row>
                <Tab
                    active={deliveryNumber==='1'}
                    title={'отправка 1'}
                    onPress={() => updateState({deliveryNumber: '1'})}
                />
                <Tab
                    active={deliveryNumber==='2'}
                    title={'отправка 2'}
                    onPress={() => updateState({deliveryNumber: '2'})}
                />
            </Row>
            <TaskList/>
        </ScrollView>
    </Flex>
}
