import {Modal, TouchableOpacity, Text, View, Pressable} from "react-native";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {
    bgSecondary,
    bold,
    borderRadius,
    center,
    flex,
    flexEnd,
    fRow,
    mr1,
    mv1,
    p2,
    primary,
    absolute
} from "../const";
import {useContext, useState} from "react";
import {AppContext} from "./contexts";
import Btn from "../lib/buttons/Btn";
import FA from "../lib/static/FA";


export default function () {
    const [visible, setVisible] = useState(false)
    const {screens, navigation, route} = useContext(AppContext)

    return <View>
        <Btn title={faBars} onPress={() => setVisible(true)}/>
        <Modal animationType={'fade'} visible={visible} transparent={true}>
            <Pressable style={[flex, flexEnd]} onPress={() => setVisible(false)}>
                <View style={[
                    bgSecondary,
                    p2,
                    {borderBottomLeftRadius: borderRadius.borderRadius},
                    absolute
                    ]}>
                    {screens.map(({title, name, burger, icon}, key) => {
                            const color = route.name === name ? primary : {}
                            if (burger) return <TouchableOpacity
                                style={[center, mv1, fRow]}
                                key={key}
                                onPress={() => {
                                    setVisible(false)
                                    navigation.navigate(name)
                                }}
                            >
                                <View style={[mr1, {width: 30}]}>
                                    <FA icon={icon} style={[color]}/>
                                </View>
                                <Text style={[bold, color]}>{title.toUpperCase()}</Text>
                            </TouchableOpacity>
                        }
                    )}
                </View>
            </Pressable>
        </Modal>
    </View>
}