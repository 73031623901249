import React, {useEffect, useState} from "react";
import {Text, TouchableOpacity, View} from "react-native";
import {black, bold, border, borderTable, center, flex, padding, primary} from "../../const";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Btn from "../buttons/Btn";
import {Flex, Row} from "./markup";


export default function ({items, plus, style, selected = 0}) {
    const [active, setActive] = useState(0)
    useEffect(() => {
        let val = 0
        if (selected < 0) {
            val = items.length + selected
            if (val < 0) val = 0
        } else if (val > items.length - 1) {
            val = items.length - 1
            if (val < 0) val = 0
        }
        setActive(val)
    }, [items, selected])

    return <Flex style={[style]}>
        <Row>
            {items.map((item, key) =>
                <Tab
                    key={key}
                    title={item.title}
                    active={key === active}
                    onPress={() => {
                        setActive(key)
                    }}
                />
            )}
            {plus ?
                <View style={{
                    borderBottomColor: borderTable.borderColor,
                    borderBottomStyle: borderTable.borderStyle,
                    borderBottomWidth: borderTable.borderWidth,
                    marginTop: -1
                }}>
                    <Btn title={faPlus} onPress={plus}/>
                </View>
                : null
            }
        </Row>
        <Flex style={[borderTable, {borderTopColor: 'transparent'}, style]}>
            {/*<ScrollView style={flex}>*/}
                {items.length ? items[active < items.length ? active : items.length - 1].body : null}
            {/*</ScrollView>*/}
        </Flex>
    </Flex>
}

export function Tab({active, onPress, title}) {
    const activeStyle = {
        borderColor: border.borderColor,
        borderTopLeftRadius: border.borderRadius,
        borderTopRightRadius: border.borderRadius,
        borderStyle: border.borderStyle,
        borderWidth: border.borderWidth,
        borderBottomColor: 'transparent',
    }

    const inactiveStyle = {
        borderColor: 'transparent',
        borderStyle: border.borderStyle,
        borderWidth: border.borderWidth,
        borderBottomColor: border.borderColor,
    }

    const common = {
        ...padding,
        ...center,
        ...flex,
        height: 40,
    }
    return <TouchableOpacity
        style={[common, active ? activeStyle : inactiveStyle]}
        onPress={onPress}
    >
        <Text style={active ? [bold, primary] : black}>{title}</Text>
    </TouchableOpacity>
}
