import React, {useEffect, useState} from "react";
import {View, TextInput} from "react-native";
import {
    bgPrimaryLight,
    field, flex,
    fRow,
    ph1,
    stickLeft,
    stickRight
} from "../../const";
import {Menu, MenuOptions, MenuOption, MenuTrigger,} from 'react-native-popup-menu';
import BtnPrimary from "../buttons/BtnPrimary";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import NonEditableField from "./NonEditableField";
import {getBorderStyle, getWrpSize} from "../sugar";


export function AutocorrectField({
                                     object = null,
                                     placeholder,
                                     find,
                                     style = {},
                                     visible = true,
                                     findField = 'name',
                                     onSubmit = null,
                                     onSubmitButton = false,
                                     onSubmitClear = false,
                                     forwardRef = null,
                                     editable = true,
                                     minSymbols = 2,
                                     onBlurSubmit = false,
                                     onFocus = null,
                                 }) {
    if (!visible) return null
    const [state, setState] = useState({
        currentObject: object,
        variants: [],
        selectedVariant: 0,
        dropdown: false,
        displayValue: object ? object[findField] : '',
    })
    useEffect(() => {
        updateState({object, displayValue: object ? object[findField] : ''})
    }, [object])

    function updateState(args) {
        setState({...state, ...args})
    }

    function getVariant(text) {
        if (!state.variants.length) return
        for (let variant of state.variants) {
            if (variant[findField].toLowerCase().startsWith(text.toLowerCase())) return variant
        }
    }

    function onCompleteChange(variant) {
        const currentObject = variant ? variant : state.currentObject
        if (onSubmit) onSubmit(currentObject)
        if (onSubmitClear) updateState({displayValue: '', currentObject: null, dropdown: false})
        else updateState({dropdown: false, displayValue: currentObject ? currentObject[findField] : ''})
    }


    if (!editable) return <NonEditableField
        text={object ? object[findField] : ''}
        fieldStyle={[onSubmitButton ? stickRight : null]}
        placeholder={placeholder}
    />

    return <View style={[fRow, getWrpSize(style)]}>
        <TextInput
            onFocus={() => onFocus ? onFocus() : null}
            ref={forwardRef}
            style={[
                flex,
                ph1,
                {backgroundColor: field.backgroundColor},
                getBorderStyle([style, onSubmitButton ? stickRight: null])
            ]}
            onKeyPress={({nativeEvent: {key: keyValue}}) => {
                if (keyValue === 'ArrowDown') {
                    if (state.selectedVariant < state.variants.length - 1) {
                        updateState({
                            selectedVariant: state.selectedVariant + 1,
                            currentObject: state.variants[state.selectedVariant + 1]
                        })
                    }
                } else if (keyValue === 'ArrowUp') {
                    if (state.selectedVariant > 0) {
                        updateState({
                            selectedVariant: state.selectedVariant - 1,
                            currentObject: state.variants[state.selectedVariant - 1]
                        })
                    }
                } else if (['Enter', 'Tab'].includes(keyValue)) onCompleteChange()
            }}
            placeholder={placeholder}
            value={state.displayValue}
            onChangeText={async displayValue => {
                if (!displayValue) {
                    updateState({
                        displayValue: '',
                        currentObject: null
                    })
                } else if (displayValue.length >= minSymbols) {
                    const data = await find(displayValue)
                    if (data && data.length) {
                        const currentObject = getVariant(displayValue)
                        updateState({
                            selectedVariant: 0,
                            variants: data,
                            dropdown: true,
                            currentObject: currentObject ? currentObject : data[0],
                            displayValue: displayValue
                        })
                    } else {
                        updateState({displayValue: ''})
                    }
                } else updateState({displayValue})
            }}
            onBlur={() => {
                if (onBlurSubmit) onCompleteChange()
            }}
        />
        <Menu
            opened={state.dropdown}
        >
            <MenuTrigger/>
            <MenuOptions>
                {state.variants.slice(0, 10).map((variant, key) => {
                    return <MenuOption
                        style={key === state.selectedVariant ? bgPrimaryLight : null}
                        key={key}
                        onSelect={() => onCompleteChange(variant)}
                        text={variant[findField]}
                    />
                })}
            </MenuOptions>
        </Menu>
        <BtnPrimary
            visible={onSubmitButton}
            title={faPlus}
            style={[stickLeft]}
            onPress={onCompleteChange}
        />
    </View>
}
