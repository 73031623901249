import {useContext} from "react";
import {Text, View} from "react-native";
import {AppContext, CurrentUserContext} from "../../contexts";
import {Flex, PressRow} from "../../../lib/markup/markup";
import {borderTable, esc, p1} from "../../../const";
import Relation from "../../../components/Relations";

export default function (props) {
    const {navigation, PATCH, setAlert, getClinic} = useContext(AppContext)
    const {reload} = useContext(CurrentUserContext)

    const clinic = getClinic(props.clinic)
    return <PressRow
        style={[borderTable]}
        onPress={() => {
            if (props.status === 'in') {
                setAlert({
                    title: 'принять приглашение?',
                    buttons: [
                        {
                            title: 'принять',
                            style: 'primary',
                            onPress: async () => {
                                await PATCH(`clinic/UserClinicRelation/${props.id}`, {status: 'do'})
                                reload()
                            }
                        },
                        esc
                    ]
                })
            } else if (props.status === 'ba') setAlert({body: 'Вы заблокированы этой клиникой'})
            else navigation.navigate('ClinicProfile', {clinic_id: props.clinic})
        }}
    >
        <Flex style={p1}>
            <Text>{clinic.name}</Text>
        </Flex>
        <View style={p1}>
            <Relation>{props.status}</Relation>
        </View>
    </PressRow>
}
