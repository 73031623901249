import {TextInput, Dimensions} from "react-native";
import {useContext, useState} from "react";
import {AppContext, PatientContext} from "../../contexts";
import {Col, Flex, Row} from "../../../lib/markup/markup";
import {bgWhite, borderTable, field, mb2, ph1} from "../../../const";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import Btn from "../../../lib/buttons/Btn";
import DeliveryTime from "../../../components/delivery_time/DeliveryTime";
import { lessTablet} from "../../../lib/sugar";
import {isManager} from "../../../objects/user_methods";


export default function (props) {
    const [state, setState] = useState({stage: props, hidden: false})
    const {patient} = useContext(PatientContext)
    const {PATCH, DELETE, user} = useContext(AppContext)

    function save(args) {
        PATCH('main/stages/' + props.id, args)
        setState({...state, stage: {...state.stage, ...args}})
    }
    const width = lessTablet() ? {width: Dimensions.get('window').width/2 - 35} : null
    const comment2 = isManager(user) ? <TextInput
        numberOfLines={1}
        style={[ph1, bgWhite, borderTable, width]}
        defaultValue={state.stage.comment2}
        onChangeText={comment2 => save({comment2})}
        placeholder={'отправка'}
    /> : null

    const comment = isManager(user) ? <TextInput
        numberOfLines={1}
        style={[ph1, bgWhite, borderTable, width]}
        defaultValue={state.stage.comment}
        onChangeText={comment => save({comment})}
        placeholder={'комментарий'}
    />: null
    const deliveryTime = <DeliveryTime
        {...state.stage}
        stage_id={state.stage.id}
        full={true}
        patientID={patient.id}
        orderID={props.order}
        style={bgWhite}
    />
    const btnMinus = <Btn
        visible={isManager(user)}
        title={faMinus}
        onPress={() => {
            DELETE('main/Stages/' + state.stage.id)
            setState({...state, hidden: true})
        }}/>
    if (state.hidden) return null
    const rowStyle = {height: field.height}
    if (lessTablet()) return <Col style={mb2}>
        <Row style={rowStyle}>
            {comment2}
            {comment}
            {btnMinus}
        </Row>
        <Row style={rowStyle}>
            {deliveryTime}
        </Row>
    </Col>
    return <Row style={rowStyle}>
        {comment2}
        <Flex style={borderTable}>{deliveryTime}</Flex>
        {comment}
        {btnMinus}
    </Row>
}


