import React, {useState} from "react";
import {TextInput, View, Text} from "react-native";
import {field, jCenter, jRow, ph1} from "../../const";


export default function (props) {
    if (props.visible === false) return null
    const [value, setValue] = useState(props.value ? String(props.value) : props.canBeNull ? '0' : '')
    const onChange = props.onChange || props.onChangeText
    if (props.editable === false) return <View style={[jCenter, ph1, {height: field.height}]}>
        <Text numberOfLines={1} style={[jRow, props.style]}>{value}</Text>
    </View>
    return <TextInput
        onKeyPress={({nativeEvent: {key: keyValue}}) => {
            if (!props.onKey || !props.onKey[keyValue]) return
            props.onKey[keyValue](value)
        }}
        style={[field, props.style]}
        keyboardType={'numeric'}
        placeholder={props.placeholder}
        value={value}
        onChangeText={text => {
            let value = String(+text.replace(/[^0-9]/g, ''))
            if (!value && !props.canBeNull) value = ''
            setValue(value)
            onChange(value)
        }}
    />
}
