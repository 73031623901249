import {border, btnWarning} from "../../const";
import Btn from "./Btn";


export default function ({
                               title = null,
                               onPress,
                             onLongPress,
                               wrpStyle = {},
                               textStyle = {},
                               bgStyle = {},
                               style = {},
                               disabled = false,
                               size = 'normal',
                               visible = true
                           }) {
    return <Btn
        title={title}
        onPress={onPress}
        onLongPress={onLongPress}
        wrpStyle={[wrpStyle, style]}
        textStyle={[btnWarning.text, textStyle]}
        bgStyle={[border, bgStyle, style]}
        disabled={disabled}
        colors={btnWarning.background}
        size={size}
        visible={visible}
        style={style}
    />
}
