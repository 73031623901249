import {Modal, Pressable, View} from "react-native";
import {bgSecondary, flex, p2} from "../../const";


export function ModalWindow({
                                children,
                                visible = false,
                                style = {},
                                positionStyle = {},
                                animation = 'fade',
                                onClose
                            }) {

    return <Modal animationType={animation} visible={visible} transparent={true}>
        <Pressable style={[flex, positionStyle]} onPress={() => {
            onClose()
        }}>
            <View style={[bgSecondary, p2, style]}>{children}</View>
        </Pressable>
    </Modal>
}

