import {EDate, replaceAll} from "../../../lib/sugar";
import {AppContext, PatientContext} from "../../contexts";
import Chat from "../../../lib/modules/chat/Chat";
import {useContext, useEffect, useState} from "react";
import Loading from "../../../lib/static/Loading";
import {host} from "../../../labConsts";
import {isAdministrations, isClinic} from "../../../objects/user_methods";
import {apiUrl} from "../../../lib/api";


export default function () {
    const {patient} = useContext(PatientContext)
    const {user, GET, POST, DELETE} = useContext(AppContext)
    const [state, setState] = useState({ready: false})

    function updateState(args) {
        setState({...state, ...args})
    }

    function messageSerializer(message) {
        return {
            datetime: new EDate(message.datetime),
            body: message.text,
            file: message.file ? host + message.file:null,
            author: message.author,
            id: message.id,
            type: message.system ? 'system' : message.user === user.id ? 'mine' : 'opponent',
            hidden: message.hidden
        }
    }

    useEffect(async () => {
        let isMounted = true
        let args = {patient: patient.id}
        if (!isAdministrations(user)) {
            args.system = 0
            args.hidden = 0
        }
        const data = await GET('main/PatientMessages/filter', args, false)
        if (!data && isMounted) updateState({chats: [{chatId: null, messages: []}], ready: true})
        let messages = []
        const showHidden = isAdministrations(user) || isClinic(user)
        for (let message of data) {
            if (!showHidden && message.hidden) continue
            messages.push(messageSerializer(message))
        }
        if (isMounted) updateState({chats: [{chatId: null, messages}], ready: true})
        return () => isMounted = false
    },[])

    if (!state.ready) return <Loading/>
    return <Chat
        chats={state.chats}
        createMessage={(async args => {
            args = {patient: patient.id, text: args.body, file:args.file, user: user.id, hidden: args.hidden}
            const message = await POST('/main/PatientMessages/', args)
            if (!Object.keys(message).length) return
            let messages = state.chats[0].messages.slice()
            const serializedMessage = messageSerializer(message)
            messages.push(serializedMessage)
            let chats = state.chats.slice()
            chats[0].messages = messages
            POST(
                'main/notifications/for_patient',
                {patient: patient.id, body: `пациент: ${patient.last_name} новая информация`}
            )
            updateState({chats})
        })}
        deleteMessage={(messageId) => {
            DELETE('/main/PatientMessages/' + messageId)
            let messages = state.chats[0].messages.slice()
            for (let i=0; i<messages.length; i++) {
                if(messages[i].id === messageId) {
                    messages.splice(i, 1)
                    break
                }
            }
            let chats = state.chats.slice()
            chats[0].messages = messages
            updateState({chats})
        }}
        showContacts={false}
    />
}
