import {
    center,
    fontBig,
    fontNormal,
    fontSmall,
    heightBig,
    heightNormal,
    heightSmall, jCenter, noWrap, ph1, widthBig,
    widthNormal,
    widthSmall
} from "../../const";
import {Text, TouchableOpacity} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {screenSize} from "../sugar";
import FA from "../static/FA";


export default function ({
                        title = null,
                        onPress,
                        wrpStyle = {},
                        textStyle = {},
                        bgStyle = {},
                        disabled = false,
                        size = 'normal',
                        colors = ['transparent', 'transparent'],
                        visible = true,
                        style = {},
    onLongPress
                    }) {
    if (!visible) return null
    const fontSize = size === 'normal' ? fontNormal : size === 'small' ? fontSmall : fontBig
    const height = size === 'normal' ? heightNormal : size === 'small' ? heightSmall : heightBig
    const width = size === 'normal' ? widthNormal : size === 'small' ? widthSmall : widthBig
    return <TouchableOpacity onPress={onPress} disabled={disabled} style={[wrpStyle, style]} onLongPress={onLongPress}>
        <LinearGradient
            colors={colors}
            style={[jCenter, center, height, width, bgStyle, {zIndex: 3, elevation: 3}]}
            opacity={disabled ? 0.4 : 1}
        >
            {
                getTitle(title, fontSize, textStyle)
            }
        </LinearGradient>
    </TouchableOpacity>
}

function getTitle(title, fontSize, textStyle) {
    if (['string', 'number'].includes(typeof title)) return <Text style={[ph1, fontSize, textStyle]}>{title}</Text>
    if (title instanceof Array) {
        return  screenSize() === 'desktop' ?
            <Text style={[ph1, noWrap, fontSize, textStyle]}>{title[0]}</Text>
            :
            <FA icon={title[1]} style={[fontSize, textStyle]}/>
    }
    return <FA icon={title} style={[fontSize, textStyle]}/>
}
