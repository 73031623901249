import {useContext} from "react";
import {AppContext, ClinicContext} from "../../contexts";
import {View} from "react-native";
import TextField from "../../../lib/fields/TextField";
import {fRow, mh1} from "../../../const";
import {screenSize} from "../../../lib/sugar";

export default function ClinicInfo() {
    const {PATCH} = useContext(AppContext)
    const {clinic, editable} = useContext(ClinicContext)
    async function save(arg) {
        PATCH(`main/clinics/${clinic.id}`, arg)
    }

    return <View style={screenSize() === 'desktop' ? fRow:null}>
        <TextField
            placeholder={'название'}
            onChange={name => save({name})}
            value={clinic.name}
            editable={editable}
        />
        <TextField
            style={screenSize() === 'desktop' ? mh1:null}
            placeholder={'адрес'}
            onChange={address => save({address})}
            value={clinic.address}
            editable={editable}
        />
        <TextField
            placeholder={'телефон'}
            onChange={phone => save({phone})}
            value={clinic.phone}
            editable={editable}
        />
    </View>
}
