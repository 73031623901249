import {useContext} from "react";
import {View} from "react-native";
import {AppContext, CurrentUserContext} from "../../contexts";
import {danger, field, mt1, primary, w50} from "../../../const";
import {Block, Row} from "../../../lib/markup/markup";
import {B} from "../../../lib/text/Style";
import Select from "../../../lib/fields/Select";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import RelationView from "./RelationView";
import {isAdministrations, isClinic} from "../../../objects/user_methods";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";

export default function () {
    const {currentUser, save} = useContext(CurrentUserContext)
    const {navigation, user, GET, setAlert, POST, reload} = useContext(AppContext)
    console.log(currentUser.relations)

    return <View>
        <Block>
            <B style={[primary, mt1]}>вызывать курьера: </B>
            <Select
                canBeNull={false}
                selected={currentUser.courier_call}
                items={[
                    {name: 'всегда', id: 'всегда'},
                    {name: 'спрашивать', id: 'спрашивать'},
                    {name: 'самовывоз', id: 'самовывоз'},
                ]}
                onChange={courier_call => save('courier_call', courier_call)}
            />

        </Block>
        <Row style={mt1} visible={isClinic(user)}>
            <B visible={currentUser.relations.length} style={primary}>мои клиники</B>
            <B visible={!currentUser.relations.length} style={[danger, w50]}>
                Зарегистрируйте клинику или запросите приглашение у администратора клиники или Креатив-Лаб
            </B>
        </Row>
        <View style={[mt1]}>
            {currentUser.relations.map((relation, key) => <RelationView key={key} {...relation}/>)}
        </View>
        <Row style={mt1}>
            <BtnPrimary
                visible={!isAdministrations(user) && isClinic(currentUser)}
                style={[{width: field.width}]}
                title={'зарегистрировать клинику'}
                onPress={() => navigation.navigate('ClinicAdd')}
            />
            <AutocorrectField
                onSubmitClear={true}
                placeholder={'добавить отношение'}
                find={async name => await GET('main/clinics/filter', {name__istartswith: name})}
                visible={isAdministrations(user) && isClinic(currentUser)}
                findField={'name'}
                onSubmit={async clinic => {
                    if (currentUser.relations.includes(clinic.id)) {
                        setAlert({title: 'пользователь уже состоит в клинике'})
                        return
                    }
                    await POST('clinic/UserClinicRelations', {clinic: clinic.id, user: currentUser.id, status: 'do'})
                    reload()
                }}
                onSubmitButton={true}
            />
        </Row>
    </View>
}
