import {Dimensions, Pressable, ScrollView, Text, TextInput, TouchableOpacity, View} from "react-native";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useState} from "react";
import {AppContext, DeliveryContext} from "../../../contexts";
import {asObject, isDesktop, zfill} from "../../../../lib/sugar";
import Loading from "../../../../lib/static/Loading";
import Table from "../../../../lib/markup/Table";
import {H2} from "../../../../lib/text/H";
import {
    center,
    danger,
    flex, flexCenter, flexEnd,
    fRow, jRow,
    mr1,
    ph1, secondary,
} from "../../../../const";
import FA from "../../../../lib/static/FA";
import {bgPickup, bgDomostom, bgAttention, colorPersonal, bgUnpaid} from "../../../../labConsts";
import {Row} from "../../../../lib/markup/markup";
import OrderStatus from "../../../../components/OrderStatus";
import DeliveryTime from "../../../../components/delivery_time/DeliveryTime";

export default function () {
    const {currentDate, navigation, PATCH, GET, isFocused, orderStatuses, getTechs} = useContext(AppContext)
    const {deliveryNumber} = useContext(DeliveryContext)
    const [state, setState] = useState({
        isLoading: true, orderBy: 'clinic', list: [], refresh: false, techs: getTechs()
    })

    function setOrderBy(orderBy) {
        setState({...state, orderBy})
    }

    function reload() {
        updateState({refresh: !state.refresh})
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    async function save(id, field, value) {
        PATCH(`main/stages/${id}`, asObject(field, value))
    }

    function sort(a, b) {
        if (a[state.orderBy].toLowerCase() > b[state.orderBy].toLowerCase()) return 1
        return -1
    }

    useEffect(async () => {
        updateState({list: [], isLoading: true})
        const lab_date = currentDate.isoDate()

        let response = await GET('main/deliveries/get_day', {lab_date, deliveryNumber})
        response.sort((a, b) => {
            if (a.lab_date > b.lab_date) return -1
            return 1
        })

        function convertTime(number) {
            if (!number) return null
            const hours = Math.floor(+number / 3600)
            return zfill(hours) + ':00'
        }

        function getIndex(row) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].order_id === row.order_id) return i
            }
            return -1
        }

        let data = []
        for (let row of response) {
            if (!row.tech_stage_status_id) continue
            let i = getIndex(row)
            if (i === -1) data.push({
                deliveryNumber: row.deliveryNumber,
                order_id: row.order_id,
                comment: row.comment,
                comment2: row.comment2,
                attention: row.attention,
                pickup: row.pickup,
                clinic_date: row.clinic_date,
                clinic_time: convertTime(row.clinic_time),
                lab_date: row.lab_date,
                lab_time: convertTime(row.lab_time),
                clinic: row.clinic,
                patient: row.patient,
                id: row.stage_id,
                order_status: row.order_status,
                statuses: [{
                    id: row.tech_stage_status_id,
                    stage: row.stage_id,
                    tech: row.tech_id,
                    status: row.lab_status_id,
                    // status: row.tech_stage_status
                }],
                doctor: row.doctor,
                patient_id: row.patient_id,
                other_techs: [],
                order_personal: row.personal,
                confirmed: row.confirmed,
                time: row.lab_time
            })
            else if (data[i].lab_date === row.lab_date && row.lab_date === lab_date) {
                if (row.lab_date === lab_date) {
                    data[i].comment = row.comment
                }
                data[i].statuses.push({
                    id: row.tech_stage_status_id,
                    stage: row.stage_id,
                    tech: row.tech_id,
                    status: row.lab_status_id,
                })
            } else {
                data[i].other_techs.push(state.techs[row.tech_id]?.code)
            }
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i].lab_date !== lab_date) {
                for (let n = 0; n < response.length; n++) {
                    if (response[n].order_id === data[i].order_id && response[n].lab_date === lab_date) {
                        data[i].comment = response[n].comment
                        data[i].comment2 = response[n].comment2
                        data[i].id = response[n].stage_id
                    }
                }
            }
            let set = new Set(data[i].other_techs)
            let array = Array.from(set)
            array.sort()
            data[i].other_techs = array.join(',')
            data[i].statuses.sort((a, b) => {
                if (state.techs[a.tech]?.code > state.techs[b.tech]?.code) return 1
                return -1
            })
            if (data[i].statuses.length) data[i].tech = state.techs[data[i]?.statuses[0].tech]?.code
        }

        data.sort(sort)
        updateState({isLoading: false, list: data})
    }, [currentDate, deliveryNumber, state.refresh, state.orderBy, isFocused])

    function toPatient(patient_id) {
        navigation.navigate('Patient', {patient_id})
    }

    const rowHeight = 20
    let maxWidth = isDesktop() ? Dimensions.get('window').width * 3 / 4 - 30 : 1200
    if (maxWidth < 1000) maxWidth = 1200
    if (state.isLoading) return <Loading/>
    if (!state.list.length) return <H2 style={center}>нет отправок</H2>
    return <ScrollView style={flex}>
        <ScrollView horizontal={true}>
            <Table
                maxWidth={maxWidth}
                maxRows={200}
                height={rowHeight}
                sort={state.setOrderBy}
                rowHeight={20}
                head={[
                    'всего: ' + state.list.length,
                    'отправка',
                    <Pressable onPress={() => setOrderBy('clinic')}>
                        <Text numberOfLines={1} style={[ph1, jRow]}>клиника</Text>
                    </Pressable>,
                    <Pressable onPress={() => setOrderBy('doctor')}>
                        <Text style={[ph1, jRow]}>доктор</Text>
                    </Pressable>,
                    <Pressable onPress={() => setOrderBy('patient')}>
                        <Text numberOfLines={1} style={[ph1, jRow]}>пациент</Text>
                    </Pressable>,
                    <Row>
                        <Pressable style={flex} onPress={() => setOrderBy('tech')}>
                            <Text numberOfLines={1} style={[ph1, jRow]}>техники</Text>
                        </Pressable>
                        <Pressable style={flex} onPress={() => setOrderBy('time')}>
                            <Text numberOfLines={1} style={[ph1, jRow, flexEnd]}>время</Text>
                        </Pressable>
                    </Row>,
                    'примечание'
                ]}
                widths={[
                    100,
                    flex,
                    100,
                    100,
                    100,
                    340,
                    flex
                ]}
                body={state.list.map((stage, key) => {
                    let bg = null
                    if (stage.order_status === 10) bg = bgAttention
                    else if (stage.comment2.includes('не опл')) bg = bgUnpaid
                    else if (stage.pickup) {
                        if (['домо-стом', 'домостом', 'домо стом'].includes(stage.clinic.toLowerCase())) bg = bgDomostom
                        else bg = bgPickup
                    }
                    return [
                        <View style={[fRow, center, flexCenter, bg]}>
                            <TouchableOpacity
                                onPress={() => {
                                    save(stage.id, 'attention', !stage.attention)
                                    reload()
                                }}
                                style={ph1}
                            >
                                <FA
                                    icon={faExclamationTriangle}
                                    style={[stage.attention ? danger : secondary]}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[mr1, {width: 70}]}
                                onPress={() => {
                                    let status = null
                                    if (orderStatuses[stage.order_status].name === 'on_shelf') {
                                        if (stage.pickup) status = orderStatuses.in_clinic.id
                                        else status = orderStatuses.checking.id
                                    } else if (orderStatuses[stage.order_status].name === 'checking') {
                                        status = orderStatuses.working.id
                                    } else if (orderStatuses[stage.order_status].name === 'working') {
                                        status = orderStatuses.on_shelf.id
                                    } else if (orderStatuses[stage.order_status].name === 'in_clinic') {
                                        if (stage.pickup) status = orderStatuses.on_shelf.id
                                    } else if (orderStatuses[stage.order_status].name === 'waiting') {
                                        status = orderStatuses.working.id
                                    }
                                    if (status) {
                                        PATCH('main/orders/' + stage.order_id, {status})
                                        let list = state.list.slice()
                                        list[key].order_status = status
                                        updateState({list})
                                    }
                                }}
                            >
                                <OrderStatus status={stage.order_status}/>
                            </TouchableOpacity>
                        </View>,

                        <View style={fRow}>
                            {stage.order_personal ? <Text style={[colorPersonal, ph1]}>личн.</Text> : null}
                            <TextInput
                                numberOfLines={1}
                                defaultValue={stage.comment2}
                                onChangeText={text => save(stage.id, 'comment2', text)}
                                style={[flex, ph1, bg]}
                            />
                        </View>,

                        <BtnManager title={stage.clinic} onPress={() => toPatient(stage.patient_id)} style={bg}/>,

                        <BtnManager title={stage.doctor} onPress={() => toPatient(stage.patient_id)} style={bg}/>,

                        <BtnManager title={stage.patient} onPress={() => toPatient(stage.patient_id)} style={bg}/>,

                        <DeliveryTime
                            full={false}
                            key={key}
                            lab_time={stage.lab_time}
                            clinic_time={stage.clinic_time}
                            stage_id={stage.id}
                            pickup={stage.pickup}
                            lab_date={stage.lab_date}
                            clinic_date={stage.clinic_date}
                            reload={reload}
                            patientID={stage.patient_id}
                            statuses={stage.statuses}
                            deliveryNumber={stage.deliveryNumber}
                            otherTechs={stage.other_techs}
                            orderID={stage.order_id}
                            confirmed={stage.confirmed}
                        />,

                        <TextInput
                            numberOfLines={1}
                            defaultValue={stage.comment}
                            onChangeText={text => save(stage.id, 'comment', text)}
                            style={{...flex, ...ph1, ...bg}}
                        />
                    ]
                })}
            />
        </ScrollView>
        <Row style={{height: 50}}/>
    </ScrollView>
}

function BtnManager({title, onPress, style}) {
    return <TouchableOpacity onPress={onPress} style={[{backgroundColor: 'transparent'}, style]}>
        <Text style={ph1} numberOfLines={1}>{title}</Text>
    </TouchableOpacity>
}







