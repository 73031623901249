import {View} from "react-native";
import {useContext, useState} from "react";
import {AppContext} from "../contexts";
import {center, field, flex, ml1, mr1, mt1, mt2, mv2} from "../../const";
import {isPhoneCorrect} from "../../lib/sugar";
import {Flex, Row} from "../../lib/markup/markup";
import {H3} from "../../lib/text/H";
import TextField from "../../lib/fields/TextField";
import Error from "../../lib/text/Error";
import BtnPrimary from "../../lib/buttons/BtnPrimary";
import BtnSecondary from "../../lib/buttons/BtnSecondary";

export default function () {
    const {POST, GET, navigation, user} = useContext(AppContext)
    const [error, setError] = useState(0)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')

    return <Flex style={center}>
        <View style={{width: field.width}}>
            <H3>добавить клинику</H3>
            <TextField placeholder={'наименование'} onChange={setName}/>
            <Error visible={error === 1}>введите название клиники</Error>

            <TextField style={mv2} placeholder={'адрес'} onChange={setAddress}/>
            <Error visible={error === 2}>введите адрес</Error>

            <TextField placeholder={'телефон'} onChange={setPhone}/>
            <Error visible={error === 3}>некорректный номер телефона</Error>
            <Error visible={error === 4}>Клиника существует. Свяжитесь с администратором</Error>
            <Row style={mt2}>
                <BtnPrimary
                    style={[flex]}
                    title={'добавить'}
                    onPress={async () => {
                        if (!name) {
                            setError(1)
                            return
                        }
                        if (!address) {
                            setError(2)
                            return
                        }
                        if (!isPhoneCorrect(phone)) {
                            setError(3)
                            return;
                        }
                        setError(0)
                        const data = await GET('main/clinics/filter', {name})
                        if (data.length) {
                            setError(4)
                            return
                        }
                        const clinic = await POST('main/clinics', {name, address, phone})
                        await POST('clinic/UserClinicRelation', {user: user.id, clinic: clinic.id, status: 'ow'})
                        navigation.goBack()
                    }}/>
                <BtnSecondary style={[flex, ml1]} title={'отмена'} onPress={() => navigation.goBack()}/>
            </Row>
        </View>
    </Flex>
}