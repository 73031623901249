import {Text, TouchableOpacity, ScrollView, Dimensions} from "react-native";
import {Flex, Row} from "../../../lib/markup/markup";
import {H2} from "../../../lib/text/H";
import {center, flex, ph1} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import Table from "../../../lib/markup/Table";
import {isDesktop} from "../../../lib/sugar";


export default function (props) {
    const [state, setState] = useState({ready: false, orderBy: 'id', orders: []})
    const {GET, navigation, currentDate, getTech} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        updateState({ready: false})
        let orders = await GET(
            'main/Foundries/load_day',
            {start: currentDate.isoDate() + ' 00:00:00', stop: currentDate.isoDate() + ' 23:59:59'}
        )
        updateState({ready: true, orders})
    }, [currentDate])

    function sort(field) {

    }

    const head = [
        {title: 'id', field: 'id'},
        {title: 'клиника', field: 'name'},
        {title: 'доктор', field: 'doctor'},
        {title: 'пациент', field: 'patient'},
        {title: 'техники', field: 'tech'},
        {title: 'исполнитель', field: 'executor'},
    ]
    const widths = [
        80,
        flex,
        flex,
        flex,
        flex,
        flex
    ]

    let orders = state.orders.slice()
    orders.sort((a, b) => {
        if (a[state.orderBy] > b[state.orderBy]) return 1
        return -1
    })

    let body = []
    const fields = ['id', 'clinic', 'doctor', 'patient', 'tech', 'executor']
    for (let order of orders) {
        body.push([])
        for (let field of fields) {
            body[body.length-1].push(<TouchableOpacity
                style={ph1}
                onPress={() => navigation.navigate('Patient', {patient_id: order.patient_id})}
            >
                <Text>{order[field]}</Text>
            </TouchableOpacity>)
        }
    }

    if (!state.ready) return <Loading/>
    if (!state.orders.length) return <H2 style={center}>нет работ</H2>
    return <Flex>
        <ScrollView horizontal={true}>
            <ScrollView>
                <Table
                    head={head.map((obj, key) =>
                        <TouchableOpacity
                            style={ph1}
                            key={key}
                            onPres={() => updateState(obj.field)}
                        >
                            <Text>{obj.title}</Text>
                        </TouchableOpacity>)}
                    body={body}
                    widths={widths}
                    maxWidth={isDesktop() ? Dimensions.get('window').width*3/4-30: 800}
                />
            </ScrollView>
            <Row style={{height: 50}}/>
        </ScrollView>
        <Row style={{width: 50}}/>
    </Flex>
}


