import {View, Text, Platform} from "react-native";
import Price from "./Price";
import {useContext, useEffect, useState} from "react";
import {AppContext, SectionsContext} from "../../contexts";
import {
    bgPrimaryLight, bgSecondaryLight, bgWhite,
    center,
    flex, fontBig,
    fRow,
    fWrap, mh1,
    ml1,
    mt1,
    p1,
    stick,
    stickLeft,
    stickRight,
    white
} from "../../../const";
import BtnShowHide from "../../../lib/buttons/BtnShowHide";
import {Col, Flex, Row} from "../../../lib/markup/markup";
import NumberField from "../../../lib/fields/NumberField";
import TextField from "../../../lib/fields/TextField";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {asObject, screenSize} from "../../../lib/sugar";
import Creator from "../../../lib/fields/Creator";


export default function (props) {
    const {editable} = useContext(SectionsContext)
    const {GET, POST, PATCH, DELETE, setAlert} = useContext(AppContext)
    const [visible, setVisible] = useState(true)
    const {open, setOpen} = props
    const [prices, setPrices] = useState([])
    const [update, setUpdate] = useState(false)

    useEffect(async () => {
        let cleanUp = false
        if (!cleanUp) {
            setPrices(
                await GET('main/Prices/filter', {section: props.id})
            )
        }
        return () => cleanUp = true
    }, [props, update])

    async function saveSection(field, value) {
        PATCH(`main/PriceSections/${props.id}`, asObject(field, value))
    }

    const titleStyle = [bgPrimaryLight, p1, fRow, fWrap]
    if (!visible) return null
    return <View>
        <Row style={titleStyle} visible={editable}>
            <BtnShowHide style={stickRight} open={open} onPress={() => setOpen(!open)}/>
            <NumberField
                style={[{width: 40, minWidth: 40}, stick, center]}
                value={String(props.number)}
                onChange={value => saveSection('number', value)}
            />
            <TextField
                style={[flex, stick]}
                value={props.name}
                onChange={value => saveSection('name', value)}
            />
            <BtnSecondary
                style={stickLeft}
                title={faTrash}
                onPress={() => {
                    setAlert({
                        title: 'удалить категорию?',
                        body: 'все связанные записи также будут удалены',
                        remove: async () => {
                            await DELETE(`main/PriceSections/${props.id}`)
                            setVisible(false)
                        }
                    })
                }}
            />
            <Creator
                style={screenSize() === 'desktop' ? ml1 : mt1}
                placeholder={'новая позиция'}
                onPress={async name => {
                    const data = await POST('main/prices', {name, section: props.id})
                    setPrices([data].concat(prices))
                    if (Platform.OS === 'web') setUpdate(!update)
                }
                }
            />
        </Row>

        <Row style={titleStyle} visible={!editable}>
            <Col>
                <BtnShowHide isActive={open} onPress={() => setOpen(!open)}/>
            </Col>
            <Flex>
                <Text style={[white, screenSize() === 'small' ? {} : fontBig, mh1]}>{props.name}</Text>
            </Flex>
        </Row>
        {open ?
            prices.map((price, key) => {
                return <Price
                    key={key}
                    background={key % 2 ? bgSecondaryLight : bgWhite}
                    {...price}
                />
            })
            : null
        }
    </View>
}