import {View, Text, TextInput} from "react-native";
import {Row} from "../../../lib/markup/markup";
import {
    bold,
    esc,
    field,
    jEnd,
    mr1,
    mt1,
    p1,
    primary,
    stick,
    stickLeft,
    stickRight
} from "../../../const";
import {useContext, useState} from "react";
import {AppContext} from "../../contexts";
import NumberField from "../../../lib/fields/NumberField";
import {copyObject, fixHeight} from "../../../lib/sugar";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Btn from "../../../lib/buttons/Btn";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";


export default function () {
    const {DELETE, PATCH, route, navigation, setAlert} = useContext(AppContext)
    const [state, setState] = useState({
        ...route.params.table,
        url: route.params.url,
    })

    function saveTable(args) {
        PATCH(state.url + state.id, args)
        setState({...state, ...args})
    }

    async function deleteTable() {
        setAlert({
            title: 'удалить таблицу?',
            buttons: [
                {
                    title: 'удалить',
                    style: 'danger',
                    onPress: async () => {
                        await DELETE(state.url + state.id)
                        navigation.goBack()
                    }
                },
                esc
            ]
        })
    }

    function updateState(args) {
        setState({...state, ...args})
    }

    function save(args) {
        PATCH(state.url + state.id, args)
        updateState(args)
    }

    const h = [fixHeight(50), jEnd]

    return <View style={[p1, mt1]}>
        <Row>
            <View style={h}>
                <Text style={[bold, primary]}>очередность</Text>
                <NumberField
                    value={state.number}
                    style={[field, stickRight]}
                    onChangeText={number => saveTable({number})}
                />
            </View>
            <View style={h}>
                <Text style={[bold, primary]}>наименование</Text>
                <TextInput
                    value={state.name}
                    style={[field, stick]}
                    onChangeText={name => saveTable({name})}
                />
            </View>
            <View style={h}>
                <Text style={[bold, primary]}>выделять красным</Text>
                <NumberField
                    value={state.alert_then}
                    style={[field, stick]}
                    onChange={alert_then => saveTable({alert_then})}
                />
            </View>
            <View style={h}>
                <BtnSecondary style={stickLeft} title={faTrash} onPress={deleteTable}/>
            </View>
        </Row>

        <Text style={[bold, primary, mt1]}>строки:</Text>
        <Row>
            {state.struct.row_names.map((name, key) => {
                return <Row key={key} style={mr1}>
                    <TextInput
                    style={[field, state.struct.row_names.length > 1 ? stickRight: null]}
                    value={name}
                    placeholder={'наименование строки'}
                    onChangeText={name => {
                        let struct = copyObject(state.struct)
                        struct.row_names[key] = name
                        save({struct})
                    }}
                    />
                    <BtnSecondary
                        visible={state.struct.row_names.length > 1}
                        style={stickLeft}
                        title={faTrash}
                        onPress={() => {
                            setAlert({
                                title: 'удалить строку?',
                                body: 'данные строки канут в Лету',
                                buttons: [
                                    {
                                        title: 'удалить',
                                        style: 'danger',
                                        onPress: () => {
                                            let struct = copyObject(state.struct)
                                            struct.row_names.splice(key, 1)
                                            struct.cells.splice(key, 1)
                                            save({struct})
                                        }
                                    },
                                    esc
                                ]
                            })

                        }}
                    />
                </Row>
            })}
            <Btn
                title={faPlus}
                onPress={() => {
                    let struct = copyObject(state.struct)
                    struct.row_names.push('')
                    struct.cells.push(new Array(struct.col_names.length))
                    save({struct})
                }}
            />
        </Row>
        <Text style={[bold, primary, mt1]}>столбцы:</Text>
        <Row>
            {state.struct.col_names.map((name, key) => {
                return <Row key={key} style={mr1}>
                    <TextInput
                        style={[field, state.struct.col_names.length > 1 ? stickRight: null]}
                        placeholder={'имя столбца'}
                        value={name}
                        onChangeText={name => {
                            let struct = copyObject(state.struct)
                            struct.col_names[key] = name
                            save({struct})
                        }}
                    />
                    <BtnSecondary
                        visible={state.struct.col_names.length > 1}
                        style={stickLeft}
                        title={faTrash}
                        onPress={() =>{
                            setAlert({
                                title: 'удалить столбец?',
                                body: 'данные столбца канут в Лету',
                                buttons: [
                                    {
                                        title: 'удалить',
                                        style: 'danger',
                                        onPress: () => {
                                            let struct = copyObject(state.struct)
                                            struct.col_names.splice(key, 1)
                                            for (let r=0; r<struct.cells.length; r++) {
                                                struct.cells[r].splice(key, 1)
                                            }
                                            save({struct})
                                        }
                                    },
                                    esc
                                ]
                            })
                        }}
                    />
                </Row>
            })}
            <Btn
                title={faPlus}
                onPress={() => {
                    let struct = copyObject(state.struct)
                    struct.col_names.push('')
                    for (let i=0; i<struct.row_names.length; i++) {
                        struct.cells[i].push('')
                    }
                    save({struct})
                }}
            />
        </Row>
    </View>
}
