import React, {useState, useEffect, useContext, useRef} from 'react';
import {Dimensions, Text, View} from 'react-native';
import {Camera} from 'expo-camera';
import Loading from "../../lib/static/Loading";
import {
    absolute, bgDanger,
    danger,
    flex,
    fontBig,
    jAround,
    jEnd,
    p1,
    white
} from "../../const";
import {Row} from "../../lib/markup/markup";
import Btn from "../../lib/buttons/Btn";
import {
    faBoltLightning,
    faCamera,
    faCameraRotate,
    faCaretLeft,
    faRulerCombined
} from "@fortawesome/free-solid-svg-icons";
import {HR} from "../../lib/static/HR";
import {isClinic} from "../../objects/user_methods";
import {ScreenContext} from "../contexts";
import storage from "../../lib/storage";
import {isDesktop} from "../../lib/sugar";

export default function (props) {

    const {user} = useContext(ScreenContext)
    const [state, setState] = useState({
        ready: false,
        type: Camera.Constants.Type.back,
        aim: isClinic(user),
        flash: 'on',
        multyCam: false
    })

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        const {status} = await Camera.requestCameraPermissionsAsync()
        const availableCams = await Camera.getAvailableCameraTypesAsync()
        updateState({ready: true, hasPermission: status === 'granted', multyCam: availableCams.length>1})
    }, [])

    const cameraRef = useRef()

    async function makePhoto(hidden = false) {
        const sizes = await cameraRef.current.getAvailablePictureSizesAsync()
        let options = {quality: 1, pictureSize: sizes[0], skipProcessing: true, exif: false}
        const {uri, width, height} = await cameraRef.current.takePictureAsync(options)
        console.log('got:', width, height, 'available:', sizes)
        // got: 640 480 available: (5) ['3840x2160', '1920x1080', '1280x720', '640x480', '352x288']
        await storage.set('cameraBuffer', {uri, hidden, width, height})
        props.navigation.goBack()
    }

    if (!state.ready) return <Loading/>
    if (!state.hasPermission) return <Text style={[fontBig, danger]}>разрешите доступ к камере</Text>;
    const {height, width} = Dimensions.get('window')
    return <View style={{height, width}}>
        <Camera
            style={[flex, jEnd]}
            type={state.type}
            flashMode={state.flash}
            ref={cameraRef}
            zoom={0}
            isActive={true}
            photo={true}
            video={true}
            audio={false}
        >
            <Row style={[jAround, {height: 80}, p1]}>
                <Btn
                    title={faCaretLeft}
                    textStyle={{fontSize: 60, ...white}}
                    onPress={() => props.navigation.goBack()}
                />
                <Btn
                    visible={state.multyCam}
                    textStyle={{fontSize: 60, ...white}}
                    title={faCameraRotate}
                    onPress={() => {
                        updateState({
                            type: Camera.Constants.Type.back ?
                                Camera.Constants.Type.front
                                :
                                Camera.Constants.Type.back
                        })
                    }}
                />
                <Btn
                    title={faCamera}
                    textStyle={{fontSize: 80, ...white}}
                    onPress={() => makePhoto()}
                />
                <Btn
                    title={faCamera}
                    textStyle={{fontSize: 80, ...danger}}
                    onPress={() => makePhoto(true)}
                />
                <Btn
                    visible={!isDesktop()}
                    title={faRulerCombined}
                    textStyle={{fontSize: 40, ...white}}
                    onPress={() => updateState({aim: !state.aim})}
                />
                <Btn
                    visible={state.flash === 'on' && !isDesktop()}
                    title={faBoltLightning}
                    textStyle={{fontSize: 40, ...white}}
                    onPress={() => updateState({flash: 'off'})}
                />
                <Btn
                    visible={state.flash === 'off' && !isDesktop()}
                    title={faBoltLightning}
                    textStyle={{fontSize: 40, ...danger}}
                    onPress={() => updateState({flash: 'on'})}
                />
            </Row>
            <HR visible={state.aim} style={[absolute, {top: height * 2 / 5}, bgDanger]}/>
            <HR visible={state.aim} style={[absolute, {left: width / 2}, bgDanger]} vertical={true}/>
        </Camera>
    </View>

}

