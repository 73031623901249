import {Text, TouchableOpacity, ScrollView, Dimensions} from "react-native";
import {Flex, Flex3, Flex4, Row} from "../../../lib/markup/markup";
import {H2} from "../../../lib/text/H";
import {
    bgPrimaryLight,
    bgSecondary,
    bold,
    center,
    flex,
    flex3,
    flex4,
    fRow,
    jCenter,
    ml1, p1,
    ph1,
    primary
} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import Table from "../../../lib/markup/Table";
import {EDate, fixWidth, isDesktop} from "../../../lib/sugar";
import PercentOfWork from "./PercentOfWork";
import {View} from "react-native";
import Btn from "../../../lib/buttons/Btn";
import {faCheck} from "@fortawesome/free-solid-svg-icons";


export default function () {
    const {GET, navigation, currentDate, isFocused} = useContext(AppContext)
    const [state, setState] = useState({ready: false, orderBy: 'id', orders: [], sort: 'id'})

    function updateState(args) {
        setState({...state, ...args})
    }

    function sort(sort) {
        updateState({sort})
    }

    useEffect(async () => {
        updateState({ready: false})
        const start = currentDate.isoDate() + ' 00:00:00'
        const stop = currentDate.isoDate() + ' 23:59:59'
        let orders = await GET(
            'main/CadCams/load_day',
            {start, stop}
        )
        orders.sort((a, b) => a[state.sort] > b[state.sort] ? 1 : -1)
        updateState({ready: true, orders, lastDate: currentDate})

    }, [currentDate, isFocused, state.sort])
    const colStyle = {...p1, ...fRow, ...flex}
    return <Flex>
        <ScrollView>
            <View style={[bgPrimaryLight, fRow]}>
                <TouchableOpacity style={flex} onPress={() => sort('clinic')}>
                    <Text numberOfLines={1} style={colStyle}>клиника</Text>
                </TouchableOpacity>
                <TouchableOpacity style={flex} onPress={() => sort('doctor')}>
                    <Text numberOfLines={1} style={colStyle}>доктор</Text>
                </TouchableOpacity>
                <TouchableOpacity style={flex} onPress={() => sort('patient')}>
                    <Text numberOfLines={1} style={colStyle}>пациент</Text>
                </TouchableOpacity>
                <TouchableOpacity style={flex} onPress={() => sort('executor')}>
                    <Text numberOfLines={1} style={colStyle}>исполнитель</Text>
                </TouchableOpacity>
            </View>
            {state.orders.map(({patient_id, patient, clinic, doctor, executor}, key) => {
                return <TouchableOpacity
                    key={key}
                    style={[fRow, key % 2 ? bgSecondary : null]}
                    onPress={() => navigation.navigate('Patient', {patient_id})}
                >
                    <Text numberOfLines={1} style={colStyle}>{clinic}</Text>
                    <Text numberOfLines={1} style={colStyle}>{doctor}</Text>
                    <Text numberOfLines={1} style={colStyle}>{patient}</Text>
                    <Text numberOfLines={1} style={colStyle}>{executor}</Text>
                </TouchableOpacity>
            })}
        </ScrollView>
    </Flex>
}



