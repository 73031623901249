import {background, borderTable, center, flex, jCenter, noBorder, noRadius, p1, stick} from "../../../const";
import {AppContext, SectionsContext} from "../../contexts";
import {useContext, useState} from "react";
import {asObject, screenSize} from "../../../lib/sugar";
import {TextInput, View, Text} from "react-native";
import Select from "../../../lib/fields/Select";
import NumberField from "../../../lib/fields/NumberField";
import Btn from "../../../lib/buttons/Btn";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {BorderFlex, BorderFlex2, BorderFlex3, BorderFlex4, BorderView, Flex, Row} from "../../../lib/markup/markup";
import {Ruble} from "../../../lib/text/Number";

export default function (props) {
    const style = [p1, flex, noBorder, noRadius]
    const {setAlert, PATCH, DELETE} = useContext(AppContext)
    const [visible, setVisible] = useState(true)
    const {sections, editable} = useContext(SectionsContext)
    const [price, setPrice] = useState({...props})

    async function savePrices(field, value) {
        let args = asObject(field, value)
        setPrice({...price, ...args})
        PATCH(`main/Prices/${props.id}`, args)
    }

    const selectWidth = screenSize() === 'desktop' ? {width: 120} : {}
    const rowHeight = {minHeight: 25}
    if (editable) {
        const fields = {
            code: <TextInput
                style={style}
                defaultValue={props.code}
                placeholder={'код'}
                onChangeText={value => savePrices('code', value)}
            />,
            name: <TextInput
                style={style}
                defaultValue={props.name}
                placeholder={'сокращение'}
                onChangeText={value => savePrices('name', value)}
            />,
            section: <Select
                style={[stick, props.background, noBorder, selectWidth]}
                items={sections}
                selected={props.section}
                placeholder={'выберите категорию'}
                onChange={async value => {
                    await savePrices('section', value)
                    setVisible(false)
                }}
                canBeNull={false}
            />,
            description: <TextInput
                multiline
                numberOfLines={3}
                style={style}
                defaultValue={props.description}
                placeholder={'описание'}
                onChangeText={value => savePrices('description', value)}
            />,
            // scope_of_work: <NumberField
            //     style={style}
            //     value={String(props.scope_of_work)}
            //     placeholder={'чел.ч.'}
            //     onChangeText={value => savePrices('scope_of_work', value)}
            // />,
            production_time: <NumberField
                style={style}
                value={String(props.production_time)}
                placeholder={'срок'}
                onChangeText={value => savePrices('production_time', value)}
            />,
            cost: <NumberField
                style={style}
                value={String(props.cost)}
                placeholder={'цена'}
                onChangeText={value => savePrices('cost', value)}
            />,
            remove: <Btn
                title={faTrash}
                onPress={() => setAlert({
                    title: 'удалить позицию?',
                    body: props.name,
                    remove: () => {
                        DELETE(`main/prices/${props.id}`)
                        setVisible(false)
                    }
                })}
            />
        }
        if (!visible) return null

        return <View style={props.background}>
            {screenSize() === 'desktop' ?
                <View>
                    <Row style={rowHeight}>
                        <BorderView style={{width: 60}}>{fields.code}</BorderView>
                        <BorderFlex>{fields.name}</BorderFlex>
                        <BorderView style={{width: selectWidth}}>{fields.section}</BorderView>
                        <BorderView style={{width: 80}}>{fields.cost}</BorderView>
                        <BorderView style={{width: 60}}>{fields.production_time}</BorderView>
                        <BorderView style={{width: 60}}>{fields.scope_of_work}</BorderView>
                        <BorderView style={[{width: 60}, jCenter, center, borderTable]}>{fields.remove}</BorderView>
                    </Row>
                    <Row style={rowHeight}>
                        <BorderFlex>{fields.description}</BorderFlex>
                    </Row>
                </View>
                :
                <View>
                    <Row style={rowHeight}>
                        <BorderFlex>{fields.code}</BorderFlex>
                        <BorderFlex4>{fields.name}</BorderFlex4>
                    </Row>
                    <Row style={rowHeight}>
                        <BorderFlex3>{fields.section}</BorderFlex3>
                        <BorderFlex style={[jCenter, center, borderTable]}>
                            {fields.remove}
                        </BorderFlex>
                    </Row>
                    <Row style={rowHeight}>
                        <BorderFlex>{fields.description}</BorderFlex>
                    </Row>
                    <Row style={rowHeight}>
                        <BorderFlex2>{fields.cost}</BorderFlex2>
                        <BorderFlex>{fields.production_time}</BorderFlex>
                        <BorderFlex>{fields.scope_of_work}</BorderFlex>
                    </Row>
                </View>
            }
        </View>
    }
    return screenSize() === 'desktop' ?
        <Row style={[props.background, rowHeight]}>
            <BorderView style={[{width: 60, p1}]}>
                <Flex style={[jCenter, center]}>
                    <Text>{props.code}</Text>
                </Flex>
            </BorderView>
            <BorderFlex style={p1}>
                <Text>{props.description}</Text>
            </BorderFlex>
            <BorderView style={[{width: 100, jCenter, center, p1}]}>
                <Flex style={[jCenter, center]}>
                    <Ruble>{props.cost}</Ruble>
                </Flex>
            </BorderView>
        </Row>
        :
        <Row style={[background, rowHeight]}>
            <BorderView style={[{width: 70}, p1]}>
                <Text>{props.code}</Text>
                <Ruble>{props.cost}</Ruble>
            </BorderView>
            <BorderFlex style={p1}>
                <Text>{props.description}</Text>
            </BorderFlex>
        </Row>
}
