import {View, Text, TouchableOpacity, StyleSheet, Modal} from "react-native";
import {PressRow, Row} from "../../../lib/markup/markup";
import {
    bgDangerLight,
    bgPrimaryLight, bgSecondary, bold, border,
    borderTable,
    center, danger, dangerLight,
    flex,
    fRow,
    jCenter,
    m1, mh1, mr1, mt1,
    p1,
    primary, shadow,
    white
} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext, WarehouseBoxContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {fixHeight, fixWidth} from "../../../lib/sugar";
import {isSuperuser, isWarehouse} from "../../../objects/user_methods";


export default function (props) {
    const {navigation, user} = useContext(AppContext)
    const {updateState, ready} = useContext(WarehouseBoxContext)
    const url = 'lab/tables/'

    if (!ready) return <Loading/>
    const colHeight = fixHeight(20)
    let colWidths = new Array(props.struct.col_names.length + 1)
    colWidths[0] = 0
    for (let row of props.struct.row_names) {
        if (row.length > colWidths[0]) colWidths[0] = row.length
    }
    for (let n = 0; n < props.struct.col_names.length; n++) {
        colWidths[n + 1] = props.struct.col_names[n].length
    }
    let maxWidth = 0
    for (let n = 0; n < colWidths.length; n++) {
        let width = colWidths[n] * 10 + 6
        if (width < 20) width = 20
        maxWidth += width
        colWidths[n] = fixWidth(width)
    }

    const cellStyle = StyleSheet.flatten([center, colHeight, borderTable, jCenter])
    return <View style={m1}>
        <PressRow
            style={[borderTable, center, white, bgPrimaryLight, p1, fixWidth(maxWidth)]}
            onPress={() => navigation.navigate('TableEdit', {table: props, url})}
        >
            <Text style={[white, fRow, fixWidth(maxWidth)]} numberOfLines={1}>
                {props.name}
            </Text>
        </PressRow>
        <Row>
            <View style={[colWidths[0], cellStyle, bgPrimaryLight]}/>
            {props.struct.col_names.map((name, key) => {
                return <View key={key} style={[colWidths[key + 1], cellStyle, bgPrimaryLight]}>
                    <Text>{name}</Text>
                </View>
            })}
        </Row>
        {
            props.struct.cells.map((row, key) => {
                return <Row key={key}>
                    <View style={[colWidths[0], cellStyle, bgPrimaryLight]}>
                        <Text>{props.struct.row_names[key]}</Text>
                    </View>
                    {
                        row.map((cell, key1) => {
                            return <TouchableOpacity
                                style={[colWidths[key1 + 1], cellStyle, +cell ? null: bgSecondary]}
                                key={key1}
                                onPress={() => {
                                    if (!isWarehouse(user) && !isSuperuser(user)) return
                                    updateState({
                                        modal: {
                                            col: key1,
                                            row: key,
                                            visible: true,
                                            index: props.index
                                        }
                                    })
                                }}
                            >
                                <Text style={+cell < +props.alert_then ? [dangerLight, bold]: null}>
                                    {+cell ? cell: null}</Text>
                            </TouchableOpacity>
                        })
                    }
                </Row>
            })
        }
    </View>
}
