import {asObject, screenSize} from "../sugar";
import React, {useEffect, useRef, useState} from "react";
import {Dimensions, Modal, Platform, Pressable, ScrollView, Text, View} from "react-native";
import {
    absolute,
    bold,
    border,
    center,
    field,
    flex,
    fRow,
    mb1,
    mt1,
    primary,
    jRow,
    secondary,
    jCenter, ph1
} from "../../const";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import FA from "../static/FA";

export default function ({
                             state = false,
                             style = {},
                             value = 'id',
                             label = 'name',
                             selected = null,
                             canBeNull = true,
                             items = [],
                             visible = true,
                             placeholder = null,
                             onChange = null,
                             editable = true
                         }) {
    if (!visible) return null
    const placeholderValue = (placeholder || 'placeholder') + '...'
    let options = canBeNull ? [{value: null, label: placeholderValue}] : []

    let currentIndex = 0
    for (let n = 0; n < items.length; n++) {
        let obj = asObject('label', items[n][label])
        obj.value = items[n][value]
        options.push(obj)
        if (obj.value === selected) currentIndex = options.length - 1
    }

    const [current, setCurrent] = useState(currentIndex);
    const [open, setOpen] = useState(Boolean(state))

    if (!editable) {
        return <View style={[jCenter, ph1, {height: field.height}, style]}>
            <Text numberOfLines={1} style={[jRow]}>
                {options.length ? options[current].label : placeholderValue}
            </Text>
        </View>
    }

    const [modalStyle, setModalStyle] = useState(
        screenSize() === 'desktop' ?
            {height: 0, width: 0, top: 0, left: 0}
            :
            {height: '70%', width: '90%', top: '10%', left: '5%'}
    )
    const componentRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (!open) return
            if (Platform.OS === 'web') {
                componentRef.current.measure((width, height, px, py, fx, fy) => {
                    let _height = field.height * options.length
                    const maxHeight = Dimensions.get('window').height - field.height * 2
                    if (maxHeight < _height) _height = maxHeight
                    let top = fy - _height / 2 + field.height / 2
                    if (top < field.height) top = field.height
                    setModalStyle({height: _height, top, left: fx})
                })
            }
        }, 200)

    }, [open])

    return <View
        ref={componentRef}
    >
        <Pressable
            style={[field, fRow, center, flex, style]}
            onPress={() => {
                setOpen(!open)
            }}
        >
            <Text numberOfLines={1} style={[flex, jRow]}>
                {options.length ? options[current].label : placeholderValue}
            </Text>
            <FA icon={open ? faChevronUp : faChevronDown}/>
        </Pressable>

        <Modal animationType={'fade'} visible={open} transparent>
            <Pressable style={[flex, primary]} onPress={() => setOpen(false)}>
                <ScrollView style={{
                    backgroundColor: field.backgroundColor,
                    width: style.width || field.width,
                    paddingHorizontal: 20,
                    ...modalStyle,
                    ...border,
                    ...absolute,
                }}>
                    {options.map((option, key) => {
                        return <Pressable
                            key={key}
                            style={[
                                key ? null : mt1,
                                mb1
                            ]}
                            onPress={() => {
                                if (onChange) onChange(option.value)
                                setCurrent(key)
                                setOpen(false)
                            }}
                        >
                            <Text
                                style={[
                                    canBeNull && placeholder && !key ? secondary : {},
                                    key === current ? bold : {},
                                ]}
                            >
                                {option.label}
                            </Text>
                        </Pressable>
                    })}
                </ScrollView>
            </Pressable>
        </Modal>
    </View>
}
