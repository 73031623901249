import {bold} from "./const";

export const shoulders = [
    {id: '0.3', name: '0.3'},
    {id: '0.5', name: '0.5'},
    {id: '1.0', name: '1.0'}
]

export const teethShapes = [
    {id: 'квадрат', name: 'квадрат'},
    {id: 'треугольник', name: 'треугольник'},
    {id: 'овал', name: 'овал'}
]

export const shades = [
    {id: 'BL1', name: 'BL1'},
    {id: 'BL2', name: 'BL2'},
    {id: 'BL3', name: 'BL3'},
    {id: 'BL4', name: 'BL4'},
    {id: 'A1', name: 'A1'},
    {id: 'A2', name: 'A2'},
    {id: 'A3', name: 'A3'},
    {id: 'A35', name: 'A3.5'},
    {id: 'A4', name: 'A4'},
    {id: 'B1', name: 'B1'},
    {id: 'B2', name: 'B2'},
    {id: 'B3', name: 'B3'},
    {id: 'B4', name: 'B4'},
    {id: 'C1', name: 'C1'},
    {id: 'C2', name: 'C2'},
    {id: 'C3', name: 'C3'},
    {id: 'C4', name: 'C4'},
    {id: 'D2', name: 'D2'},
    {id: 'D3', name: 'D3'},
    {id: 'D4', name: 'D4'},
]

export const orderStatus = [
    'IN_CLINIC_DONT_CALLED',
    'IN_CLINIC_WAS_CALLED',
    'IN_CLINIC_ON_WAY',
    'TO_LAB_ON_WAY',
    'IN_LAB_ON_WORK',
    'IN_LAB_WAITING',
    'TO_CLINIC',
    'WAITING_FOR_PAYMENT',
]

export const bgDomostom = {backgroundColor: '#64e489'}
export const bgAttention = {backgroundColor: '#fca678'}
export const bgPickup = {backgroundColor: '#54D6FF'}
export const bgUnpaid = {backgroundColor: '#ff88ee'}
export const bgMoney = {backgroundColor: '#e4ce1b'}
export const colorPersonal = {...bold, color: '#c700c7'}

export const host = 'https://api.creative-lab.online'
