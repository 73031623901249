import {useContext} from "react";
import {CurrentUserContext} from "../../contexts";
import {View} from "react-native";
import {Col, Row} from "../../../lib/markup/markup";
import {EDate, screenSize} from "../../../lib/sugar";
import {Date} from "../../../lib/fields/Date";
import {field, ml1} from "../../../const";
import {TextInput} from "react-native";

export default function ({fields}) {
    const {currentUser, save, reload} = useContext(CurrentUserContext)
    return <Col>
        <Row>
            <View style={screenSize() === 'desktop' ? {flexDirection: 'row'} : {}}>
                {fields.map((arg, key) => {
                        if (arg.length === 3) {
                            return <View style={{width: 115}}><Date
                                useButtons={false}
                                showYear={true}
                                weekdayMode={false}
                                style={key && screenSize() === 'desktop' ? ml1 : {}}
                                key={key}
                                date={currentUser[arg[1]] ? new EDate(currentUser[arg[1]]) : null}
                                onChange={text => {
                                    save(arg[1], text.isoDate())
                                    reload()
                                }}
                                placeholder={arg[0]}
                            /></View>
                        }
                        return <TextInput
                            style={[key && screenSize() === 'desktop' ? ml1 : {}, field]}
                            key={key}
                            placeholder={arg[0]}
                            defaultValue={currentUser[arg[1]]}
                            onChangeText={text => save(arg[1], text)}
                        />
                    }
                )}
            </View>
        </Row>
    </Col>
}
