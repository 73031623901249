import {TouchableOpacity, ScrollView, View} from "react-native";
import {Row} from "../../../lib/markup/markup";
import {H3} from "../../../lib/text/H";
import {bgPrimaryLight, bgSecondary, border, center, flex, fRow, jBetween, mb1, ml1, mt1, p1} from "../../../const";
import {useContext, useEffect, useRef, useState} from "react";
import {AppContext, CouriersContext} from "../../contexts";
import Phone from "../../../lib/text/Phone";
import Task from "./Task";


export default function ({code, id, phone, is_cadcam_courier}) {
    const {navigation} = useContext(AppContext)
    const {tasks, updateState, deliveryNumber} = useContext(CouriersContext)
    const scrollViewRef = useRef()
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        setRefresh(!refresh)
    }, [deliveryNumber])

    return <View
        style={[
            {width: 290, height: 400, maxHeight: 500},
            is_cadcam_courier ? bgPrimaryLight :bgSecondary,
            border,
            ml1,
            mt1,
            p1
        ]}
    >
        <Row style={[jBetween, center, mb1]}>
            <TouchableOpacity
                style={flex}
                onPress={async () => {
                    for (let i=0; i<tasks.length; i++) {
                        if (tasks[i].selected) await tasks[i].setCourier(id)
                    }
                    updateState({tasks})
                }}
            >
                <H3>{code}</H3>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('UserProfile', {user_id: id})}>
                <Phone>{phone}</Phone>
            </TouchableOpacity>
        </Row>

            <ScrollView
                ref={scrollViewRef}
                onContentSizeChange={() => scrollViewRef?.current?.scrollToEnd({animated: false})}
            >
                {tasks.map((task, key) => {
                    if (task.courier !== id || task.deliveryNumber !== deliveryNumber) return null
                    return <Task key={key} index={key}/>
                })}
            </ScrollView>

    </View>
}
