import {border, btnSuccess} from "../../const";
import Btn from "./Btn";

export function BtnSuccess({
                               title = null,
                               onPress,
                               onLongPress,
                               wrpStyle = {},
                               textStyle = {},
                               bgStyle = {},
                               style = {},
                               disabled = false,
                               size = 'normal',
                               visible = true
                           }) {
    return <Btn
        title={title}
        onPress={onPress}
        onLongPress={onLongPress}
        wrpStyle={[wrpStyle, style]}
        textStyle={[btnSuccess.text, textStyle]}
        bgStyle={[border, bgStyle, style]}
        disabled={disabled}
        colors={btnSuccess.background}
        size={size}
        visible={visible}
        style={style}
    />
}
