import {Dimensions, StyleSheet} from "react-native";

export const fontBig = {fontSize: 20}
export const fontNormal = {fontSize: 16}
export const fontSmall = {fontSize: 12}
export const borderRadius = {borderRadius: 5}
export const borderColor = {borderColor: '#777'}
export const borderWidth = {borderWidth: 1}
export const borderStyle = {borderStyle: 'solid'}
export const border = {...borderWidth, ...borderColor, ...borderRadius, ...borderStyle}
export const noBorder = {borderColor: 'transparent'}
export const noRadius = {borderRadius: 0}
export const borderTable = {...borderWidth, ...borderColor, ...borderStyle, ...noRadius}

export const textShadow = {
    textShadowColor: '#fff',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 10
}

export const primary = {
    color: '#004369'
}

export const primaryLight = {
    color: '#009dd9'
}

export const secondary = {
    color: "#afafaf"
}

export const secondaryLight = {
    color: "#dedede"
}

export const danger = {
    color: "#b11f1f"
}

export const dangerLight = {
    color: "#ff2828"
}

export const warning = {
    color: "#c35103"
}

export const warningLight = {
    color: "#ff7800"
}

export const success = {
    color: "#278400"
}

export const successLight = {
    color: "#2eb700"
}

export const btnPrimary = {
    text: {color: '#fff'},
    background: [primaryLight.color, primary.color, primaryLight.color]
}

export const btnSecondary = {
    text: {color: '#000'},
    background: [secondaryLight.color, secondary.color, secondaryLight.color]
}

export const btnDanger = {
    text: {color: '#fff'},
    background: [dangerLight.color, danger.color, dangerLight.color]
}

export const btnWarning = {
    text: {color: '#fff'},
    background: [warningLight.color, warning.color, warningLight.color]
}

export const btnSuccess = {
    text: {color: '#fff'},
    background: [successLight.color, success.color, successLight.color]
}

export const btnLink = {
    text: {color: '#000'},
    background: ['transparent', 'transparent']
}

export const font = {}
export const fontTitle = {
    color: '#004369'
}

export const primarySplash = {
    backgroundColor: '#c4f0ff'
}

export const bgPrimary = {
    backgroundColor: primary.color
}

export const bgPrimaryLight = {
    backgroundColor: primaryLight.color
}

export const bgSecondary = {
    backgroundColor: secondary.color
}

export const bgSecondaryLight = {
    backgroundColor: secondaryLight.color
}

export const bgDanger = {
    backgroundColor: danger.color
}

export const bgDangerLight = {
    backgroundColor: dangerLight.color
}

export const bgWarning = {
    backgroundColor: warning.color
}

export const bgWarningLight = {
    backgroundColor: warningLight.color
}

export const bgSuccess = {
    backgroundColor: success.color
}

export const bgSuccessLight = {
    backgroundColor: successLight.color
}

export const bgWhite = {
    backgroundColor: '#fff'
}
export const bgTransparent = {
    backgroundColor: 'transparent'
}

export const shadow = {
    shadowColor: '#000',
    // shadowOffset: {width: 20, height: 20},
    shadowOpacity: 0.9,
    shadowRadius: 20,
    elevation: 20
}

export const bold = {fontWeight: 'bold'}
export const italic = {fontStyle: 'italic'}

export const red = {color: 'red'}
export const darkred = {color: 'darkred'}
export const green = {color: 'green'}
export const white = {color: 'white'}
export const black = {color: 'black'}
export const blue = {color: 'blue'}
export const transparent = {color: 'transparent'}
export const background = {
    header: {backgroundColor: '#fff'},
    body: {backgroundColor: '#ddd'}
}

export const height = {
    height: 40,
    maxHeight: 40,
    minHeight: 40
}

export const heightNormal = {
    height: 30,
    maxHeight: 30,
    minHeight: 30
}

export const heightSmall = {
    height: 20,
    maxHeight: 20,
    minHeight: 20
}

export const heightBig = {
    height: 40,
    maxHeight: 40,
    minHeight: 40
}

export const width = 40
export const widthSmall = {minWidth: 30}
export const widthNormal = {minWidth: 40}
export const widthBig = {minWidth: 50}

export const absolute = {position: 'absolute'}

export const margin = {margin: 5}
export const ml0 = {marginLeft: 0}
export const ml1 = {marginLeft: 5}
export const ml2 = {marginLeft: 10}
export const ml3 = {marginLeft: 15}
export const ml4 = {marginLeft: 20}
export const ml5 = {marginLeft: 25}

export const mr0 = {marginRight: 0}
export const mr1 = {marginRight: 5}
export const mr2 = {marginRight: 10}
export const mr3 = {marginRight: 15}
export const mr4 = {marginRight: 20}
export const mr5 = {marginRight: 25}

export const m0 = {margin: 0}
export const m1 = {margin: 5}
export const m2 = {margin: 10}
export const m3 = {margin: 15}
export const m4 = {margin: 20}
export const m5 = {margin: 25}

export const mb0 = {marginBottom: 0}
export const mb1 = {marginBottom: 5}
export const mb2 = {marginBottom: 10}
export const mb3 = {marginBottom: 15}
export const mb4 = {marginBottom: 20}
export const mb5 = {marginBottom: 25}

export const mt0 = {marginTop: 0}
export const mt1 = {marginTop: 5}
export const mt2 = {marginTop: 10}
export const mt3 = {marginTop: 15}
export const mt4 = {marginTop: 20}
export const mt5 = {marginTop: 25}

export const mv0 = {marginVertical: 0}
export const mv1 = {marginVertical: 5}
export const mv2 = {marginVertical: 10}
export const mv3 = {marginVertical: 15}
export const mv4 = {marginVertical: 20}
export const mv5 = {marginVertical: 25}

export const mh0 = {marginHorizontal: 0}
export const mh1 = {marginHorizontal: 5}
export const mh2 = {marginHorizontal: 10}
export const mh3 = {marginHorizontal: 15}
export const mh4 = {marginHorizontal: 20}
export const mh5 = {marginHorizontal: 25}

export const p0 = {padding: 0}
export const p1 = {padding: 5}
export const p2 = {padding: 10}
export const p3 = {padding: 15}

export const pt0 = {paddingTop: 0}
export const pt1 = {paddingTop: 5}
export const pt2 = {paddingTop: 10}
export const pt3 = {paddingTop: 15}

export const pb0 = {paddingBottom: 0}
export const pb1 = {paddingBottom: 5}
export const pb2 = {paddingBottom: 10}
export const pb3 = {paddingBottom: 15}

export const pr0 = {paddingRight: 0}
export const pr1 = {paddingRight: 5}
export const pr2 = {paddingRight: 10}
export const pr3 = {paddingRight: 15}

export const pl0 = {paddingLeft: 0}
export const pl1 = {paddingLeft: 5}
export const pl2 = {paddingLeft: 10}
export const pl3 = {paddingLeft: 15}

export const ph0 = {paddingHorizontal: 0}
export const ph1 = {paddingHorizontal: 5}
export const ph2 = {paddingHorizontal: 10}
export const ph3 = {paddingHorizontal: 15}

export const pv0 = {paddingVertical: 0}
export const pv1 = {paddingVertical: 5}
export const pv2 = {paddingVertical: 10}
export const pv3 = {paddingVertical: 15}

export const padding = {paddingVertical: 10, paddingHorizontal: 5}

export const maxScreenWidth = {maxWidth: 1200}
export const minWidth = {minWidth: 40}
export const maxWidth = {maxWidth: 300}
export const w100 = {width: '100%'}
export const w75 = {width: '75%'}
export const w50 = {width: '50%'}
export const w33 = {width: '33%'}
export const w25 = {width: '25%'}
export const w20 = {width: '20%'}
export const w15 = {width: '15%'}

export const h100 = {height: '100%'}
export const h75 = {height: '75%'}
export const h50 = {height: '50%'}
export const h33 = {height: '33%'}
export const h25 = {height: '25%'}

export const block100 = {...w100, ...h100}
export const block75 = {...w75, ...h75}
export const block50 = {...w50, ...h50}
export const block33 = {...w33, ...h33}
export const block25 = {...w25, ...h25}

export const jRow = {flexDirection: 'row'}
export const fRow = {flexDirection: 'row'}
export const fWrap = {flexWrap: 'wrap'}
export const noWrap = {flexWrap: 'nowrap'}
export const flexStart = {justifyContent: 'flex-start', textAlign: 'left'}
export const flexEnd = {alignItems: 'flex-end', textAlign: 'right'}
export const center = {alignItems: 'center', textAlign: 'center'}
export const flexCenter = {justifyContent: 'center'}

export const jStart = {justifyContent: 'flex-start'}
export const jEnd = {justifyContent: 'flex-end'}
export const jCenter = {justifyContent: 'center'}
export const jAround = {justifyContent: 'space-around'}
export const jBetween = {justifyContent: 'space-between'}
export const flex = {flex: 1}
export const flex1 = {flex: 1}
export const flex2 = {flex: 2}
export const flex3 = {flex: 3}
export const flex4 = {flex: 4}
export const flex5 = {flex: 5}
export const flex6 = {flex: 6}

export const stickRight = {
    marginRight: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0
}

export const stickLeft = {
    marginLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
}

export const stickBottom = {
    marginBottom: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
}

export const stickTop = {
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
}

export const stick = {
    ...stickLeft,
    ...stickRight
}

export const itemWidth = {
    minWidth: 80,
    ...maxWidth,
    width: 250
}

export const field = StyleSheet.flatten({
    backgroundColor: '#fff',
    ...itemWidth,
    ...heightNormal,
    ...border,
    ...ph1,
})

export const flexField = StyleSheet.flatten({
    backgroundColor: '#fff',
    ...flex,
    ...heightNormal,
    ...border,
    ...ph1,
})

export const splash = {
    backgroundColor: '#000',
    opacity: 0.8,
}

export const esc = {title: 'отмена'}

export const emptyTime = '--:--'


export function getFieldStyles(style) {
    const wrpStyle = StyleSheet.flatten([
        {
            ...borderWidth,
            ...borderColor,
            ...borderRadius,
            ...borderStyle,
        },
        style
    ])

    const fieldStyle = StyleSheet.flatten(
        [flex, ph1, {backgroundColor: field.backgroundColor}, noBorder])
    return [wrpStyle, fieldStyle]
}

export const colors = {
    primary,
    primaryLight,
    secondary,
    secondaryLight,
    success,
    successLight,
    warning,
    warningLight,
    danger,
    dangerLight
}







