import {View} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../screens/contexts";
import {isDesktop} from "../../lib/sugar";
import TechRow from "./TechRow";

export default function ({statuses, stage, setStatuses}) {
    const [state, setState] = useState({statuses, table: null, stage})
    const {getTechs} = useContext(AppContext)
    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let data = Object.values(await getTechs())
        data.sort((a, b) => {
            if (a.code > b.code) return 1
            return -1
        })
        let table = [[]]
        let count = 0
        const maxSize = isDesktop() ? 5 : 4
        for (let tech of data) {
            if (!tech.code) continue
            if (count === maxSize) {
                table.push([])
                count = 0
            }
            table[table.length -1 ].push(tech)
            count++
        }
        updateState({table})
    }, [state.statuses])
    if (!state.table) return null

    return <View>
            {state.table.map((row, key) => <TechRow
                row={row}
                key={key}
                stageID={state.stage.stage_id}
                statuses={state.stage.statuses}
                setStatuses={statuses => {
                    setStatuses(statuses)
                    updateState({stage: {...state.stage, statuses}})
                }}
            />)}
        </View>
}

