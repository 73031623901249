import {Text} from "react-native";
import {bold, italic} from "../../const";
import React from "react";
import {capitalize} from "../sugar";

export function B({children, style, visible=true}) {
    if (!visible) return null
    return <Text style={[bold, style]}>{children}</Text>
}

export function I({children, style, visible=true}) {
    if (!visible) return null
    return <Text style={[italic, style]}>{children}</Text>
}

export function BI({children, style, visible=true}) {
    if (!visible) return null
    return <Text style={[bold, italic, style]}>{children}</Text>
}

export function Capitalize({children, style}) {
    return <Text style={style}>{capitalize(String(children))}</Text>
}
