import {View, ScrollView, Text} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import {bgPrimaryLight, borderTable, field, jCenter, p1, pv1} from "../../../const";
import {Date} from "../../../lib/fields/Date";
import {Flex, PressRow, Row} from "../../../lib/markup/markup";
import Loading from "../../../lib/static/Loading";
import {H3} from "../../../lib/text/H";
import {TechStatus} from "../../../components/delivery_time/Statuses";


export default function JobList({techID}) {
    const [state, setState] = useState({list: [], ready: false})
    const {currentDate, GET} = useContext(AppContext)

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        updateState({ready: false})

        const start = currentDate.copy()
        let stop = currentDate.copy()
        stop.change({days: 21})

        const response = await GET(
            'main/stages/filter',
            {
                lab_date__range: [start.isoDate(), stop.isoDate()],
                statuses__tech__id: techID
            }
        )

        let list = []
        let date = null

        for (let stage of response) {
            if (!date || stage.lab_date !== date) {
                date = stage.lab_date
                list.push(date)
            }
            list.push(stage)
        }
        updateState({list, ready: true})
    }, [currentDate])

    if (!state.ready) return <Loading/>
    if (!state.list.length) return <H3>сейчас ничего нет</H3>
    return <Flex>
        <ScrollView style={{maxWidth: 500}}>
        {state.list.map((obj, key) => {
            if (typeof obj === 'string') {
                return <Row key={key} style={[bgPrimaryLight, borderTable, jCenter, pv1]}>
                    <Date editable={false} date={obj} showYear={false} weekdayMode={'full'}/>
                </Row>
            } else return <Stage {...obj} key={key} techID={techID}/>
        })}
        </ScrollView>
    </Flex>
}

function Stage(props) {
    const [state, setState] = useState({
        clinic: null, doctor: null, patient: null, techStageStatus: null, ready: false})
    const {navigation, GET, getUser, getClinic} = useContext(AppContext)
    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        const response = await GET('main/patients/filter', {orders: props.order})
        const patient = response[0]
        const doctor = await getUser(patient.doctor)
        let clinic = await getClinic(patient.clinic)
        if (clinic) clinic = clinic.name
        let techStageStatus
        for (let status of props.statuses) {
            if (status.tech === props.techID) techStageStatus = status
        }
        updateState({clinic, doctor, patient, techStageStatus, ready: true})
    }, [])

    if (!state.ready) return <Loading/>
    return <Row style={[{height: field.height}, p1, borderTable, state.techStageStatus.status !== 1 ? {backgroundColor: '#9bff9b'} : null]}>
        <View style={[{width: 40}]}>
            <TechStatus
                techStageStatus={state.techStageStatus}
                patientID={state.patient.id}
                statusOnly={true}
                orderID={props.order}
            />
        </View>
        <Flex>
            <PressRow
                onPress={() => navigation.navigate('Patient', {patient_id: state.patient.id})}
            >
                <Flex>
                    <Text numberOfLines={1}>{state.clinic}</Text>
                </Flex>
                <Flex>
                    <Text numberOfLines={1}>{state.doctor.last_name}</Text>
                </Flex>
                <Flex>
                    <Text numberOfLines={1}>{state.patient.last_name}</Text>
                </Flex>
            </PressRow>
        </Flex>
    </Row>
}
