import {Dimensions, Text} from "react-native";
import {Row} from "../../../lib/markup/markup";
import {bold, fRow, mr2, primary} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import {View} from "react-native";

export default function () {
    const [percent, setPercent] = useState([])
    const {currentDate, GET} = useContext(AppContext)

    useEffect(async () => {
        let isMounted = true
        const start = currentDate.isoDate() + ' 00:00:00'
        const stop = currentDate.isoDate() + ' 23:59:59'
        const response = await GET('lab/percent_of_work', {start, stop})
        let sum = 0
        for (let obj of response) {
            sum += +obj[1]
        }
        let percent = []
        for (let obj of response) {
            percent.push({
                'last_name': obj[0],
                'percent': Math.round(+obj[1] * 100 / sum)
            })
        }
        percent.sort((a, b) => {
            if (a.last_name > b.last_name) return 1
            return -1
        })
        if (isMounted) setPercent(percent)
        return () => isMounted=false
    }, [])
    if (!percent.length) return null
    return <Row style={{maxWidth: Dimensions.get('window').width}}>
        {percent.map(({last_name, percent}, key) => <Row style={mr2} key={key}>
            <Text style={primary}>{last_name}:</Text>
            <Text style={bold}>{percent}%</Text>
        </Row>)}
    </Row>
}
