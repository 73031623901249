import {EDate, fixWidth, screenSize} from "../../../lib/sugar";
import {p0, p1} from "../../../const";
import {useContext} from "react";
import {AppContext} from "../../contexts";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {api} from "../../../lib/api";
import {Text} from "react-native";
import {BorderFlex, BorderFlex4, BorderView, Row} from "../../../lib/markup/markup";
import BtnSecondary from "../../../lib/buttons/BtnSecondary";
import {Date} from "../../../lib/fields/Date";
import Image from "../../../lib/static/Image";
import {host} from "../../../labConsts";

export default function (props) {
    const desktopOnly = screenSize() === 'desktop'
    const style = p1
    const {setAlert} = useContext(AppContext)
    return <Row style={[props.background, fixWidth(800)]}>
        <BorderView style={[style, p0]}>
            <BtnSecondary
                title={faDownload}
                onPress={() => {
                    setAlert({
                        title: 'куда загрузить?',
                        body: 'ПК должен быть включен :)',
                        vertical: true,
                        buttons: props.hosts.map((host, key) => {
                            return {
                                onPress: () => props.upload(props.id, host.id),
                                title: host.name,
                                style: host.id === api.user.host ? 'primary' : 'secondary'
                            }
                        })
                    })
                }}
            />
        </BorderView>
        <BorderView style={[{width: 80}, style]}>
            <Date date={new EDate(props.date)} editable={false} weekdayMode={false}/>
        </BorderView>
        <BorderFlex style={style}>
            <Text>{props.practice}</Text>
        </BorderFlex>
        <BorderFlex style={style}>
            <Text>{props.patient}</Text>
        </BorderFlex>
        <BorderFlex4 style={style} visible={desktopOnly}>
            <Text>{props.path}</Text>
        </BorderFlex4>
        <BorderView style={{width: 80, ...style}} visible={desktopOnly}>
            <Text>{props.size}МБ</Text>
        </BorderView>
        <BorderView style={{width: 50, ...style, ...p0}}>
            <Image url={host +'/media/' + props.preview} style={{height: 60, width:50}}/>
        </BorderView>
    </Row>
}
