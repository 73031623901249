import {View, Text} from "react-native";
import {Flex, FlexRow, PressRow} from "../../../lib/markup/markup";
import {
    bgSecondaryLight, bgWarningLight,
    border, center, dangerLight, esc, flex,
    mt1,
    p1, primary, primaryLight,
    stickLeft,
    stickRight,
    success
} from "../../../const";
import {useContext} from "react";
import {AppContext, CouriersContext} from "../../contexts";
import TextField from "../../../lib/fields/TextField";
import {Time} from "../../../lib/fields/Time";
import {isManager} from "../../../objects/user_methods";
import Phone from "../../../lib/text/Phone";


export default function ({index}) {
    const {user, setAlert, route} = useContext(AppContext)
    let {updateState, tasks} = useContext(CouriersContext)
    let task = tasks[index]

    async function saveTask(field, value) {
        await tasks[index].edit(field, value)
        updateState({tasks})
    }

    function selectTask() {
        if (!isManager(user)) return
        tasks[index].selected = !tasks[index].selected
        updateState({tasks})
    }

    // const body = <View>
    //     <Text>{task?.clinic?.address}</Text>
    //     <Phone>{task?.clinic?.phone}</Phone>
    // </View>

    return <View
        style={[
            p1,
            border,
            task?.selected ? bgWarningLight : bgSecondaryLight,
            !task.confirmed && task.courier ? {borderColor: dangerLight.color} : null
        ]}
    >
        <FlexRow style={center}>
            <PressRow
                style={{width: 15}}
                onPress={() => setAlert({
                    body: task.stages.map((stage, i) => stage.patient).join(', ')
                })}
            >
                <Text style={dangerLight}>{task.calls.length ? 'В' : ''}</Text>
                {task.stages.length ? <Text style={primaryLight}>{task.stages.length}</Text> : null}
            </PressRow>
            <PressRow
                style={flex}
                onPress={async () => {
                    if (task.finished_at) return
                    if (task.confirmed && !task.selected) {
                        setAlert({
                            title: 'задача отправлена',
                            body: 'вы действительно хотите переназначить задание?',
                            buttons: [
                                {
                                    title: 'переназначить',
                                    style: 'primary',
                                    onPress: selectTask
                                },
                                esc
                            ]
                        })
                    } else await selectTask()
                }}
            >

                <View style={{width: 55}}>
                    <Time
                        time={task.lab_time}
                        visible={route.name === 'Couriers'}
                        editable={false}
                    />
                </View>
                <Flex>
                    <Text style={!task.confirmed && task.courier ? dangerLight : primary}>{task.direction}</Text>
                </Flex>
                <View style={{width: 60}}>
                    <Time
                        style={task.finished_at ? success : null}
                        time={task.finished_at}
                        editable={false}
                    />
                </View>
            </PressRow>
        </FlexRow>
        <FlexRow visible={task.courier}>
            <Time
                time={task.courier_time}
                editable={route.name === 'Couriers'}
                onChange={courier_time => saveTask('courier_time', courier_time)}
                style={stickRight}
            />
            <TextField
                style={stickLeft}
                placeholder={'комментарий'}
                onChange={comment => saveTask('comment', comment)}
                value={task.comment}
            />
        </FlexRow>
        <View style={{height: mt1.marginTop}}/>
    </View>
}
