import React from "react";
import {Video} from 'expo-av';

export default function ({url, style}) {
    return <Video
                style={style}
                source={{
                    uri: url
                }}
                useNativeControls
                resizeMode="contain"
                isLooping
            />
}
