import {View, Text, TouchableOpacity} from "react-native";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../contexts";
import Loading from "../../lib/static/Loading";
import {EDate, fixHeight, fixWidth} from "../../lib/sugar";
import {Flex, Row} from "../../lib/markup/markup";
import {isAdministrations} from "../../objects/user_methods";
import {
    bgDangerLight,
    bgSecondary,
    bgWhite, bold,
    border,
    borderTable,
    center,
    jCenter,
    m1, ml1, mt1,
    noBorder,
    p1, primary,
    white
} from "../../const";
import {H3} from "../../lib/text/H";

export default function (props) {
    const [state, setState] = useState({ready: false})

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let date = new EDate()
        date.change({days: -(date.getDate()-1)})
        let months = [[]]
        let lastMonth = date.getMonth()
        for (let n=0; n<93; n++) {
            if (date.getMonth() !== lastMonth) {
                if (months.length === 3) break
                months.push([])
                lastMonth = date.getMonth()
            }
            months[months.length-1].push(date.copy())
            date.change({days: 1})
        }
        updateState({ready: true, months})
    }, [])

    if (!state.ready) return <Loading/>
    return <View>
        <Text style={[primary, mt1, ml1, bold]}>рабочие дни "Креатив-Лаб"</Text>
        <Row style={mt1}>
            {state.months.map((days, key) => <Month key={key} days={days}/>)}
        </Row>
    </View>
}


function Month({days}) {

    function initWeek(day) {
        let weekday = day.getDay()
        if (!weekday) weekday = 7
        let array = []
        for (let n=0; n<weekday-1; n++) array.push(null)
        return array
    }

    let weeks = [initWeek(days[0])]

    for (let day of days) {
        if (weeks[weeks.length-1].length === 7) weeks.push([])
        weeks[weeks.length-1].push(day)
    }
    return <View style={[p1, m1, border, bgSecondary]}>
        <H3>{days[0].monthName()}</H3>
        {weeks.map((week, key) => <Week key={key} days={week}/>)}
    </View>
}

const cellStyle = {...fixHeight(25), ...fixWidth(25), ...center, ...jCenter, ...borderTable}

function Week({days}) {
    return <Row>
        {days.map((day, key) => {
            if (!day) return <View key={key} style={[cellStyle, noBorder]}/>
            return <Day key={key} day={day}/>
        })}
    </Row>
}

function Day({day}) {
    const {holidays, user, setHolidays, POST} = useContext(AppContext)
    return <TouchableOpacity
        style={[cellStyle, holidays.includes(day.isoDate()) ? [bgDangerLight, white]: bgWhite]}
        onPress={async () => {
            if (!isAdministrations(user)) return
            const index = holidays.indexOf(day.isoDate())
            if (index === -1) {
                POST('main/holidays/', {date: day.isoDate()})
                setHolidays(holidays.concat(day.isoDate()))
            } else {
                POST('main/holidays/delete/', {date: day.isoDate()})
                holidays.splice(index, 1)
                setHolidays(holidays)
            }
        }}
    >
        <Text>{day.getDate()}</Text>
    </TouchableOpacity>
}

